import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useGlobalState } from 'context/GlobalState';
import { CardAccountFilter } from 'domains/card/components';
import {
  CategoryFilter,
  FlagReasonFilter,
  ProjectFilter,
  ReceiptFilter,
  StatusFilter,
  TeamFilter,
} from 'domains/transaction/components';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  MerchantCategory,
  PrivateExpenseStatus,
  privateExpenseStatuses,
  Project,
  ReviewFlagReason,
  Team,
  TransactionReceiptStatus,
  TransactionStatus,
} from 'services/constants';
import { QueryParams, visibleTransactionStatuses } from '.././index';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  teams: Team[];
  projects: Project[];
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  status: TransactionStatus[];
  category: MerchantCategory[];
  teamId: string[];
  projectIds: string[];
  receipt: TransactionReceiptStatus | '';
  fromDate: Moment | null;
  toDate: Moment | null;
  flagReason: ReviewFlagReason | '';
  privateExpenseStatus: PrivateExpenseStatus[];
  cardAccountId: string;
}

const initialState: State = {
  status: [],
  category: [],
  teamId: [],
  projectIds: [],
  receipt: '',
  fromDate: null,
  toDate: null,
  flagReason: '',
  privateExpenseStatus: [],
  cardAccountId: '',
};

const FiltersDrawer = ({
  teams,
  projects,
  params,
  setParam,
  ...props
}: Props) => {
  const {
    state: { cardAccounts, featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>(initialState);

  const areFiltersSelected = !!(
    state.status.length ||
    state.category.length ||
    state.teamId.length ||
    state.projectIds.length ||
    state.fromDate ||
    state.toDate ||
    state.flagReason ||
    state.privateExpenseStatus.length ||
    state.cardAccountId
  );

  useEffect(() => {
    if (props.open)
      setState({
        status: params.status,
        category: params.category,
        teamId: params.teamId,
        projectIds: params.projectIds,
        receipt: params.receipt,
        fromDate: params.fromDate,
        toDate: params.toDate,
        flagReason: params.flagReason,
        privateExpenseStatus: params.privateExpenseStatus,
        cardAccountId: params.cardAccountId,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    setParam(
      Object.entries({
        ...state,
        fromDate: state.fromDate?.startOf('day')?.format(),
        toDate: state.toDate?.endOf('day')?.format(),
      })
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.entries(initialState));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <StatusFilter
        value={state.status}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            status: xor(prevState.status, [value]),
          }))
        }
        options={visibleTransactionStatuses}
      />

      <DateFilter
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate: fromDate?.startOf('day') || null,
            toDate: toDate?.endOf('day') || null,
          }))
        }
      />

      {cardAccounts.length > 1 && (
        <CardAccountFilter
          value={state.cardAccountId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, cardAccountId: value }))
          }
        />
      )}

      {featureModules.RECEIPT_MANAGEMENT && (
        <ReceiptFilter
          value={state.receipt}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, receipt: value }))
          }
        />
      )}

      <CategoryFilter
        value={state.category}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, category: value }))
        }
      />

      {!!teams.length && (
        <TeamFilter
          teams={teams}
          value={state.teamId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, teamId: value }))
          }
        />
      )}

      {!!projects.length && (
        <ProjectFilter
          projects={projects}
          value={state.projectIds}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, projectIds: value }))
          }
        />
      )}

      <FlagReasonFilter
        value={state.flagReason}
        onChange={(flagReason) =>
          setState((prevState) => ({
            ...prevState,
            flagReason,
            privateExpenseStatus:
              flagReason === ReviewFlagReason.privateExpense
                ? [...privateExpenseStatuses]
                : [],
          }))
        }
        privateExpenseStatusesValue={state.privateExpenseStatus}
        onPrivateExpenseStatusChange={(values) =>
          setState((prevState) => ({
            ...prevState,
            privateExpenseStatus: xor(prevState.privateExpenseStatus, values),
          }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
