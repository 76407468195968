import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { partnerPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import { IntegrationImage } from 'domains/settings/components';
import { IntegrationItem } from 'domains/settings/pages/IntegrationsPage';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Typography,
  withDialogWrapper,
} from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import {
  AccountingSystem,
  IntegrationName,
  PartnerAuthStatus,
  PartnerIdType,
  SUPPORT_EMAIL,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';
import AuthorizationRevokeComponent from './AuthorizationRevokeComponent';
import UpgradeButton from './UpgradeButton';

const LogoContainer = styled('div')({
  margin: '0 auto',
  lineHeight: 0,
  height: 72,
  width: 128,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  svg: { maxHeight: '100%', maxWidth: '100%' },
});

interface Props extends DialogProps {
  integration: IntegrationItem | null;
  integrations: IntegrationItem[] | null;
  onClose: () => void;
  onPartnerUnauthorized: (partnerId: PartnerIdType) => void;
}

const IntegrationDialog = ({
  integration,
  integrations,
  onPartnerUnauthorized,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const canUser = useCanUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();

  if (!integration) return null;

  const onActivatePartner = (path: string) => {
    const anotherIntegrationIsProcessed = integrations?.find(
      (item) => item.partnerId && item.status !== PartnerAuthStatus.inactive
    );

    if (anotherIntegrationIsProcessed) {
      return enqueueSnackbar(t('errors.activatingIntegrationsSimultaneously'), {
        variant: 'error',
      });
    }

    history.push(path);
  };

  const renderActivationButton = (onActivate: () => void) => {
    if (integration.status === PartnerAuthStatus.inactive) {
      return integration.requiresUpgrade ? (
        <UpgradeButton />
      ) : (
        <Button
          onClick={onActivate}
          disabled={!canUser('partners:change') || !integration.partnerDetails}
        >
          {t('integrationDialog.connectButton')}
        </Button>
      );
    }

    return (
      <AuthorizationRevokeComponent
        integration={integration}
        onPartnerUnauthorized={onPartnerUnauthorized}
      />
    );
  };

  const renderActionButtons = () => {
    if (integration.partnerId) {
      return (
        <>
          <Button variant="text" onClick={props.onClose}>
            {t('common.button.close')}
          </Button>
          {renderActivationButton(() =>
            onActivatePartner(
              `${partnerPaths.partners}/${integration.partnerId}`
            )
          )}
        </>
      );
    }
    // can navigate to the settings page only when useAccountingExport is enabled
    if (
      integration.name === IntegrationName.lexOffice &&
      integration.status === PartnerAuthStatus.inactive &&
      accountingSettings?.useAccountingExport
    ) {
      return (
        <>
          <Button variant="text" onClick={props.onClose}>
            {t('common.button.close')}
          </Button>
          {integration.requiresUpgrade ? (
            <UpgradeButton />
          ) : (
            <Button
              onClick={() =>
                history.push(
                  generatePath(getPath('settingsLocoia'), {
                    orgId: organization!.id,
                  })
                )
              }
              disabled={
                !canUser('locoia-sub-page:visit', accountingSettings) ||
                !canUser('locoia-settings:view')
              }
            >
              {t('integrationDialog.connectButton')}
            </Button>
          )}
        </>
      );
    }

    if (
      accountingSettings?.accountingSystem &&
      [
        AccountingSystem.quickbooks,
        AccountingSystem.xero,
        AccountingSystem.oracleNetSuite,
        AccountingSystem.microsoftDynamics365BusinessCentral,
      ].includes(accountingSettings.accountingSystem) &&
      integration.name === accountingSettings.accountingSystem &&
      accountingSettings?.useAccountingExport &&
      integration.status === PartnerAuthStatus.inactive
    ) {
      return (
        <>
          <Button variant="text" onClick={props.onClose}>
            {t('common.button.close')}
          </Button>
          <Button
            onClick={() =>
              history.push(
                generatePath(getPath('settingsAccountingSystem'), {
                  orgId: organization!.id,
                })
              )
            }
            disabled={
              !canUser('accounting-system-subpage:visit', accountingSettings)
            }
          >
            {t('integrationDialog.connectButton')}
          </Button>
        </>
      );
    }

    if (integration.status === PartnerAuthStatus.inactive) {
      return (
        <>
          <Button variant="text" onClick={props.onClose}>
            {t('common.button.close')}
          </Button>
          <Button
            component="a"
            href={`mailto:${SUPPORT_EMAIL}`}
            rel="noopener noreferrer"
            sx={{ ml: 1 }}
          >
            {t('integrationDialog.linkTitle')}
          </Button>
        </>
      );
    }

    return <Button onClick={props.onClose}>{t('common.button.close')}</Button>;
  };

  return (
    <Dialog
      {...props}
      maxWidth="md"
      PaperProps={{
        sx: {
          paddingRight: '350px',
          height: 668,
        },
      }}
    >
      <DialogContent>
        <LogoContainer>{integration.logo}</LogoContainer>

        <Typography variant="h6" mt={1}>
          {integration.title}
        </Typography>
        <Typography variant="subtitle2" mb={2}>
          {integration.subtitle}
        </Typography>

        {integration.description.map((str, index) => (
          <Typography key={index}>{str}</Typography>
        ))}

        {integration.subDescription && (
          <Typography component="div" variant="body2" mt={2}>
            {integration.subDescription}
          </Typography>
        )}
      </DialogContent>

      <DialogActions>{renderActionButtons()}</DialogActions>

      <IntegrationImage
        integrationName={integration.name}
        partnerId={integration.partnerId}
      />
    </Dialog>
  );
};

export default withDialogWrapper(IntegrationDialog);
