import { useEffect } from 'react';
import { FormHelperText } from '@mui/material';
import { lessThan } from 'dinero.js';
import { useFormik } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { omit } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  useCardAccountCurrency,
  useCardAccountNameGetter,
} from 'domains/card/hooks';
import {
  ArrowsLeftRightIcon,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CardAccount,
  CardAccountStatus,
  OrganizationAccountType,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  dineroFromMoney,
  getGenericErrorMsg,
} from 'services/utils';

const getCardAccount = (cardAccounts: CardAccount[], id: string) => {
  return cardAccounts.find((item) => item.id === id);
};

interface FormValues {
  cardAccountFrom: string;
  amountFrom: string;
  cardAccountTo: string;
  amountTo: string;
}

const initialValues: FormValues = {
  cardAccountFrom: '',
  amountFrom: '',
  cardAccountTo: '',
  amountTo: '',
};

interface Props extends DialogProps {
  onClose: () => void;
  fromId?: string | null;
  toId?: string | null;
}

const TransferMoneyDialog = ({ fromId, toId, ...props }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization, cardAccounts },
    dispatch,
  } = useGlobalState();
  const activeCardAccounts = cardAccounts.filter(
    (item) => item.status.value === CardAccountStatus.active
  );
  const getCardAccountName = useCardAccountNameGetter();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      const accountFrom = cardAccounts.find(
        (account) => account.id === values.cardAccountFrom
      );
      const accountTo = cardAccounts.find(
        (account) => account.id === values.cardAccountTo
      );

      if (accountFrom && accountFrom.balance.value.value <= 0) {
        errors.cardAccountFrom = t('transferMoneyDialog.balanceTooLowError');
      }
      if (
        accountFrom &&
        accountTo &&
        accountFrom.currency.value !== accountTo.currency.value
      ) {
        errors.cardAccountTo = t(
          'transferMoneyDialog.differentCurrenciesError'
        );
      }
      if (values.amountFrom && !+values.amountFrom) {
        errors.amountFrom = t('transferMoneyDialog.amountTooLowError');
      }
      if (values.amountTo && !+values.amountTo) {
        errors.amountTo = t('transferMoneyDialog.amountTooLowError');
      }
      if (!errors.cardAccountFrom && accountFrom && values.amountFrom) {
        if (
          lessThan(
            dineroFromMoney(accountFrom.balance.value),
            dineroFromFloat(values.amountFrom, accountFrom.currency.value)
          )
        ) {
          errors.amountFrom = t(
            'transferMoneyDialog.amountIsBiggerThanBalanceError'
          );
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      try {
        await api.createMoneyTransfer({
          organizationId: organization!.id,
          sourceCardAccountId: values.cardAccountFrom,
          targetCardAccountId: values.cardAccountTo,
          moneyToSend: convertDineroToMoney(
            dineroFromFloat(values.amountFrom, currencyFrom)
          ),
          moneyToReceive: convertDineroToMoney(
            dineroFromFloat(values.amountTo, currencyTo)
          ),
        });
        const { cardAccounts } = await api.getCardAccounts(organization!.id);
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            cardAccounts,
            defaultCardAccount: cardAccounts.find(
              (item) => item.defaultAccount.value
            )!,
          },
        });
        if (!mounted.current) return;
        enqueueSnackbar(t('transferMoneyDialog.successMessage'), {
          variant: 'success',
        });
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
        props.onClose();
      }
    },
  });

  useEffect(() => {
    if (fromId) {
      formik.setFieldValue('cardAccountFrom', fromId, true);
    } else if (toId) {
      formik.setFieldValue('cardAccountTo', toId, true);
    }
  }, []);
  const currencyFrom = useCardAccountCurrency(formik.values.cardAccountFrom);
  const currencyTo = useCardAccountCurrency(formik.values.cardAccountTo);

  const isSubmitDisabled =
    !formik.values.cardAccountFrom ||
    !formik.values.amountFrom ||
    !formik.values.cardAccountTo ||
    !formik.values.amountTo ||
    !formik.isValid;

  const isCardAccountFromDisabled = (cardAccount: CardAccount) => {
    return (
      cardAccount.balance.value.value <= 0 ||
      cardAccount.id === formik.values.cardAccountTo ||
      cardAccount.status.value !== CardAccountStatus.active
    );
  };

  const isCardAccountToDisabled = (cardAccount: CardAccount) => {
    return (
      cardAccount.id === formik.values.cardAccountFrom ||
      cardAccount.status.value !== CardAccountStatus.active
    );
  };

  const renderAccountBalance = (
    cardAccountId: string | null,
    isSourceAccount: boolean
  ) => {
    if (!cardAccountId) return ' ';
    const cardAccount = getCardAccount(cardAccounts, cardAccountId);
    if (!cardAccount) return ' ';

    if (isSourceAccount) {
      return (
        <Trans
          i18nKey="transferMoneyDialog.fromDescription"
          components={{
            balance: <FormatMoney value={cardAccount.balance.value} />,
          }}
        />
      );
    }

    const balance =
      cardAccount.accountType.value === OrganizationAccountType.prefunded
        ? cardAccount!.balance.value
        : cardAccount!.availableLimit.value;

    return (
      <Trans
        i18nKey="transferMoneyDialog.toDescription"
        components={{ balance: <FormatMoney value={balance} /> }}
      />
    );
  };

  return (
    <Dialog {...props}>
      <DialogTitle>{t('transferMoneyDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          id="transfer-money-form"
        >
          <Typography variant="body2" mb={2}>
            {t('transferMoneyDialog.description')}
          </Typography>

          <Box display="flex">
            <Box
              flex="1 1 0px"
              width={0}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>{t('transferMoneyDialog.fromLabel')}</InputLabel>
                <Select<string>
                  displayEmpty
                  value={formik.values.cardAccountFrom}
                  onChange={(e) =>
                    formik.setFieldValue(
                      'cardAccountFrom',
                      e.target.value,
                      true
                    )
                  }
                  renderValue={() => {
                    const cardAccount = cardAccounts.find(
                      (item) => item.id === formik.values.cardAccountFrom
                    );
                    if (cardAccount) return getCardAccountName(cardAccount);
                    return (
                      <Typography
                        component="span"
                        variant="inherit"
                        color="text.disabled"
                      >
                        {t('transferMoneyDialog.selectPlaceholder')}
                      </Typography>
                    );
                  }}
                  error={!!formik.errors.cardAccountFrom}
                >
                  {activeCardAccounts.map((cardAccount) => (
                    <MenuItem
                      key={cardAccount.id}
                      value={cardAccount.id}
                      disabled={isCardAccountFromDisabled(cardAccount)}
                    >
                      <ListItemText
                        primary={getCardAccountName(cardAccount)}
                        secondary={
                          <FormatMoney value={cardAccount.balance.value} />
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!formik.errors.cardAccountFrom}>
                  {formik.errors.cardAccountFrom ||
                    renderAccountBalance(formik.values.cardAccountFrom, true)}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth sx={{}}>
                <MoneyField
                  label={t('transferMoneyDialog.fromAmountLabel')}
                  {...omit(formik.getFieldProps('amountFrom'), 'onChange')}
                  onValueChange={({ value }) => {
                    formik.setFieldValue('amountFrom', value);
                    formik.setFieldValue('amountTo', value);
                    if (!formik.isValid) formik.validateForm(formik.values);
                  }}
                  error={!!formik.errors.amountFrom}
                  helperText={formik.errors.amountFrom || ' '}
                  currency={currencyFrom.code}
                  decimalScale={currencyFrom.exponent}
                  isNumericString
                />
              </FormControl>
            </Box>

            <Box
              px={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <IconButton
                onClick={() =>
                  formik.setValues(
                    {
                      ...formik.values,
                      cardAccountFrom: formik.values.cardAccountTo,
                      cardAccountTo: formik.values.cardAccountFrom,
                    },
                    true
                  )
                }
              >
                <ArrowsLeftRightIcon />
              </IconButton>
            </Box>

            <Box
              flex="1 1 0px"
              width={0}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>{t('transferMoneyDialog.toLabel')}</InputLabel>
                <Select<string>
                  displayEmpty
                  value={formik.values.cardAccountTo}
                  onChange={(e) =>
                    formik.setFieldValue('cardAccountTo', e.target.value, true)
                  }
                  renderValue={() => {
                    const cardAccount = cardAccounts.find(
                      (item) => item.id === formik.values.cardAccountTo
                    );
                    if (cardAccount) return getCardAccountName(cardAccount);
                    return (
                      <Typography
                        component="span"
                        variant="inherit"
                        color="text.disabled"
                      >
                        {t('transferMoneyDialog.selectPlaceholder')}
                      </Typography>
                    );
                  }}
                  error={!!formik.errors.cardAccountTo}
                >
                  {activeCardAccounts.map((cardAccount) => (
                    <MenuItem
                      key={cardAccount.id}
                      value={cardAccount.id}
                      disabled={isCardAccountToDisabled(cardAccount)}
                    >
                      <ListItemText
                        primary={getCardAccountName(cardAccount)}
                        secondary={
                          <FormatMoney
                            value={
                              cardAccount.accountType.value ===
                              OrganizationAccountType.prefunded
                                ? cardAccount.balance.value
                                : cardAccount.availableLimit.value
                            }
                          />
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!formik.errors.cardAccountTo}>
                  {formik.errors.cardAccountTo ||
                    renderAccountBalance(formik.values.cardAccountTo, false)}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth sx={{}}>
                <MoneyField
                  label={t('transferMoneyDialog.toAmountLabel')}
                  {...omit(formik.getFieldProps('amountTo'), 'onChange')}
                  onValueChange={({ value }) => {
                    formik.setFieldValue('amountFrom', value);
                    formik.setFieldValue('amountTo', value);
                    if (!formik.isValid) formik.validateForm(formik.values);
                  }}
                  error={!!formik.errors.amountTo}
                  helperText={formik.errors.amountTo || ' '}
                  currency={currencyTo.code}
                  decimalScale={currencyTo.exponent}
                  isNumericString
                />
              </FormControl>
            </Box>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.close')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          type="submit"
          form="transfer-money-form"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper(TransferMoneyDialog);
