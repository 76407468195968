import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import {
  portalOrgPaths,
  PortalOrgPathsKeys,
} from 'components/App/PortalApp/paths';
import withPartnerLogic from 'components/App/PortalApp/withPartnerLogic';
import { Container, ContainerInner, MainContent } from 'components/App/style';
import { PortalMainHeader } from 'components/MainHeader';
import Sidebar from 'components/Sidebar';
import { useGlobalState } from 'context/GlobalState';
import { Box, LoaderWithOverlay } from 'elements';
import useIsOrgInOnboarding from 'hooks/useIsOrgInOnboarding';
import { OrganizationStatus } from 'services/constants';
import MirrorViewAlert, { alertHeight } from './MirrorViewAlert';

const SIDEBAR_TABS: PortalOrgPathsKeys[] = [
  'dashboard',
  'cardAccounts',
  'cards',
  'transactions',
  'transactionsReview',
  'transactionsFlagged',
  'accounting',
  'billingPayments',
  'billingStatements',
  'members',
  'teams',
  'rewards',
  'merchants',
  'pliantEarth',
  'export',
  'exportQueue',
  'exported',
  'settings',
  'settingsIntegrations',
  'settingsFeatureModules',
  'settingsOrganization',
  'settingsAccounting',
  'partner',
];

const PortalOrgLayout = ({
  children,
  isThemeLoading = true,
}: {
  children: React.ReactNode;
  isThemeLoading?: boolean;
}) => {
  const {
    state: { organization },
  } = useGlobalState();
  const { isOrgInOnboarding, isOrgInOnboardingNew } = useIsOrgInOnboarding();

  const sidebarPaths = useMemo(() => {
    if (organization) {
      const sidebarPaths: { [pathId: string]: string } = {};

      if (
        isOrgInOnboarding ||
        organization.status === OrganizationStatus.cancelled
      ) {
        sidebarPaths.onboarding = generatePath(
          isOrgInOnboardingNew
            ? portalOrgPaths.orgOnboarding
            : portalOrgPaths.onboarding,
          {
            orgId: organization.id,
          }
        );
      }
      SIDEBAR_TABS.forEach((key) => {
        sidebarPaths[key] = generatePath(portalOrgPaths[key], {
          orgId: organization.id,
        });
      });
      return sidebarPaths;
    } else {
      return {};
    }
  }, [organization, isOrgInOnboardingNew]);

  return (
    <Container style={{ flexDirection: 'column' }}>
      <MirrorViewAlert />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Sidebar
          paths={sidebarPaths}
          showOnboarding={isOrgInOnboarding}
          PaperProps={{
            sx: {
              top: `${alertHeight}px`,
              height: `calc(100% - ${alertHeight}px)`,
            },
          }}
        />
        <ContainerInner>
          <PortalMainHeader />
          <MainContent>
            {children}
            <LoaderWithOverlay loading={isThemeLoading} />
          </MainContent>
        </ContainerInner>
      </Box>
    </Container>
  );
};

export default withPartnerLogic(PortalOrgLayout);
