import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import DatevServiceLogo from 'assets/svg/integrations/datevServiceLogo.svg?react';
import { ApiIntegrationStatusBadge } from 'components/Badge';
import { useGlobalState } from 'context/GlobalState';
import {
  ArrowSquareOutIcon,
  Box,
  Button,
  Link as MuiLink,
  Paper,
  Typography,
} from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import {
  ApiIntegrationStatus,
  DatevConnection,
  SUPPORT_EMAIL,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';

const DatevGUILink = 'https://apps.datev.de/tokrevui/';
const DatevHelpLink = 'https://apps.datev.de/help-center/documents/1007329';

enum ValidationError {
  documentManagementNotAvailable = 'DOCUMENT_MANAGEMENT_NOT_AVAILABLE',
  accountsPayableLedgerNotAvailable = 'ACCOUNTS_PAYABALE_LEDGER_NOT_AVAILABLE',
  basicAccountingInformationMissing = 'BASIC_ACCOUNTING_INFORMATION_MISSING',
  ledgersInformationMissing = 'LEDGERS_INFORMATION_MISSING',
}

interface Props {
  isConnectionError: boolean;
  isDisconnectionError: boolean;
  isClientsFetchError: boolean;
  isDatevValidationError: boolean;
  validationErrors: string[] | null;
  connectionAudit: Omit<DatevConnection, 'validationErrors'> | null;
  onConnect: () => void;
  onDisconnect: () => void;
  onActivate: () => void;
}

const DatevConnectionSidebar = ({
  isConnectionError,
  isDisconnectionError,
  isClientsFetchError,
  isDatevValidationError,
  validationErrors,
  connectionAudit,
  onConnect,
  onDisconnect,
  onActivate,
}: Props) => {
  const { t } = useTranslation();
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    state: { accountingSettings, organization },
  } = useGlobalState();

  const getShownErrorMsg = () => {
    if (isDatevValidationError)
      return (
        <Box mb={3}>
          <Typography variant="body2" color="error.main" mb={2}>
            {t('datevSubPage.datevPermissionRequirementsNotMet.title')}
          </Typography>
          <Typography variant="body2" mb={1}>
            {t('datevSubPage.datevPermissionRequirementsNotMet.subtitle')}
          </Typography>

          <Typography variant="body2" mb={1}>
            <Trans i18nKey="datevSubPage.datevPermissionRequirementsNotMet.description" />
          </Typography>

          <Typography variant="body2">
            <Trans
              i18nKey="datevSubPage.datevPermissionRequirementsNotMet.footer"
              components={{
                mailTo: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={'mailto:' + SUPPORT_EMAIL}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                ),
              }}
            />
          </Typography>
        </Box>
      );

    let messageKey = '';
    if (isConnectionError) messageKey = 'datevSubPage.apiErrors.connection';
    if (isDisconnectionError) messageKey = 'datevSubPage.apiErrors.disconnect';
    if (isClientsFetchError) messageKey = 'datevSubPage.apiErrors.clientsFetch';

    return messageKey ? (
      <Box mb={3}>
        <Typography variant="body2" color="error.main">
          <Trans
            i18nKey={messageKey}
            components={{
              mailTo: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href={'mailto:' + SUPPORT_EMAIL}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
              datevLink: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href={DatevGUILink}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
            values={{
              partnerName,
            }}
          />
        </Typography>
      </Box>
    ) : null;
  };

  const renderValidationErrors = () => {
    let errorMessages: string[] = [];
    const datevErrors = validationErrors!;

    if (
      datevErrors.includes(ValidationError.documentManagementNotAvailable) &&
      datevErrors.includes(ValidationError.accountsPayableLedgerNotAvailable)
    ) {
      errorMessages = ['datevSubPage.datevErrors.managementLedgerMissing'];
    } else if (
      datevErrors.includes(ValidationError.documentManagementNotAvailable)
    ) {
      errorMessages = ['datevSubPage.datevErrors.managementMissing'];
    } else if (
      datevErrors.includes(ValidationError.accountsPayableLedgerNotAvailable)
    ) {
      errorMessages = ['datevSubPage.datevErrors.ledgerMissing'];
    }

    if (
      datevErrors.includes(ValidationError.basicAccountingInformationMissing) ||
      datevErrors.includes(ValidationError.ledgersInformationMissing)
    )
      errorMessages = [
        ...errorMessages,
        'datevSubPage.datevErrors.otherMissing',
      ];

    return errorMessages.map((msg) => (
      <Typography key={msg} variant="body2" color="error.main" mb={1}>
        <Trans
          i18nKey={msg}
          components={{
            b: <b />,
            linkTo: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={DatevHelpLink}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      </Typography>
    ));
  };

  return (
    <Box flexShrink={0} width={260} ml={5}>
      <Box mb={5} mx="auto" width={90}>
        <DatevServiceLogo />
      </Box>

      <Paper
        sx={{
          p: 3,
          borderRadius: 1,
          background: (theme) => theme.variables.background.contrast,
        }}
      >
        <Typography variant="h6" mb={3}>
          {t('datevSubPage.sidebar.titleNotConnected')}
        </Typography>

        <Box display="flex" alignItems="center" mb={3}>
          <Typography variant="caption" mr={2}>
            {t('datevSubPage.sidebar.datevConnectionStatus')}
          </Typography>
          <ApiIntegrationStatusBadge
            status={accountingSettings!.apiIntegrationStatus}
          />
        </Box>

        {!!validationErrors?.length && (
          <Box mt="16px" mb="24px">
            {renderValidationErrors()}
          </Box>
        )}

        <Box my={3}>
          {accountingSettings!.apiIntegrationStatus ===
            ApiIntegrationStatus.notConnected && (
            <>
              {!validationErrors?.length && (
                <Typography variant="body2" mb={3}>
                  <Trans
                    i18nKey="datevSubPage.sidebar.descriptionNotConnected"
                    components={{
                      linkTo: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href={DatevHelpLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      ),
                      b: <b />,
                    }}
                  />
                </Typography>
              )}
              <Button
                fullWidth
                onClick={onConnect}
                disabled={!canUser('datev-api-connection:change')}
              >
                {t('datevSubPage.sidebar.connectButton')}
              </Button>
            </>
          )}

          {accountingSettings!.apiIntegrationStatus ===
            ApiIntegrationStatus.needsWork && (
            <>
              <Button
                fullWidth
                sx={{ mb: 1 }}
                onClick={onActivate}
                disabled={!canUser('datev-api-connection:change')}
              >
                {t('datevSubPage.sidebar.completeActivationButton')}
              </Button>
              <Button
                fullWidth
                onClick={onDisconnect}
                disabled={!canUser('datev-api-connection:change')}
                variant="outlined"
              >
                {t('datevSubPage.sidebar.disconnectButton')}
              </Button>
              <Typography variant="caption" mt={2} component="div">
                <Trans
                  i18nKey="datevSubPage.sidebar.disconnectionText"
                  components={{
                    linkTo: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={DatevHelpLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                    mailTo: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={'mailto:' + SUPPORT_EMAIL}
                        rel="noreferrer"
                        target="_blank"
                      />
                    ),
                  }}
                  values={{
                    partnerName,
                  }}
                />
              </Typography>
            </>
          )}

          {accountingSettings!.apiIntegrationStatus ===
            ApiIntegrationStatus.connected && (
            <>
              {!validationErrors?.length && (
                <Typography variant="body2" mb={3}>
                  <Trans
                    i18nKey="datevSubPage.sidebar.descriptionConnected"
                    components={{
                      linkTo: (
                        <Link
                          to={generatePath(getPath('export'), {
                            orgId: organization!.id,
                          })}
                        />
                      ),
                    }}
                  />
                </Typography>
              )}
              <Button
                fullWidth
                variant="outlined"
                disabled={!canUser('datev-api-connection:change')}
                onClick={onDisconnect}
              >
                {t('datevSubPage.sidebar.disconnectButton')}
              </Button>
            </>
          )}
        </Box>

        {getShownErrorMsg()}

        {accountingSettings!.apiIntegrationStatus !==
          ApiIntegrationStatus.notConnected && (
          <>
            {/* need to check all information, as there may be something missing */}
            {connectionAudit?.connectedAt &&
              connectionAudit?.tokenValidUntil &&
              connectionAudit?.datevClientId && (
                <Typography variant="body2" color="textSecondary" mb={2}>
                  {t('datevSubPage.sidebar.tokenAuditLine1', {
                    tokenId: connectionAudit.datevClientId,
                    connectionDate: moment(connectionAudit.connectedAt).format(
                      'DD.MM.YYYY'
                    ),
                    expirationDate: moment(
                      connectionAudit.tokenValidUntil
                    ).format('DD.MM.YYYY'),
                  })}
                </Typography>
              )}
            {connectionAudit?.connectedByFirstName &&
              connectionAudit?.connectedByLastName && (
                <Typography variant="body2" color="textSecondary" mb={2}>
                  {t('datevSubPage.sidebar.tokenAuditLine2', {
                    userName: `${connectionAudit.connectedByFirstName} ${connectionAudit.connectedByLastName}`,
                  })}
                </Typography>
              )}

            <MuiLink
              href={DatevGUILink}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {t('datevSubPage.sidebar.detevTokens')}
              <ArrowSquareOutIcon sx={{ ml: 1 }} />
            </MuiLink>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default DatevConnectionSidebar;
