import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'elements';
import {
  AccountEntry,
  AccountEntryBillPaymentType,
  AccountEntryType,
} from 'services/constants';

const Title = ({
  billPaymentInfo,
}: {
  billPaymentInfo: AccountEntry['billPaymentInfo'];
}) => {
  const { t } = useTranslation();
  return (
    <Typography variant="subtitle1" mt={2}>
      {t(`accountEntryBillPaymentType.${billPaymentInfo!.billPaymentType}`) +
        `: ${billPaymentInfo!.purpose}`}
    </Typography>
  );
};

interface Props {
  accountEntry: AccountEntry;
}

const BillPaymentInfo = ({ accountEntry }: Props) => {
  const { t } = useTranslation();

  if (accountEntry.type === AccountEntryType.other) {
    return (
      <>
        <Typography variant="subtitle1" mt={2}>
          {t('accountingEntryDetailsPage.otherPayment')}
        </Typography>

        <Box display="flex" alignItems="flex-end" mt={1}>
          <Typography variant="subtitle2">
            {t('accountingEntryDetailsPage.bookingDate')}
          </Typography>
          <Typography variant="caption" ml={1}>
            {moment(accountEntry.bookingDate).format('D MMM YYYY')}
          </Typography>
        </Box>
      </>
    );
  }

  const billPaymentInfo = accountEntry.billPaymentInfo;
  const billPaymentType = billPaymentInfo?.billPaymentType;

  if (billPaymentType === AccountEntryBillPaymentType.bill) {
    return (
      <>
        <Title billPaymentInfo={billPaymentInfo!} />

        <Box display="flex" alignItems="center" mt={1}>
          {billPaymentInfo!.cutoffDate && (
            <Box display="flex" alignItems="flex-end" mr={3}>
              <Typography variant="subtitle2">
                {t('accountingEntryDetailsPage.cutoffDate')}
              </Typography>
              <Typography variant="caption" ml={1}>
                {moment(billPaymentInfo!.cutoffDate).format('D MMM YYYY')}
                {!!billPaymentInfo!.paymentDefermentInDays &&
                  billPaymentInfo!.paymentDefermentInDays > 0 && (
                    <span>
                      {t('accountingEntryDetailsPage.nDaysDeferred', {
                        number: billPaymentInfo!.paymentDefermentInDays,
                      })}
                    </span>
                  )}
              </Typography>
            </Box>
          )}
          <Box display="flex" alignItems="flex-end">
            <Typography variant="subtitle2">
              {t('accountingEntryDetailsPage.paymentDate')}
            </Typography>
            <Typography variant="caption" ml={1}>
              {moment(accountEntry.bookingDate).format('D MMM YYYY')}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  if (billPaymentType === AccountEntryBillPaymentType.prefunding) {
    return (
      <>
        <Title billPaymentInfo={billPaymentInfo!} />

        <Box display="flex" alignItems="flex-end" mt={1}>
          <Typography variant="subtitle2">
            {t('accountingEntryDetailsPage.paymentDate')}
          </Typography>
          <Typography variant="caption" ml={1}>
            {moment(accountEntry.bookingDate).format('D MMM YYYY')}
          </Typography>
        </Box>
      </>
    );
  }

  if (billPaymentType === AccountEntryBillPaymentType.billPaymentReversal) {
    return (
      <>
        <Title billPaymentInfo={billPaymentInfo!} />

        <Box display="flex" alignItems="flex-end" mt={1}>
          <Typography variant="subtitle2">
            {t('accountingEntryDetailsPage.bookingDate')}
          </Typography>
          <Typography variant="caption" ml={1}>
            {moment(accountEntry.bookingDate).format('D MMM YYYY')}
          </Typography>
        </Box>
      </>
    );
  }

  if (billPaymentType === AccountEntryBillPaymentType.directDebitReversalFee) {
    return (
      <>
        <Title billPaymentInfo={billPaymentInfo!} />

        <Box display="flex" alignItems="flex-end" mt={1}>
          <Typography variant="subtitle2">
            {t('accountingEntryDetailsPage.bookingDate')}
          </Typography>
          <Typography variant="caption" ml={1}>
            {moment(accountEntry.bookingDate).format('D MMM YYYY')}
          </Typography>
        </Box>
      </>
    );
  }

  if (billPaymentType === AccountEntryBillPaymentType.payout) {
    return (
      <>
        <Title billPaymentInfo={billPaymentInfo!} />

        <Box display="flex" alignItems="center" mt={1}>
          <Box display="flex" alignItems="flex-end">
            <Typography variant="subtitle2">
              {t('accountingEntryDetailsPage.requestDate')}
            </Typography>
            <Typography variant="caption" ml={1}>
              {moment(billPaymentInfo!.requestDate).format('D MMM YYYY')}
            </Typography>
          </Box>

          <Box display="flex" alignItems="flex-end" ml={3}>
            <Typography variant="subtitle2">
              {t('accountingEntryDetailsPage.paymentDate')}
            </Typography>
            <Typography variant="caption" ml={1}>
              {moment(accountEntry.bookingDate).format('D MMM YYYY')}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  if (billPaymentType === AccountEntryBillPaymentType.internalTransfer) {
    const name = accountEntry.billPaymentInfo?.originalPaymentCardAccountName;

    return (
      <>
        <Typography variant="subtitle1" mt={2}>
          {accountEntry.amount.value > 0
            ? t('accountingPage.transferFrom', { name })
            : t('accountingPage.transferTo', { name })}
        </Typography>

        <Box display="flex" alignItems="center" mt={1}>
          <Box display="flex" alignItems="flex-end">
            <Typography variant="subtitle2">
              {t('accountingEntryDetailsPage.requestDate')}
            </Typography>
            <Typography variant="caption" ml={1}>
              {moment(billPaymentInfo!.requestDate).format('D MMM YYYY')}
            </Typography>
          </Box>

          {accountEntry.bookingDate && (
            <Box display="flex" alignItems="flex-end" ml={3}>
              <Typography variant="subtitle2">
                {t('accountingEntryDetailsPage.bookingDate')}
              </Typography>
              <Typography variant="caption" ml={1}>
                {moment(accountEntry.bookingDate).format('D MMM YYYY')}
              </Typography>
            </Box>
          )}
        </Box>
      </>
    );
  }

  return null;
};

export default BillPaymentInfo;
