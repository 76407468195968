import { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { DialogContent, LoaderWithOverlay, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  clientSecret: string;
  paymentMethodId: string;
  onReimbursementSuccess: () => void;
}

const STRIPE_SUCCESS_STATUSES = ['succeeded', 'requires_capture'];

const ImmediatePaymentStep = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (stripe) {
      handlePayment();
    }
  }, [stripe]);

  const handlePayment = async () => {
    try {
      const { error, paymentIntent } = await stripe!.confirmCardPayment(
        props.clientSecret,
        {
          payment_method: props.paymentMethodId,
        }
      );

      if (!mounted.current) return;
      setIsLoading(false);

      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else if (
        paymentIntent &&
        STRIPE_SUCCESS_STATUSES.includes(paymentIntent.status)
      ) {
        enqueueSnackbar(
          t(
            'transactionDetails.reimbursementDialog.initiateReimbursementSuccess'
          ),
          {
            variant: 'success',
          }
        );
        props.onReimbursementSuccess();
      }
    } catch (error) {
      if (!mounted.current) return;
      setIsLoading(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DialogContent>
        <LoaderWithOverlay loading={isLoading} />
        <Typography variant="body1">
          {t('transactionDetails.reimbursementDialog.descriptions.inProgress')}
        </Typography>
      </DialogContent>
    </>
  );
};

export default ImmediatePaymentStep;
