import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  CardConfigGroup,
  CardFundingType,
  CardNewType,
} from 'services/constants';
import { FormValues, Step } from './index';

const useAttributesStepSettings = ({
  member,
  cardConfigSetting,
  customFields,
}: FormValues) => {
  const {
    state: { featureModules, accountingSettings },
  } = useGlobalState();

  return useMemo(() => {
    if (!member || !cardConfigSetting) {
      return {
        isLabelInputVisible: false,
        isPurposeTextareaVisible: false,
        isDesignPickerVisible: false,
        isTeamsSelectVisible: false,
        isProjectsSelectVisible: false,
        areCustomNameInputsVisible: false,
        areCustomFieldsVisible: false,
      };
    }

    const isLabelInputVisible =
      cardConfigSetting.cardType === CardNewType.virtual &&
      cardConfigSetting.maxUsage !== 1;

    const isPurposeTextareaVisible = cardConfigSetting.maxUsage === 1;

    const isDesignPickerVisible = cardConfigSetting.cardDesignIds.length > 1;

    const isTeamsSelectVisible = featureModules.TEAMS && !!member.teams.length;

    const isProjectsSelectVisible =
      cardConfigSetting.maxUsage !== 1 && accountingSettings?.projectEnabled;

    const areCustomNameInputsVisible =
      cardConfigSetting?.cardConfigGroup ===
        CardConfigGroup.pliantVirtualTravel &&
      featureModules.CUSTOM_CARDHOLDER_NAME;

    const areCustomFieldsVisible = customFields.length > 0;

    return {
      isLabelInputVisible,
      isPurposeTextareaVisible,
      isDesignPickerVisible,
      isTeamsSelectVisible,
      isProjectsSelectVisible,
      areCustomNameInputsVisible,
      areCustomFieldsVisible,
    };
  }, [
    member,
    cardConfigSetting,
    customFields,
    featureModules,
    accountingSettings,
  ]);
};

const useStepManager = () => {
  const { t } = useTranslation();
  const {
    state: { featureModules },
  } = useGlobalState();
  const { isAdminApp } = useCurrentApp();
  const formik = useFormikContext<FormValues>();
  const attributesStepSettings = useAttributesStepSettings(formik.values);
  const steps = useMemo<Step[]>(() => {
    if (!formik.values.cardConfigSetting) {
      return ['type'];
    }

    const isAttributesStepAvailable = (Object.keys(
      attributesStepSettings
    ) as Array<keyof typeof attributesStepSettings>).some(
      (key) =>
        typeof attributesStepSettings[key] === 'boolean' &&
        attributesStepSettings[key]
    );

    return [
      'type',
      ...(formik.values.cardConfigSetting.fundingType === CardFundingType.charge
        ? ['limitsAndValidity' as Step]
        : []),
      ...(featureModules.CARD_CONTROLS ? ['controls' as Step] : []),
      ...(isAttributesStepAvailable ? ['attributes' as Step] : []),
      'summary',
    ];
  }, [
    formik.values.cardConfigSetting,
    featureModules.CARD_CONTROLS,
    attributesStepSettings,
  ]);

  const goPrev = () => {
    const currentStepIndex = steps.indexOf(formik.values.step);
    const prevStep = steps[currentStepIndex - 1] || steps[currentStepIndex];
    formik.setFieldValue('step', prevStep);
  };

  const goNext = () => {
    const currentStepIndex = steps.indexOf(formik.values.step);
    const nextStep = steps[currentStepIndex + 1] || steps[currentStepIndex];
    formik.setFieldValue('step', nextStep);
  };

  const goTo = (step: Step) => {
    formik.setFieldValue('step', step);
  };

  const getStepLabel = (step: Step) =>
    isAdminApp && step === 'type'
      ? t('issueCardDialog_v2.stepper.titlesByStep.memberAndType')
      : t(`cardRequestDialog_v2.stepper.titlesByStep.${step}`);

  return {
    currentStep: formik.values.step,
    steps,
    attributesStepSettings,
    goPrev,
    goNext,
    goTo,
    getStepLabel,
  };
};

export default useStepManager;
