import { useTranslation } from 'react-i18next';
import NothingFound from 'components/NothingFound';
import CancelApplicationButton from 'components/OnboardingPage/CancelApplicationButton';
import { useGlobalState } from 'context/GlobalState';
import { InviteMemberButtonWithMenu } from 'domains/member/components';
import { SectionItem } from 'domains/onboarding/components';
import { PartnerLegalDisclaimer } from 'domains/partner/components';
import { Box, Typography } from 'elements';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import { OnboardingSection, OrganizationStatus } from 'services/constants';

interface Props {
  sections: OnboardingSection[] | null;
}

const OrganizationOnboardingPage = ({ sections }: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();

  return (
    <>
      <PageHeader>
        <ContentContainer
          mx="auto"
          size="sm"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PageTitle title={t('orgOnboardingPage.title')} />
            <Typography mt={1} variant="body2" color="textSecondary">
              {t('orgOnboardingPage.description')}
            </Typography>
          </Box>

          <Box ml={2}>
            <InviteMemberButtonWithMenu />
          </Box>
        </ContentContainer>
      </PageHeader>

      <PageContent display="flex" flexDirection="column">
        {sections && (
          <ContentContainer mx="auto" size="sm" width="100%" flex={1}>
            {sections.map((section) => (
              <SectionItem key={section.type} section={section}>
                {t(`orgOnboardingSectionTitle.${section.type}`)}
              </SectionItem>
            ))}
          </ContentContainer>
        )}

        {sections && sections.length === 0 && <NothingFound />}

        <PartnerLegalDisclaimer />

        <Box display="flex" justifyContent="center" mt={2}>
          {organization!.status === OrganizationStatus.onboarding && (
            <CancelApplicationButton />
          )}
        </Box>
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingPage;
