import BasLogo from 'assets/images/integrationAvatars/bas.png';
import BezalaLogo from 'assets/svg/integrations/bezalaLogo.svg?react';
import CandisLogo from 'assets/svg/integrations/candisLogo.svg?react';
import CirculaLogo from 'assets/svg/integrations/circulaLogo.svg?react';
import ConfermaLogo from 'assets/svg/integrations/confermaLogo.svg?react';
import KlippaLogo from 'assets/svg/integrations/klippaLogo.svg?react';
import MobilexpenseLogo from 'assets/svg/integrations/mobilexpenseLogo.svg?react';
import MocoappLogo from 'assets/svg/integrations/mocoappLogo.svg?react';
import NellyLogo from 'assets/svg/integrations/nellyLogo.svg?react';
import ScopevisioLogo from 'assets/svg/integrations/scopevisioLogo.svg?react';
import ViaxoftLogo from 'assets/svg/integrations/viaxoftLogo.svg?react';
import VoxelLogo from 'assets/svg/integrations/voxelLogo.svg?react';
import { PartnerIds, PartnerIdType } from 'services/constants';

const PartnerLogo = ({ partnerId }: { partnerId: PartnerIdType }) => {
  if (partnerId === PartnerIds.bezala || partnerId === PartnerIds.bezalaWallet)
    return <BezalaLogo />;
  if (partnerId === PartnerIds.viaxoft) return <ViaxoftLogo />;
  if (partnerId === PartnerIds.candis) return <CandisLogo />;
  if (
    partnerId === PartnerIds.circula ||
    partnerId === PartnerIds.circulaDemo ||
    partnerId === PartnerIds.circulaEmbedded ||
    partnerId === PartnerIds.circulaFullyEmbedded
  )
    return <CirculaLogo />;
  if (
    partnerId === PartnerIds.mobilexpenseDeclaree ||
    partnerId === PartnerIds.mobilexpenseMXP
  )
    return <MobilexpenseLogo />;
  if (partnerId === PartnerIds.klippa) return <KlippaLogo />;
  if (partnerId === PartnerIds.mocoapp) return <MocoappLogo />;
  if (partnerId === PartnerIds.voxel) return <VoxelLogo />;
  if (partnerId === PartnerIds.conferma) return <ConfermaLogo />;
  // should be replaced once we have svg
  if (partnerId === PartnerIds.bas)
    return <img src={BasLogo} alt="BAS" height={45} />;
  if (partnerId === PartnerIds.scopevisio) return <ScopevisioLogo />;
  if (partnerId === PartnerIds.nelly) return <NellyLogo />;
  return null;
};

export default PartnerLogo;
