import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  Alert,
  AlertTitle,
  Box,
  CheckCircleIcon,
  CircularProgress,
  FlagPennantIcon,
  Tooltip,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Money,
  NetworkErrorCode,
  PrivateExpenseReimbursementTransactionDetailsResponse,
  PrivateExpenseStatus,
  ReviewFlagReason,
  TransactionReviewStatus,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import PrivateExpenseReviewComment from './PrivateExpenseReviewComment';

interface Props {
  memberId: string;
  reviewStatus: TransactionReviewStatus | null;
  reviewFlagReason: ReviewFlagReason | null;
  privateExpenseStatus: PrivateExpenseStatus | null;
  reviewComment: string | null;
  reviewedAt: string | null;
  reviewerFullName: string | null;
  reviewerEmail: string | null;
  transactionId: string;
  transactionAmount: Money;
  onReimbursementSuccess: () => void;
}

interface State {
  isLoading: boolean;
  additionalTransactionDetails: PrivateExpenseReimbursementTransactionDetailsResponse | null;
}

const TransactionReviewComment = ({
  memberId,
  reviewStatus,
  reviewFlagReason,
  privateExpenseStatus,
  reviewComment,
  reviewedAt,
  reviewerFullName,
  reviewerEmail,
  transactionId,
  transactionAmount,
  onReimbursementSuccess,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { featureModules, member },
  } = useGlobalState();
  const { companyReimbursementEnabled } = useFlags();
  const [state, setState] = useState<State>({
    isLoading: false,
    additionalTransactionDetails: null,
  });

  const canInitiateReimbursement =
    companyReimbursementEnabled &&
    featureModules.COMPANY_REIMBURSEMENT &&
    memberId === member?.id &&
    reviewFlagReason === ReviewFlagReason.privateExpense &&
    privateExpenseStatus === PrivateExpenseStatus.reimbursementOutstanding;

  useEffect(() => {
    if (
      companyReimbursementEnabled &&
      featureModules.COMPANY_REIMBURSEMENT &&
      reviewFlagReason === ReviewFlagReason.privateExpense &&
      privateExpenseStatus === PrivateExpenseStatus.reimbursementOutstanding
    ) {
      getPrivateExpenseReimbursementTransactionDetails();
    }
  }, [transactionId]);

  const getPrivateExpenseReimbursementTransactionDetails = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const additionalTransactionDetails = await api.getPrivateExpenseReimbursementTransactionDetails(
        transactionId
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        additionalTransactionDetails,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      if (getNetworkErrorCode(error) === NetworkErrorCode.notFound) {
        setState((prevState) => ({
          ...prevState,
          additionalTransactionDetails: null,
        }));
        return;
      }
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  if (state.isLoading)
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <CircularProgress />
      </Box>
    );

  if (
    companyReimbursementEnabled &&
    featureModules.COMPANY_REIMBURSEMENT &&
    reviewFlagReason === ReviewFlagReason.privateExpense &&
    privateExpenseStatus !== PrivateExpenseStatus.reimbursementNotSupported
  ) {
    return (
      <PrivateExpenseReviewComment
        privateExpenseStatus={privateExpenseStatus}
        reviewComment={reviewComment}
        reviewedAt={reviewedAt}
        reviewerFullName={reviewerFullName}
        reviewerEmail={reviewerEmail}
        transactionId={transactionId}
        transactionAmount={transactionAmount}
        additionalTransactionDetails={state.additionalTransactionDetails}
        canInitiateReimbursement={canInitiateReimbursement}
        onReimbursementSuccess={onReimbursementSuccess}
      />
    );
  } else if (reviewStatus === TransactionReviewStatus.flagged) {
    return (
      <Alert
        severity="error"
        icon={<FlagPennantIcon fontSize="inherit" />}
        sx={{ my: 2, mx: 3 }}
      >
        <AlertTitle>
          {reviewFlagReason
            ? t(`transactionDetails.reviewComment.flaggedAs`, {
                reviewFlagReason: t(`reviewFlagReasons.${reviewFlagReason}`),
              })
            : t(`transactionReviewStatus.${reviewStatus}`)}
        </AlertTitle>
        <Typography variant="body2">{reviewComment}</Typography>
        {reviewedAt && (
          <Typography
            mt={0.5}
            variant="overline2"
            component="div"
            textAlign="right"
          >
            <Tooltip title={reviewerEmail || ''}>
              <span>
                {t(`transactionDetails.reviewComment.info`, {
                  name: reviewerFullName,
                  date: moment(reviewedAt).format('DD.MM.YYYY'),
                })}
              </span>
            </Tooltip>
          </Typography>
        )}
      </Alert>
    );
  }

  if (reviewStatus === TransactionReviewStatus.resolved)
    return (
      <Alert
        severity="success"
        icon={<CheckCircleIcon fontSize="inherit" />}
        sx={{ my: 2, mx: 3 }}
      >
        <AlertTitle>
          {t(`transactionDetails.reviewComment.markedAsResolved`, {
            reviewFlagReason: t(`reviewFlagReasons.${reviewFlagReason}`),
          })}
        </AlertTitle>
        <Typography variant="body2">{reviewComment}</Typography>
        {reviewedAt && (
          <Typography
            mt={0.5}
            variant="overline2"
            component="div"
            textAlign="right"
          >
            <Tooltip title={reviewerEmail || ''}>
              <span>
                {t(`transactionDetails.reviewComment.info`, {
                  name: reviewerFullName,
                  date: moment(reviewedAt).format('DD.MM.YYYY'),
                })}
              </span>
            </Tooltip>
          </Typography>
        )}
      </Alert>
    );

  return null;
};

export default TransactionReviewComment;
