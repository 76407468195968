import { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import NothingFound from 'components/NothingFound';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingBreadcrumbs } from 'domains/onboarding/components';
import { useGetTasks } from 'domains/onboarding/hooks';
import {
  getNavigationPath,
  getOnboardingKeyTypeFromUrl,
  getTaskNavigationLinks,
} from 'domains/onboarding/utils';
import { PartnerLegalDisclaimer } from 'domains/partner/components';
import {
  Box,
  LoaderWithOverlay,
  MobileStepperProgress,
  Typography,
} from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useMounted from 'hooks/useMounted';
import { ContentContainer, PageContent, PageHeader } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingSections,
  OnboardingSectionType,
  OnboardingTask,
  OnboardingTaskAnnualRevenue,
  OnboardingTaskBalanceSheetTotal,
  OnboardingTaskCitizenships,
  OnboardingTaskCompanyType,
  OnboardingTaskDateOfBirth,
  OnboardingTaskFoundationDate,
  OnboardingTaskIdentification,
  OnboardingTaskOrgName,
  OnboardingTaskPepFatca,
  OnboardingTaskQA,
  OnboardingTaskRegAddress,
  OnboardingTaskRegNumber,
  OnboardingTasks,
  OnboardingTaskStaffHeadcount,
  OnboardingTaskTaxId,
  OnboardingTaskTerms,
  OnboardingTaskType,
  OnboardingTaskVatId,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { onboardingKeys } from 'services/network/queryKeys';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { useCanUser } from 'services/rbac';
import AnnualRevenueTask from './AnnualRevenueTask';
import BalanceSheetTotalTask from './BalanceSheetTotalTask';
import CitizenshipsTask from './CitizenshipsTask';
import CompanyTypeTask from './CompanyTypeTask';
import DateOfBirthTask from './DateOfBirth';
import FoundationDateTask from './FoundationDateTask';
import IdentificationTask from './IdentificationTask';
import PepFatcaTask from './PepFatcaTask';
import QATask from './QATask';
import RegisteredAddessTask from './RegisteredAddessTask';
import RegisteredNumberTask from './RegisteredNumberTask';
import StaffHeadcountTask from './StaffHeadcountTask';
import TaxIdTask from './TaxIdTask';
import TermsAndConditionsTask from './TermsAndConditionsTask';
import TradeNameTask from './TradeNameTask';
import VatIdTask from './VatIdTask';

const OrganizationOnboardingTaskPage = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const canUser = useCanUser();
  const history = useHistory();
  const mounted = useMounted();
  const { sectionKey, taskId, groupId = '' } = useParams<{
    sectionKey: string;
    taskId: string;
    groupId?: string;
  }>();
  const {
    state: { organization },
  } = useGlobalState();
  const [linkToNavigateOnUpdate, setLinkToNavigateOnUpdate] = useState('');
  const showPageError = useShowPageError();
  const queryClient = useQueryClient();
  const { useGetOnboardingSections } = useTanstackQuery();
  const {
    data: sectionsData,
    isFetching: areSectionsLoading,
  } = useGetOnboardingSections(organization!.id);
  const sections = sectionsData?.sections || [];
  const currentSectionType = useMemo(
    () => getOnboardingKeyTypeFromUrl<OnboardingSectionType>(sectionKey),
    [sectionKey]
  );
  const currentSectionObject = useMemo(
    () => sections.find((item) => item.type === currentSectionType),
    [sections, currentSectionType]
  );
  const { tasks, areTasksLoading, tasksError } = useGetTasks();

  const numberCompletedTasks = useMemo(
    () =>
      tasks.filter((task) =>
        [
          OnboardingItemStatus.completed,
          OnboardingItemStatus.submitted,
        ].includes(task.status)
      ).length,
    [tasks]
  );
  const shownTask = useMemo(
    () => tasks.find((task) => task.id === taskId) || null,
    [tasks, taskId]
  );

  const currentGroup = useMemo(
    () =>
      groupId
        ? currentSectionObject?.groups.find((g) => g.id === groupId) || null
        : null,
    [groupId, currentSectionObject]
  );

  const taskNavigationItems = useMemo(
    () => getTaskNavigationLinks(tasks, shownTask?.id),
    [tasks, shownTask]
  );

  useEffect(() => contentRef.current?.scrollTo({ top: 0 }), [
    history.location.pathname,
  ]);

  useEffect(() => {
    if (tasksError) {
      logError(tasksError);
      showPageError();
    }
  }, [tasksError]);

  // this useEffect is used in order to avoid memory leak and
  // wait until all data is refetched and correctly set
  useEffect(() => {
    if (linkToNavigateOnUpdate && !areSectionsLoading && !areTasksLoading)
      history.push(linkToNavigateOnUpdate);
  }, [linkToNavigateOnUpdate, areSectionsLoading, areTasksLoading]);

  // use when refetched data is important for the next interactions
  const onTaskInvalidate = async () => {
    await Promise.all([
      queryClient.invalidateQueries(
        onboardingKeys.tasks(organization!.id, currentSectionType)
      ),
      groupId &&
        queryClient.invalidateQueries(
          onboardingKeys.groupTasks(organization!.id, groupId)
        ),
      queryClient.invalidateQueries(onboardingKeys.sections(organization!.id)),
    ]);
  };

  const onTaskUpdate = async (
    newTask: OnboardingTask,
    disabledRedirection: boolean = false
  ) => {
    // update tasks and sections so that all tasks have up-to-date info
    await Promise.all([
      queryClient.invalidateQueries(
        onboardingKeys.tasks(organization!.id, currentSectionType)
      ),
      groupId &&
        queryClient.invalidateQueries(
          onboardingKeys.groupTasks(organization!.id, groupId)
        ),
      queryClient.invalidateQueries(onboardingKeys.sections(organization!.id)),
    ]);

    // override data with optimistic response in case when BE
    // returned outdated data
    if (!groupId) {
      // section tasks
      queryClient.setQueryData<OnboardingTasks>(
        onboardingKeys.tasks(organization!.id, currentSectionType),
        (prevState) => ({
          tasks:
            prevState?.tasks.map((task) =>
              task.id === newTask.id ? newTask : task
            ) || [],
        })
      );
    }

    if (groupId) {
      // group tasks
      queryClient.setQueryData<OnboardingTasks>(
        onboardingKeys.groupTasks(organization!.id, groupId),
        (prevState) => ({
          tasks:
            prevState?.tasks.map((task) =>
              task.id === newTask.id ? newTask : task
            ) || [],
        })
      );
    }
    queryClient.setQueryData<OnboardingSections>(
      onboardingKeys.sections(organization!.id),
      (prevState) => {
        const newSectionTask = omit(newTask, 'data');
        return {
          sections:
            prevState?.sections.map((section) => ({
              ...section,
              ...(groupId
                ? {
                    groups: section.groups.map((group) => ({
                      ...group,
                      tasks:
                        group.tasks.map((task) =>
                          task.id === newSectionTask.id ? newSectionTask : task
                        ) || [],
                    })),
                  }
                : {
                    tasks:
                      section.tasks.map((task) =>
                        task.id === newSectionTask.id ? newSectionTask : task
                      ) || [],
                  }),
            })) || [],
        };
      }
    );

    if (!mounted.current || disabledRedirection) return;
    // we won't redirect user directly from here,
    // as there are warnings about memory leak and seems state (refetch) is not
    // fully updated when we start redirection. Instead we use setting a "next link" to
    // the local state and wait until all API calls are fulfilled
    setLinkToNavigateOnUpdate(
      getNavigationPath(organization!.id, sectionKey, {
        taskId: taskNavigationItems.nextTaskId,
        groupId,
      })
    );
  };

  const renderTaskContent = () => {
    if (!shownTask) return null;
    const isReadOnly = !canUser('org-onboarding-task:change');

    switch (shownTask.type) {
      case OnboardingTaskType.organizationName:
        return (
          <TradeNameTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskOrgName}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.registeredAddress:
        return (
          <RegisteredAddessTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskRegAddress}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.registeredNumber:
        return (
          <RegisteredNumberTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskRegNumber}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.foundationDate:
        return (
          <FoundationDateTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskFoundationDate}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.vatId:
        return (
          <VatIdTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskVatId}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.annualRevenue:
        return (
          <AnnualRevenueTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskAnnualRevenue}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.balanceSheetTotal:
        return (
          <BalanceSheetTotalTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskBalanceSheetTotal}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.staffHeadcount:
        return (
          <StaffHeadcountTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskStaffHeadcount}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.companyType:
        return (
          <CompanyTypeTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskCompanyType}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.businessEstablishmentDocs:
      case OnboardingTaskType.proofOfAddress:
      case OnboardingTaskType.question:
      case OnboardingTaskType.idCopy:
      case OnboardingTaskType.shareholderList:
        return (
          <QATask
            key={shownTask.id}
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskQA}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
            onInvalidate={onTaskInvalidate}
          />
        );
      case OnboardingTaskType.pepFatca:
        return (
          <PepFatcaTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskPepFatca}
            group={currentGroup}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.taxId:
        return (
          <TaxIdTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskTaxId}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.identification:
        return (
          <IdentificationTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskIdentification}
            taskNavigationItems={taskNavigationItems}
            onTaskInvalidate={onTaskInvalidate}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.dateOfBirth:
        return (
          <DateOfBirthTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskDateOfBirth}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.citizenships:
        return (
          <CitizenshipsTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskCitizenships}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );
      case OnboardingTaskType.terms:
        return (
          <TermsAndConditionsTask
            isReadOnly={isReadOnly}
            task={shownTask as OnboardingTaskTerms}
            taskNavigationItems={taskNavigationItems}
            onUpdate={onTaskUpdate}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <PageHeader>
        <ContentContainer mx="auto" size="sm">
          <OnboardingBreadcrumbs task={shownTask} />

          <Box mt={5}>
            <Typography variant="subtitle2">
              {t('orgOnboardingTaskPage.stepper', {
                completed: numberCompletedTasks,
                total: tasks.length,
              })}
            </Typography>

            <MobileStepperProgress
              steps={tasks.length + 1}
              activeStep={numberCompletedTasks}
              nextButton={null}
              backButton={null}
              sx={{
                px: 0,
                '.MuiMobileStepper-progress': {
                  width: '100%',
                },
              }}
            />
          </Box>
        </ContentContainer>
      </PageHeader>

      <PageContent display="flex" flexDirection="column" ref={contentRef}>
        <ContentContainer mx="auto" size="sm" width="100%" flex={1}>
          {renderTaskContent()}
          {!shownTask && !areTasksLoading && <NothingFound />}
        </ContentContainer>

        <LoaderWithOverlay loading={areTasksLoading} />

        <PartnerLegalDisclaimer />
      </PageContent>
    </>
  );
};

export default OrganizationOnboardingTaskPage;
