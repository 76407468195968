import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserAddIcon from 'assets/icons/interface/userAdd.svg?react';
import { Button as Mui4Button } from 'components/Button';
import { useGlobalState } from 'context/GlobalState';
import { InviteMemberDialog } from 'domains/member/dialogs';
import InvitePartnerMemberDialog from 'domains/member/dialogs/InvitePartnerMemberDialog';
import { useAvailableRoles } from 'domains/member/utils';
import { Box, Button, ButtonProps, Tooltip, UserIcon } from 'elements';
import useInviteDialogThresholdExceededMessage from 'hooks/useInviteDialogThresholdExceededMessage';

interface Props extends ButtonProps {
  // `isPartnerMember` is used for partner member invite in Portal app
  isPartnerMember?: boolean;
  dataTestId?: string;
  dataIntercomTarget?: string;
  isNewDesign?: boolean;
  onSuccess?: () => void;
  onDialogOpen?: () => void;
}

const InviteMemberButtonWithDialog = ({
  isPartnerMember = false,
  dataTestId,
  dataIntercomTarget,
  isNewDesign = false,
  onSuccess,
  onDialogOpen,
  ...buttonProps
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { subscriptionPlan },
  } = useGlobalState();
  const thresholdExceededMessage = useInviteDialogThresholdExceededMessage();
  const { isInviteMemberAllowed } = useAvailableRoles({ isPartnerMember });
  const [isInviteMemberDialogOpen, setIsInviteMemberDialogOpen] = useState(
    false
  );

  if (!isInviteMemberAllowed || (!isPartnerMember && !subscriptionPlan))
    return null;

  const isButtonDisabled = () =>
    !!subscriptionPlan &&
    subscriptionPlan.maxActiveMembersCount !== null &&
    subscriptionPlan.activeAndInvitedMembersCount >=
      subscriptionPlan.maxActiveMembersCount;

  return (
    <>
      <Tooltip title={isButtonDisabled() ? thresholdExceededMessage : ''}>
        <Box display="inline-block">
          {isNewDesign ? (
            <Button
              data-test-id={dataTestId}
              data-intercom-target={dataIntercomTarget}
              onClick={() => {
                setIsInviteMemberDialogOpen(true);
                if (onDialogOpen) onDialogOpen();
              }}
              startIcon={<UserIcon />}
              disabled={isButtonDisabled()}
              {...buttonProps}
            >
              {t('inviteMemberButtonWithDialog.inviteMember')}
            </Button>
          ) : (
            <Mui4Button
              data-test-id={dataTestId}
              data-intercom-target={dataIntercomTarget}
              onClick={() => {
                setIsInviteMemberDialogOpen(true);
                if (onDialogOpen) onDialogOpen();
              }}
              startIcon={<UserAddIcon />}
              disabled={isButtonDisabled()}
            >
              {t('inviteMemberButtonWithDialog.inviteMember')}
            </Mui4Button>
          )}
        </Box>
      </Tooltip>
      {isPartnerMember ? (
        <InvitePartnerMemberDialog
          open={isInviteMemberDialogOpen}
          onClose={() => setIsInviteMemberDialogOpen(false)}
          onSuccess={() => {
            setIsInviteMemberDialogOpen(false);
            if (onSuccess) onSuccess();
          }}
        />
      ) : (
        <InviteMemberDialog
          subscriptionPlan={subscriptionPlan!}
          open={isInviteMemberDialogOpen}
          onClose={() => setIsInviteMemberDialogOpen(false)}
          onSuccess={() => {
            setIsInviteMemberDialogOpen(false);
            if (onSuccess) onSuccess();
          }}
        />
      )}
    </>
  );
};

export default InviteMemberButtonWithDialog;
