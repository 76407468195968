import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingTaskActions,
  TaskTitle,
} from 'domains/onboarding/components';
import { Box, Grid, LoaderWithOverlay, TextField } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingTaskRegNumber,
  TaskNavigationPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg, trimObjValues } from 'services/utils';

interface FormValues {
  nationalRegisterCourt: string;
  registrationNumber: string;
}

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskRegNumber;
  onUpdate: (newTask: OnboardingTaskRegNumber) => void;
}

const RegisteredNumberTask = ({
  isReadOnly,
  task,
  taskNavigationItems,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const { useUpdateRegisteredNumber } = useTanstackQuery();
  const { mutate: orgRegNumberMutate } = useUpdateRegisteredNumber({
    onSuccess: (response) => {
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization: {
            ...organization!,
            tradeRegisterNumber: response.data.registrationNumber.value || '',
            nationalRegisterCourt:
              response.data.nationalRegisterCourt.value || '',
          },
        },
      });
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
      formik.setSubmitting(false);
    },
  });

  const {
    data: { nationalRegisterCourt, registrationNumber },
  } = task;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      nationalRegisterCourt: nationalRegisterCourt.value ?? '',
      registrationNumber: registrationNumber.value ?? '',
    },
    validate: (values) => {
      const errors: { [k in keyof FormValues]?: string } = {};
      if (
        task.data.nationalRegisterCourt.required &&
        !values.nationalRegisterCourt
      ) {
        errors['nationalRegisterCourt'] = t('common.errors.fieldRequired');
      }
      return errors;
    },
    onSubmit: (values) => {
      orgRegNumberMutate({
        organizationId: organization!.id,
        taskId: task.id,
        data: trimObjValues(values),
      });
    },
  });

  const hasEmptyField =
    (nationalRegisterCourt.required &&
      !formik.values.nationalRegisterCourt.trim()) ||
    !formik.values.registrationNumber.trim();

  return (
    <>
      <PageTitle pt={0} pb={3} title={<TaskTitle task={task} />} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            disabled={
              isReadOnly || formik.isSubmitting || !registrationNumber.editable
            }
            label={t('orgOnboardingRegNumberTask.registrationNumberLabel')}
            placeholder={t(
              'orgOnboardingRegNumberTask.registrationNumberPlaceholder'
            )}
            {...formik.getFieldProps('registrationNumber')}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            disabled={
              isReadOnly ||
              formik.isSubmitting ||
              !nationalRegisterCourt.editable
            }
            label={t('orgOnboardingRegNumberTask.nationalRegisterCourtLabel')}
            placeholder={t(
              'orgOnboardingRegNumberTask.nationalRegisterCourtPlaceholder'
            )}
            {...formik.getFieldProps('nationalRegisterCourt')}
            error={!!formik.errors.nationalRegisterCourt}
            helperText={formik.errors.nationalRegisterCourt}
          />
        </Grid>
      </Grid>

      <Box mt={3}>
        <OnboardingTaskActions
          isReadOnly={
            isReadOnly ||
            !(registrationNumber.editable || nationalRegisterCourt.editable)
          }
          taskNavigationItems={taskNavigationItems}
          disabled={
            formik.isSubmitting ||
            hasEmptyField ||
            (task.status !== OnboardingItemStatus.requiresAction &&
              !formik.dirty)
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default RegisteredNumberTask;
