import { ComponentType, FC, useEffect, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import { Helmet } from 'react-helmet';
import { createPartnerTheme } from 'components/App/style/themeMui5';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';
import { PartnerMemberMappedDetails } from 'services/constants';

const withTheme = <P extends object>(Component: ComponentType<P>): FC<P> => (
  props: P
): JSX.Element | null => {
  const assetsFolderPath = {
    development: `${env.REACT_APP_ASSETS_URL}/images/dev`,
    staging: `${env.REACT_APP_ASSETS_URL}/images/staging`,
    prod: `${env.REACT_APP_ASSETS_URL}/images/v2`,
  }[env.REACT_APP_TARGET_ENV];

  const {
    dispatch,
    state: { member },
  } = useGlobalState();
  const partnerId = (member as PartnerMemberMappedDetails).partnerIds[0];
  // Currently we set theme based on partnerId, no matter which whitelabel level
  // the partner has. In the future the logic in this component should rely on "partnerCompanyId"
  // instead of "partnerId". "partnerCompanyId" represents a membership of partners,
  // e.g. "circula", "circulaDemo", "circulaEmbedded", etc === 1 partner membership
  const theme = useMemo(() => createPartnerTheme(partnerId), [partnerId]);

  useEffect(() => {
    // set default portal app theme
    // to minimize theme changes
    dispatch({
      type: 'SET_THEME_ID',
      payload: partnerId,
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link
          type="image/x-icon"
          rel="shortcut icon"
          href={`${assetsFolderPath}/favicon.ico`}
        />
        <link
          type="image/png"
          sizes="16x16"
          rel="icon"
          href={`${assetsFolderPath}/favicon-16x16.png`}
        />
        <link
          type="image/png"
          sizes="32x32"
          rel="icon"
          href={`${assetsFolderPath}/favicon-32x32.png`}
        />
        <link
          type="image/png"
          sizes="96x96"
          rel="icon"
          href={`${assetsFolderPath}/favicon-96x96.png`}
        />
        <title>Portal</title>
      </Helmet>
      <Component {...props} />
    </ThemeProvider>
  );
};
export default withTheme;
