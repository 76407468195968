import { intersection, pick } from 'lodash';
import moment from 'moment';
import {
  AccountingItemStatus,
  AccountingSettings,
  AccountingTransaction,
  ExportFlowTransaction,
  FeatureModuleValueByKeyMap,
  NONE_VALUE,
  privateExpenseStatuses,
  Project,
  ProjectStatus,
  reviewFlagReasons,
  Subcategory,
  SubcategoryStatus,
  Supplier,
  Team,
  Transaction,
  TransactionExportStatus,
  transactionExportStatuses,
  transactionReceiptStatuses,
  TransactionReviewStatus,
  transactionReviewStatuses,
  TransactionSimpleType,
  VatRateStatus,
} from 'services/constants';
import {
  getValidQueryParamValue,
  getValidQueryParamValues,
} from 'services/utils';

export const MISSING_SUPPLIER_OPTION = 'MISSING';
export const MISSING_TEAM_OPTION = 'MISSING';

// creates an object composed of values that are included in Transaction and ExportFlowTransaction
export const getSameTransactionsFields = (
  transaction: Transaction,
  exportFlowTransaction: ExportFlowTransaction
): Partial<ExportFlowTransaction> => {
  const transactionKeys = Object.keys(transaction);
  const exportFlowTransactionKeys = Object.keys(exportFlowTransaction);
  const sameKeys = intersection(transactionKeys, exportFlowTransactionKeys);
  return {
    ...pick(transaction, sameKeys),
    bookingDate: transaction.bookingDate!,
  };
};

export const getSubcategoryFields = (
  accountingTransactions: AccountingTransaction[]
) => {
  const ids = accountingTransactions.map((item) => item.subcategoryId);
  if (ids.some((id) => !id)) {
    return {
      subcategoryId: null,
      subcategoryStatus: SubcategoryStatus.missing,
    };
  }
  if (ids.some((id) => id !== ids[0])) {
    return {
      subcategoryId: null,
      subcategoryStatus: SubcategoryStatus.multiple,
    };
  }
  return {
    subcategoryId: ids[0]!,
    subcategoryStatus: SubcategoryStatus.single,
  };
};

export const getVatRateFields = (
  accountingTransactions: AccountingTransaction[]
) => {
  const ids = accountingTransactions.map((item) => item.vatRateId);
  if (ids.some((id) => !id)) {
    return {
      vatRateId: null,
      vatRateStatus: VatRateStatus.missing,
    };
  }
  if (ids.some((id) => id !== ids[0])) {
    return {
      vatRateId: null,
      vatRateStatus: VatRateStatus.multiple,
    };
  }
  return {
    vatRateId: ids[0]!,
    vatRateStatus: VatRateStatus.single,
  };
};

export const getProjectFields = (
  accountingTransactions: AccountingTransaction[]
) => {
  const ids = accountingTransactions.map((item) => item.projectId);
  if (ids.some((id) => !id)) {
    return { projectId: null, projectStatus: ProjectStatus.missing };
  }
  if (ids.some((id) => id !== ids[0])) {
    return { projectId: null, projectStatus: ProjectStatus.multiple };
  }
  return { projectId: ids[0]!, projectStatus: ProjectStatus.single };
};

export const mergeTransactionDetailsFieldsToExportFlowTransaction = (
  exportFlowTransaction: ExportFlowTransaction,
  transaction: Transaction,
  accountingTransactions: AccountingTransaction[]
): ExportFlowTransaction => ({
  ...exportFlowTransaction,
  ...getSameTransactionsFields(transaction, exportFlowTransaction),
  ...getSubcategoryFields(accountingTransactions),
  ...getVatRateFields(accountingTransactions),
  ...getProjectFields(accountingTransactions),
});

export const getSubcategoryFilterApiParams = (value: string) => {
  const subcategoryId =
    !!value && value !== SubcategoryStatus.missing ? value : undefined;
  const subcategoryStatus =
    value === SubcategoryStatus.missing ? SubcategoryStatus.missing : undefined;
  return { subcategoryId, subcategoryStatus };
};

export const getVatRateFilterApiParams = (value: string) => {
  const vatRateId =
    !!value && value !== VatRateStatus.missing ? value : undefined;
  const vatRateStatus =
    value === VatRateStatus.missing ? VatRateStatus.missing : undefined;
  return { vatRateId, vatRateStatus };
};

export const getProjectFilterApiParams = (value: string) => {
  const projectId =
    !!value && value !== ProjectStatus.missing ? value : undefined;
  const projectStatus =
    value === ProjectStatus.missing ? ProjectStatus.missing : undefined;
  return { projectId, projectStatus };
};

export const visibleTransactionTypes = [
  TransactionSimpleType.purchase,
  TransactionSimpleType.refund,
  TransactionSimpleType.chargeback,
  TransactionSimpleType.recharge,
];

export const getValidExportStatusParam = (exportStatus: string | null) => {
  return transactionExportStatuses.includes(exportStatus as any)
    ? (exportStatus as TransactionExportStatus)
    : TransactionExportStatus.notExported;
};

export const getSupplierQueryParam = (
  supplier: string | undefined = '',
  accountingSettings: AccountingSettings | null,
  suppliers: Supplier[] | null
) => {
  if (!accountingSettings?.supplierEnabled || !suppliers?.length) return '';
  if (supplier === MISSING_SUPPLIER_OPTION) return supplier;
  const ids = suppliers.map((item) => item.id);
  return ids.includes(supplier) ? supplier : '';
};

export const getSubcategoryQueryParam = (
  subcategory: string | undefined = '',
  accountingSettings: AccountingSettings | null,
  subcategories: Subcategory[] | null
) => {
  if (!accountingSettings?.subcategoryEnabled || !subcategories) return '';
  if (subcategory === SubcategoryStatus.missing) {
    return subcategory;
  }
  const visibleIds = subcategories
    .filter((item) => item.status === AccountingItemStatus.active)
    .map((item) => item.id);
  return visibleIds.includes(subcategory) ? subcategory : '';
};

export const getProjectQueryParam = (
  project: string | undefined = '',
  accountingSettings: AccountingSettings | null,
  projects: Project[] | null
) => {
  if (!accountingSettings?.projectEnabled || !projects) return '';
  if (project === ProjectStatus.missing) {
    return project;
  }
  const visibleIds = projects
    .filter((item) => item.status === AccountingItemStatus.active)
    .map((item) => item.id);
  return visibleIds.includes(project) ? project : '';
};

export const getTeamQueryParam = (
  teamId: string | undefined = '',
  teams: Team[]
) => {
  if (teamId === MISSING_TEAM_OPTION) return teamId;
  const ids = teams.map((team) => team.id);
  return ids.includes(teamId) ? teamId : '';
};

export const getQueryParams = (
  qs: string,
  accountingSettings: AccountingSettings | null,
  suppliers: Supplier[] | null,
  subcategories: Subcategory[] | null,
  projects: Project[] | null,
  teams: Team[],
  cardAccountIds: string[],
  featureModules: FeatureModuleValueByKeyMap
) => {
  const {
    q,
    exportStatus,
    type,
    fromDate,
    toDate,
    receipt,
    supplier,
    subcategory,
    vatRate,
    project,
    team,
    reviewStatus,
    flagReason,
    privateExpenseStatus,
    cardAccountId,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  const visibleReviewStatuses: (
    | TransactionReviewStatus
    | typeof NONE_VALUE
  )[] = featureModules.MANAGER_TX_REVIEWS
    ? [...transactionReviewStatuses, NONE_VALUE]
    : [
        TransactionReviewStatus.flagged,
        TransactionReviewStatus.resolved,
        NONE_VALUE,
      ];

  return {
    q: q ? q.trim() : '',
    exportStatus: getValidExportStatusParam(exportStatus),
    type: getValidQueryParamValues(type, visibleTransactionTypes),
    receipt: getValidQueryParamValue(receipt, transactionReceiptStatuses),
    supplier: getSupplierQueryParam(supplier, accountingSettings, suppliers),
    subcategory: getSubcategoryQueryParam(
      subcategory,
      accountingSettings,
      subcategories
    ),
    vatRate: accountingSettings?.vatRateEnabled ? vatRate : '',
    project: getProjectQueryParam(project, accountingSettings, projects),
    team: getTeamQueryParam(team, teams),
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
    reviewStatus: getValidQueryParamValue(reviewStatus, visibleReviewStatuses),
    flagReason: featureModules.PRIVATE_EXPENSE
      ? getValidQueryParamValue(flagReason, reviewFlagReasons)
      : '',
    privateExpenseStatus: featureModules.PRIVATE_EXPENSE
      ? getValidQueryParamValues(privateExpenseStatus, privateExpenseStatuses)
      : [],
    cardAccountId: getValidQueryParamValue(cardAccountId, cardAccountIds),
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

export type RequestParams = {
  q?: string;
  organizationId: string;
  type?: string;
  receiptNeeded?: boolean;
  receiptMissing?: boolean;
  fromDate?: string;
  toDate?: string;
  supplierId?: string;
  isSupplierMissing?: boolean;
  subcategoryStatus?: string;
  subcategoryId?: string;
  vatRateStatus?: string;
  vatRateId?: string;
  projectStatus?: string;
  projectId?: string;
  teamId?: string;
  isTeamMissing?: boolean;
  reviewStatus?: string;
  flagReason?: string;
  privateExpenseStatus?: string[];
  cardAccountId?: string;
};

export const getSelectedFiltersCount = ({
  type,
  receipt,
  supplier,
  subcategory,
  vatRate,
  project,
  team,
  fromDate,
  reviewStatus,
  flagReason,
  privateExpenseStatus,
  cardAccountId,
}: QueryParams) =>
  +!!supplier +
  +!!subcategory +
  +!!vatRate +
  +!!project +
  +!!team +
  +!!type.length +
  +!!receipt.length +
  +!!fromDate +
  +!!reviewStatus +
  +!!flagReason +
  +!!privateExpenseStatus.length +
  +!!cardAccountId;
