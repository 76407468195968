import { useState } from 'react';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  LoaderWithOverlay,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { logError } from 'services/monitoring';
import { getGenericErrorMsg } from 'services/utils';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

interface State {
  isLoading: boolean;
  errorMessage?: string;
  incompleteForm: boolean;
}

const STRIPE_SUCCESS_STATUSES = ['succeeded', 'requires_capture'];

const Step2 = (props: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] = useState<State>({
    isLoading: false,
    errorMessage: '',
    incompleteForm: false,
  });

  const handleSubmit = async () => {
    setState({ ...state, isLoading: true });

    try {
      const { error, paymentIntent } = await stripe!.confirmPayment({
        elements: elements!,
        confirmParams: {
          return_url: window.location.origin,
        },
        redirect: 'if_required',
      });

      if (!mounted.current) return;
      setState({ ...state, isLoading: false });

      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else if (
        paymentIntent &&
        STRIPE_SUCCESS_STATUSES.includes(paymentIntent.status)
      ) {
        enqueueSnackbar(
          t(
            'transactionDetails.reimbursementDialog.initiateReimbursementSuccess'
          ),
          {
            variant: 'success',
          }
        );
        props.onSuccess();
      }
    } catch (error) {
      if (!mounted.current) return;
      setState({ ...state, isLoading: false });
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DialogContent>
        <PaymentElement />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onCancel}
          variant="text"
          children={t('common.button.cancel')}
        />
        <Button
          onClick={handleSubmit}
          disabled={
            !stripe || !elements || state.isLoading || state.incompleteForm
          }
          children={t('common.button.confirm')}
        />
      </DialogActions>
      <LoaderWithOverlay loading={state.isLoading} />
    </>
  );
};

export default Step2;
