import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import Auth0Callback from 'components/App/Auth0Callback';
import AppErrorOverlay from 'components/AppErrorOverlay';
import OrganizationData from 'components/OrganizationData';
import {
  BillingPageRedirect,
  BillingPaymentsPage,
  BillingStatementsPage,
} from 'domains/billing/pages';
import { CardAccountsPage, CardsPage } from 'domains/card/pages';
import {
  AdminProfilePage,
  MembersPage,
  PartnerMembersPage,
  TeamsPage,
  UsersPage,
} from 'domains/member/pages';
import { OrganizationMerchantsPage } from 'domains/merchant';
import {
  DashboardPage,
  OrganizationsPage,
  PliantEarthPage,
} from 'domains/organization/pages';
import { EmbeddedPartnerPage } from 'domains/partner';
import { RewardsPage } from 'domains/reward/pages';
import { Settings } from 'domains/settings';
import {
  AccountEntriesPage,
  AdminTransactionsPage,
  ExportPage,
  TransactionsFlaggedPage,
  TransactionsNeedsReviewPage,
} from 'domains/transaction/pages';
import PortalUserData from './PortalUserData';
import PortalLayout from './layouts/PortalLayout';
import PortalOrgLayout from './layouts/PortalOrgLayout';
import {
  portalOrgPaths,
  portalOrgPathsArray,
  portalPathsArray,
  portalRootPaths,
  portalRootPathsArray,
} from './paths';

function Routes() {
  return (
    <Switch>
      <Route path="/unauthorized">
        <AppErrorOverlay errorCode="UNAUTHORIZED" />
      </Route>
      <Route path="/callback">
        <Auth0Callback />
      </Route>
      <Route exact path={portalPathsArray}>
        <PortalUserData>
          <Route exact path={portalRootPathsArray}>
            <PortalLayout>
              <Route
                exact
                path={portalRootPaths.root}
                render={() => <Redirect to={portalRootPaths.organizations} />}
              />
              <Route
                exact
                path={portalRootPaths.organizations}
                component={OrganizationsPage}
              />
              <Route
                path={portalRootPaths.profile}
                component={AdminProfilePage}
              />
              <Route path={portalRootPaths.users} component={UsersPage} />
              <Route
                exact
                path={portalRootPaths.userDetailsPartial}
                render={(params) => (
                  <Redirect
                    to={`${portalRootPaths.users}${params.location.search}`}
                  />
                )}
              />
              <Route
                path={portalRootPaths.partnerMembers}
                component={PartnerMembersPage}
              />
            </PortalLayout>
          </Route>

          <Route path={portalOrgPathsArray}>
            <PortalOrgLayout>
              <OrganizationData>
                <Route
                  exact
                  path={portalOrgPaths.root}
                  render={({ match }) => (
                    <Redirect
                      to={generatePath(portalOrgPaths.dashboard, match.params)}
                    />
                  )}
                />
                <Route
                  exact
                  path={portalOrgPaths.dashboard}
                  component={DashboardPage}
                />
                <Route path={portalOrgPaths.members} component={MembersPage} />
                <Route path={portalOrgPaths.teams} component={TeamsPage} />
                <Route path={portalOrgPaths.cards} component={CardsPage} />
                <Route
                  path={portalOrgPaths.cardAccounts}
                  component={CardAccountsPage}
                />
                <Route
                  path={portalOrgPaths.transactions}
                  component={AdminTransactionsPage}
                />
                <Route
                  path={portalOrgPaths.accounting}
                  component={AccountEntriesPage}
                />
                <Route
                  path={portalOrgPaths.transactionsReview}
                  component={TransactionsNeedsReviewPage}
                />
                <Route
                  path={portalOrgPaths.transactionsFlagged}
                  component={TransactionsFlaggedPage}
                />
                <Route
                  path={[
                    portalOrgPaths.export,
                    portalOrgPaths.exportQueue,
                    portalOrgPaths.exported,
                  ]}
                  component={ExportPage}
                />
                <Route
                  path={portalOrgPaths.billing}
                  component={BillingPageRedirect}
                />
                <Route
                  path={portalOrgPaths.billingPayments}
                  component={BillingPaymentsPage}
                />
                <Route
                  path={portalOrgPaths.billingStatements}
                  component={BillingStatementsPage}
                />
                <Route
                  path={portalOrgPaths.merchants}
                  component={OrganizationMerchantsPage}
                />
                <Route path={portalOrgPaths.rewards} component={RewardsPage} />
                <Route
                  path={portalOrgPaths.partner}
                  component={EmbeddedPartnerPage}
                />
                <Route
                  path={portalOrgPaths.pliantEarth}
                  component={PliantEarthPage}
                />
                <Route path={portalOrgPaths.settings} component={Settings} />
              </OrganizationData>
            </PortalOrgLayout>
          </Route>
        </PortalUserData>
      </Route>

      <Route path="*">
        <AppErrorOverlay errorCode="PAGE_NOT_FOUND" />
      </Route>
    </Switch>
  );
}

export default Routes;
