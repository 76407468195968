import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { portalPaths } from 'components/App/PortalApp/paths';
import { Alert, AlertTitle, Button, CaretLeftIcon } from 'elements';

export const alertHeight = 48;

const MirrorViewAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert
      severity="info"
      sx={{
        py: 0,
        height: `${alertHeight}px`,
        borderRadius: 0,
        alignItems: 'center',
      }}
    >
      <AlertTitle sx={{ mb: 0, display: 'flex', alignItems: 'center' }}>
        {t('partnerPortal.mirrorViewMsg')}
        <Button
          component={RouterLink}
          to={portalPaths.organizations}
          onClick={(e: any) => e.stopPropagation()}
          color="inherit"
          variant="outlined"
          size="small"
          sx={{ ml: 2 }}
          startIcon={<CaretLeftIcon />}
        >
          {t('partnerPortal.backToOrgsBtn')}
        </Button>
      </AlertTitle>
    </Alert>
  );
};

export default MirrorViewAlert;
