import { useState } from 'react';
import { TFunction } from 'i18next';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CreditCardIcon,
  FlagPennantIcon,
  Tooltip,
  Typography,
} from 'elements';
import useCurrentApp from 'hooks/useCurrentApp';
import {
  Money,
  PrivateExpenseReimbursementTransactionDetailsResponse,
  PrivateExpenseStatus,
} from 'services/constants';
import InitiatingReimbursementDialog from './InitiatingReimbursementDialog';

const getAlertSeverity = (
  privateExpenseStatus: PrivateExpenseStatus | null,
  lastReimbursementFailed: boolean
) => {
  switch (privateExpenseStatus) {
    case PrivateExpenseStatus.reimbursementOutstanding:
      return lastReimbursementFailed ? 'error' : 'warning';
    case PrivateExpenseStatus.reimbursementPending:
      return 'info';
    case PrivateExpenseStatus.reimbursed:
    case PrivateExpenseStatus.reimbursedOffline:
      return 'success';
    default:
      return 'error';
  }
};

const getAlertTitle = (
  privateExpenseStatus: PrivateExpenseStatus | null,
  t: TFunction
) => {
  if (
    privateExpenseStatus &&
    privateExpenseStatus !== PrivateExpenseStatus.reimbursementNotSupported
  ) {
    return t(`transactionDetails.reviewComment.titles.${privateExpenseStatus}`);
  }
  return '';
};

const getAlertDescription = (
  privateExpenseStatus: PrivateExpenseStatus | null,
  lastReimbursementFailed: boolean,
  reviewerFullName: string | null,
  reviewComment: string | null
) => {
  if (privateExpenseStatus === PrivateExpenseStatus.reimbursedOffline) {
    return reviewComment;
  } else if (
    privateExpenseStatus === PrivateExpenseStatus.reimbursementOutstanding &&
    lastReimbursementFailed
  ) {
    return (
      <Trans
        i18nKey="transactionDetails.reviewComment.descriptions.REIMBURSEMENT_OUTSTANDING_WITH_ERROR"
        values={{
          name: reviewerFullName,
          comment: reviewComment,
        }}
        components={{ b: <b /> }}
      />
    );
  }

  return (
    <Trans
      i18nKey={`transactionDetails.reviewComment.descriptions.${privateExpenseStatus}`}
      values={{
        comment: reviewComment,
      }}
      components={{ b: <b /> }}
    />
  );
};

const getAlertReviewInfo = (
  privateExpenseStatus: PrivateExpenseStatus | null,
  lastReimbursementFailed: boolean,
  reviewerFullName: string | null,
  reviewedAt: string,
  t: TFunction
) => {
  if (
    privateExpenseStatus === PrivateExpenseStatus.reimbursedOffline ||
    (privateExpenseStatus === PrivateExpenseStatus.reimbursementOutstanding &&
      !lastReimbursementFailed)
  ) {
    return t(`transactionDetails.reviewComment.info`, {
      name: reviewerFullName,
      date: moment(reviewedAt).format('DD.MM.YYYY'),
    });
  } else if (
    privateExpenseStatus === PrivateExpenseStatus.reimbursementPending ||
    (privateExpenseStatus === PrivateExpenseStatus.reimbursementOutstanding &&
      lastReimbursementFailed)
  ) {
    return moment(reviewedAt).format('DD.MM.YYYY');
  }
};

interface Props {
  privateExpenseStatus: PrivateExpenseStatus | null;
  reviewComment: string | null;
  reviewedAt: string | null;
  reviewerFullName: string | null;
  reviewerEmail: string | null;
  transactionId: string;
  transactionAmount: Money;
  additionalTransactionDetails: PrivateExpenseReimbursementTransactionDetailsResponse | null;
  canInitiateReimbursement: boolean;
  onReimbursementSuccess: () => void;
}

interface State {
  initiatingReimbursement: boolean;
}

const PrivateExpenseReviewComment = ({
  privateExpenseStatus,
  reviewComment,
  reviewedAt,
  reviewerFullName,
  reviewerEmail,
  transactionId,
  transactionAmount,
  additionalTransactionDetails,
  canInitiateReimbursement,
  onReimbursementSuccess,
}: Props) => {
  const { t } = useTranslation();
  const { isAdminApp } = useCurrentApp();
  const [state, setState] = useState<State>({
    initiatingReimbursement: false,
  });

  const initiateReimbursement = () => {
    setState((prevState) => ({
      ...prevState,
      initiatingReimbursement: true,
    }));
  };

  return (
    <>
      <Alert
        severity={getAlertSeverity(
          privateExpenseStatus,
          !!additionalTransactionDetails?.error
        )}
        icon={<FlagPennantIcon fontSize="inherit" />}
        sx={canInitiateReimbursement ? { borderRadius: 0 } : { my: 2, mx: 3 }}
      >
        <AlertTitle>{getAlertTitle(privateExpenseStatus, t)}</AlertTitle>

        <Typography variant="body2">
          {getAlertDescription(
            privateExpenseStatus,
            !!additionalTransactionDetails?.error,
            reviewerFullName,
            reviewComment
          )}
        </Typography>

        {canInitiateReimbursement && (
          <Box mt={1} textAlign="right">
            <Button color="primary" onClick={initiateReimbursement}>
              <CreditCardIcon sx={{ mr: 1 }} />
              {t('transactionDetails.reviewComment.initiateReimbursement')}
            </Button>
          </Box>
        )}

        {!!additionalTransactionDetails?.error && (
          <Box mt={4}>
            <AlertTitle>
              {t('transactionDetails.reviewComment.reimbursementAttemptFailed')}
            </AlertTitle>
            <Typography variant="body2">
              {additionalTransactionDetails.error}
            </Typography>
          </Box>
        )}

        {reviewedAt &&
          !canInitiateReimbursement &&
          privateExpenseStatus !== PrivateExpenseStatus.reimbursed &&
          (isAdminApp ||
            (!isAdminApp &&
              privateExpenseStatus ===
                PrivateExpenseStatus.reimbursedOffline)) && (
            <Typography
              mt={0.5}
              variant="overline2"
              component="div"
              textAlign="right"
            >
              <Tooltip title={reviewerEmail || ''}>
                <span>
                  {getAlertReviewInfo(
                    privateExpenseStatus,
                    !!additionalTransactionDetails?.error,
                    reviewerFullName,
                    reviewedAt,
                    t
                  )}
                </span>
              </Tooltip>
            </Typography>
          )}
      </Alert>

      {canInitiateReimbursement && (
        <InitiatingReimbursementDialog
          open={state.initiatingReimbursement}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              initiatingReimbursement: false,
            }))
          }
          onReimbursementSuccess={onReimbursementSuccess}
          transactionId={transactionId}
          transactionAmount={transactionAmount}
        />
      )}
    </>
  );
};

export default PrivateExpenseReviewComment;
