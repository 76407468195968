import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { getDocumentUrl } from 'domains/document/utils';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { InitiateReimbursementResponse } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { Steps } from '..';

interface Props {
  onClose: () => void;
  goToNextStep: (step: Steps, response: InitiateReimbursementResponse) => void;
  transactionId: string;
  alreadyAcceptedTermsAndConditions: boolean;
}

interface State {
  acceptedTermsAndConditions: boolean;
  isConfirming: boolean;
}

const NoPaymentMethod = (props: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { member, organization },
  } = useGlobalState();
  const mounted = useMounted();
  const [state, setState] = useState<State>({
    acceptedTermsAndConditions: props.alreadyAcceptedTermsAndConditions,
    isConfirming: false,
  });

  const acceptTermsAndConditionsOrContinue = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isConfirming: true,
      }));
      if (!props.alreadyAcceptedTermsAndConditions) {
        await api.acceptCardholderReimbursementTermsAndConditions(
          member.id,
          organization!.id
        );
      }
      const response = await api.initiateReimbursement(
        member.id,
        props.transactionId
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConfirming: false,
      }));
      props.goToNextStep(Steps.PAYMENT_DETAILS, response);
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConfirming: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DialogContent>
        <LoaderWithOverlay loading={state.isConfirming} />
        <>
          <Typography variant="body1">
            {t(
              'transactionDetails.reimbursementDialog.descriptions.noPaymentMethod'
            )}
          </Typography>

          {!props.alreadyAcceptedTermsAndConditions && (
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.acceptedTermsAndConditions}
                    onChange={() =>
                      setState((prevState) => ({
                        ...prevState,
                        acceptedTermsAndConditions: !prevState.acceptedTermsAndConditions,
                      }))
                    }
                  />
                }
                label={
                  <Typography variant="body2" color="text.secondary">
                    <Trans
                      i18nKey="transactionDetails.reimbursementDialog.termsAndConditions"
                      components={{
                        a: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            style={{ color: 'inherit' }}
                            href={getDocumentUrl(
                              'COMPANY_REIMBURSEMENT_CARDHOLDER.pdf'
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                      }}
                    />
                  </Typography>
                }
              />
            </Box>
          )}
        </>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={props.onClose}
          children={t('common.button.cancel')}
        />
        <Button
          onClick={acceptTermsAndConditionsOrContinue}
          disabled={!state.acceptedTermsAndConditions}
          children={t('common.button.confirm')}
        />
      </DialogActions>
    </>
  );
};

export default NoPaymentMethod;
