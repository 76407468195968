import { useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { QueryParams } from 'domains/transaction/pages/ExportPage/utils';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { VatRate, VatRateStatus } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

export interface VatRateData {
  value: VatRate | VatRateStatus.missing | null;
  isLoading: boolean;
  isError: boolean;
}

// helper for checking whether we should refetch data or not
const isValueMismatched = (param: string, stateValue: VatRateData['value']) => {
  // Case 1: Missing status mismatch
  if (param === VatRateStatus.missing)
    return stateValue !== VatRateStatus.missing;
  // Case 2: No value exists for the particular parameter
  if (param && !stateValue) return true;
  // Case 3: Parameter represents an ID, and values differ
  if (param)
    return stateValue === VatRateStatus.missing || param !== stateValue!.id;

  return false;
};

const useFilters = (params: Pick<QueryParams, 'vatRate'>) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const mounted = useMounted();
  const {
    state: { organization },
  } = useGlobalState();
  const [vatRateData, setVatRateData] = useState<VatRateData>({
    value: null,
    isLoading: false,
    isError: false,
  });

  const getVatRate = async () => {
    if (params.vatRate === VatRateStatus.missing) {
      setVatRateData((prevState) => ({
        ...prevState,
        value: VatRateStatus.missing,
      }));
      return;
    }

    setVatRateData((prevState) => ({ ...prevState, isLoading: true }));
    try {
      if (!mounted.current) return;
      const { valid, invalid } = await api.checkVatRates({
        organizationId: organization!.id,
        vatRateIds: [params.vatRate],
      });
      setVatRateData((prevState) => ({
        ...prevState,
        value: valid[0] || null,
        isError: !!invalid.length,
        isLoading: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setVatRateData((prevState) => ({
        ...prevState,
        isLoading: false,
        isError: true,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), {
        variant: 'error',
      });
      logError(error);
    }
  };

  useEffect(() => {
    // automatically reset state when no param
    if (!params.vatRate) {
      setVatRateData((prevState) => ({
        ...prevState,
        value: null,
        isError: false,
      }));
      return;
    }

    if (isValueMismatched(params.vatRate, vatRateData.value)) getVatRate();
  }, [params.vatRate]);

  // extend when we have other dynamic filters
  const setDataFilters = ({
    vatRate,
  }: {
    vatRate: Pick<VatRateData, 'value'>;
  }) => {
    setVatRateData((prevState) => ({
      ...prevState,
      ...vatRate,
      isError: false,
    }));
  };

  return {
    vatRateData,
    areFiltersLoading: vatRateData.isLoading,
    setDataFilters,
  };
};

export default useFilters;
