import { useEffect, useState } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { Box, CircularProgress } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  InitiateReimbursementResponse,
  PaymentMethod,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { Steps } from '..';
import NoPaymentMethod from './NoPaymentMethod';
import WithPaymentMethod from './WithPaymentMethod';

interface Props {
  onClose: () => void;
  goToNextStep: (step: Steps, response: InitiateReimbursementResponse) => void;
  transactionId: string;
}

interface State {
  isLoading: boolean;
  paymentMethod: PaymentMethod | null;
  alreadyAcceptedTermsAndConditions: boolean;
}

const ConfirmationStep = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { member },
  } = useGlobalState();
  const mounted = useMounted();
  const [state, setState] = useState<State>({
    isLoading: true,
    paymentMethod: null,
    alreadyAcceptedTermsAndConditions: false,
  });

  const getData = async () => {
    try {
      const { paymentMethods } = await api.getPaymentMethods(member.id);
      if (!paymentMethods.length) {
        const {
          isAccepted,
        } = await api.checkCardholderReimbursementTermsAndConditions(member.id);
        setState((prevState) => ({
          ...prevState,
          alreadyAcceptedTermsAndConditions: isAccepted,
        }));
      }
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        paymentMethod: paymentMethods[0] as PaymentMethod,
      }));
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (state.isLoading) {
    return (
      <Box display="flex" justifyContent="center" mb={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (state.paymentMethod) {
    return <WithPaymentMethod {...props} paymentMethod={state.paymentMethod} />;
  }

  return (
    <NoPaymentMethod
      {...props}
      alreadyAcceptedTermsAndConditions={
        state.alreadyAcceptedTermsAndConditions
      }
    />
  );
};

export default ConfirmationStep;
