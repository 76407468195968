import {
  ArrowsHorizontalIcon,
  Avatar,
  AvatarProps,
  BankIcon,
  BankPaymentFeeIcon,
  BankReversalIcon,
  BankTopUpIcon,
  BankWithdrawalIcon,
  MoneyIcon,
  SvgIconProps,
} from 'elements';
import {
  AccountEntryBillPaymentType,
  AccountEntryType,
} from 'services/constants';

interface AccountEntryIconProps extends SvgIconProps {
  type: AccountEntryType;
  billPaymentType: AccountEntryBillPaymentType | undefined;
}

const AccountEntryIcon = ({
  type,
  billPaymentType,
  sx = [],
  ...props
}: AccountEntryIconProps) => {
  const getIcon = () => {
    if (type === AccountEntryType.other) return MoneyIcon;
    if (billPaymentType === AccountEntryBillPaymentType.bill) return BankIcon;
    if (billPaymentType === AccountEntryBillPaymentType.prefunding)
      return BankTopUpIcon;
    if (billPaymentType === AccountEntryBillPaymentType.billPaymentReversal)
      return BankReversalIcon;
    if (billPaymentType === AccountEntryBillPaymentType.directDebitReversalFee)
      return BankPaymentFeeIcon;
    if (billPaymentType === AccountEntryBillPaymentType.payout)
      return BankWithdrawalIcon;
    if (billPaymentType === AccountEntryBillPaymentType.internalTransfer)
      return ArrowsHorizontalIcon;
    return null;
  };

  const Icon = getIcon();
  if (!Icon) return null;

  return (
    <Icon
      {...props}
      sx={[
        {
          color:
            type !== AccountEntryType.other &&
            (billPaymentType ===
              AccountEntryBillPaymentType.billPaymentReversal ||
              billPaymentType ===
                AccountEntryBillPaymentType.directDebitReversalFee)
              ? (theme) => theme.palette.error.main
              : (theme) => theme.palette.primary.contrastText,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

interface Props extends AvatarProps {
  size?: 'medium' | 'large';
  type: AccountEntryType;
  billPaymentType: AccountEntryBillPaymentType | undefined;
}

const AccountEntryAvatar = ({
  size = 'medium',
  type,
  billPaymentType,
  sx = [],
  ...props
}: Props) => {
  return (
    <Avatar
      variant="rounded"
      sx={[
        {
          background: (theme) => theme.palette.primary.main,
          ...(size === 'large'
            ? {
                height: 80,
                width: 80,
                borderRadius: 2,
                color: 'text.primary',
                border: (theme) => `1px solid ${theme.palette.primary.main}`,
              }
            : {
                height: 24,
                width: 24,
                color: 'action.active',
              }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <AccountEntryIcon
        type={type}
        billPaymentType={billPaymentType}
        sx={{
          fontSize: size === 'large' ? 48 : 20,
        }}
      />
    </Avatar>
  );
};

export default AccountEntryAvatar;
