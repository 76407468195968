import { ComponentType, FC, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { createPartnerTheme } from 'components/App/style/themeMui5';
import { useGlobalState } from 'context/GlobalState';
import {
  PartnerIds,
  PartnerMemberMappedDetails,
  PartnerWhitelabelLevel,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';

const withPartnerLogic = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props: P): JSX.Element | null => {
  const api = useImperativeApi();
  const {
    dispatch,
    state: { member, themeId },
  } = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);
  const theme = useMemo(() => createPartnerTheme(themeId), [themeId]);

  useEffect(() => {
    (async () => {
      try {
        const partnerConfig = await api.getPartnerConfig(
          (member as PartnerMemberMappedDetails).partnerIds[0]
        );

        dispatch({
          type: 'SET_THEME_ID',
          payload:
            partnerConfig &&
            [
              PartnerWhitelabelLevel.FULLY_WHITELABEL,
              PartnerWhitelabelLevel.COMPLIANCE_RISK_WHITELABEL,
              PartnerWhitelabelLevel.EMBEDDED_WHITELABEL,
              PartnerWhitelabelLevel.LOGO_ONLY,
            ].includes(partnerConfig.whitelabelLevel)
              ? partnerConfig.partnerId
              : PartnerIds.pliant,
        });

        dispatch({
          type: 'SET_PARTNER_DATA',
          payload: { partnerConfig },
        });

        setIsLoading(false);
      } catch (error) {
        dispatch({
          type: 'SET_THEME_ID',
          payload: PartnerIds.pliant,
        });
        logError(error);
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Component {...props} isThemeLoading={isLoading} />
    </ThemeProvider>
  );
};

export default withPartnerLogic;
