import { useFormik } from 'formik';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormHelperText,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { Card, DEFAULT_TIMEZONE } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const getFormikInitialValues = (card: Card) => {
  if (card.cardValidity) {
    return {
      validFrom: moment.utc(card.cardValidity.validFrom).format('YYYY-MM-DD'),
      validTo: moment.utc(card.cardValidity.validTo).format('YYYY-MM-DD'),
    };
  }

  return {
    validFrom: '',
    validTo: '',
  };
};

interface FormValues {
  validFrom: string;
  validTo: string;
}

interface Props extends DialogProps {
  card: Card;
  onSuccess: (card: Card) => void;
  onClose: () => void;
}

const EditCardValidityPeriodDialog = ({ card, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      ...getFormikInitialValues(card),
    },
    validate: (values) => {
      if (!card.issuingDate || !card.expiryDate) return;

      const minDate = moment.utc(card.issuingDate);
      const maxDate = moment.utc(card.expiryDate);
      const validFrom = moment.utc(values.validFrom, 'YYYY-MM-DD', true);
      const validTo = moment.utc(values.validTo, 'YYYY-MM-DD', true);

      if (
        !validFrom.isValid() ||
        !validTo.isValid() ||
        !validFrom.isBetween(minDate, maxDate, 'day', '[]') ||
        !validTo.isBetween(minDate, maxDate, 'day', '[]') ||
        validFrom.isAfter(validTo)
      ) {
        return {
          validFrom: t('errors.cardValidityPeriod', {
            minDate: minDate.format('D MMM YYYY'),
            maxDate: maxDate.format('D MMM YYYY'),
          }),
        };
      }
    },
    onSubmit: async ({ validFrom, validTo }, { setSubmitting }) => {
      try {
        const data = await api.updateCardValidityPeriod(card.cardId, {
          validFrom,
          validTo,
          validTimezone: DEFAULT_TIMEZONE,
        });
        if (!mounted.current) return;
        onSuccess(data);
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('editCardValidityPeriodDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="edit-card-validity-period-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={12}>
              <TextField
                type="date"
                label={t('editCardValidityPeriodDialog.validFrom')}
                error={!!formik.errors.validFrom}
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('validFrom')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="date"
                label={t('editCardValidityPeriodDialog.validTo')}
                error={!!formik.errors.validFrom}
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('validTo')}
              />
            </Grid>
          </Grid>
          {formik.errors.validFrom && (
            <FormHelperText error>{formik.errors.validFrom}</FormHelperText>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={formik.isSubmitting}
          form="edit-card-validity-period-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(EditCardValidityPeriodDialog);
