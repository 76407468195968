import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import { OnboardingStatusBadge } from 'domains/onboarding/components';
import {
  getGroupIconByType,
  getNavigationPath,
} from 'domains/onboarding/utils';
import {
  Avatar,
  CaretRightIcon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
} from 'elements';
import { OnboardingGroup } from 'services/constants';

interface Props extends ListItemProps {
  group: OnboardingGroup;
  sectionKey: string;
}

const GroupListItem = ({ group, sectionKey, ...props }: Props) => {
  const {
    state: { organization },
  } = useGlobalState();

  const Icon = getGroupIconByType();

  return (
    <ListItem
      sx={{
        py: 2,
        pl: 2,
        '&+&': { mt: 3 },
        borderBottom: '1px solid',
        borderColor: (theme) => theme.palette.divider,
      }}
      {...props}
      secondaryAction={
        <IconButton
          edge="end"
          component={Link}
          to={{
            pathname: getNavigationPath(organization!.id, sectionKey, {
              groupId: group.id,
            }),
          }}
        >
          <CaretRightIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          sx={{ bgcolor: (theme) => theme.variables.warmGrey[200] }}
        >
          <Icon color="primary" />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        sx={{ ml: 2 }}
        primary={group.name}
        // TODO: add "secondary" text (not returned from BE)
        // secondary={secondaryText}
      />

      <OnboardingStatusBadge status={group.status} sx={{ mr: 2 }} />
    </ListItem>
  );
};

export default GroupListItem;
