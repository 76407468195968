import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingStatusBadge,
  TaskTitle,
} from 'domains/onboarding/components';
import {
  getNavigationPath,
  getTaskIconByType,
  getTaskPreselectedText,
} from 'domains/onboarding/utils';
import {
  Avatar,
  CaretRightIcon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
} from 'elements';
import { OnboardingTask } from 'services/constants';

interface Props extends ListItemProps {
  task: OnboardingTask;
  // if no groupId, group path structure is ignored
  groupId?: string;
  sectionKey: string;
}

const TaskListItem = ({ task, groupId = '', sectionKey, ...props }: Props) => {
  const { t } = useTranslation();
  const {
    state: { organization },
  } = useGlobalState();

  const Icon = getTaskIconByType(task);
  const secondaryText = getTaskPreselectedText(task, t);

  return (
    <ListItem
      sx={{
        py: 2,
        pl: 2,
        '&+&': { mt: 3 },
        borderBottom: '1px solid',
        borderColor: (theme) => theme.palette.divider,
      }}
      {...props}
      secondaryAction={
        <IconButton
          edge="end"
          component={Link}
          to={{
            pathname: getNavigationPath(organization!.id, sectionKey, {
              taskId: task.id,
              groupId,
            }),
          }}
        >
          <CaretRightIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar
          variant="rounded"
          sx={{ bgcolor: (theme) => theme.variables.warmGrey[200] }}
        >
          <Icon color="primary" />
        </Avatar>
      </ListItemAvatar>

      <ListItemText
        sx={{ ml: 2 }}
        primary={<TaskTitle task={task} />}
        secondary={secondaryText}
      />

      <OnboardingStatusBadge status={task.status} sx={{ mr: 2 }} />
    </ListItem>
  );
};

export default TaskListItem;
