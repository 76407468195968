import React, { useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import flowRight from 'lodash/flowRight';
import withLoader from 'components/App/withLoader';
import { useGlobalState } from 'context/GlobalState';
import { withFeatureFlagsProvider } from 'services/featureflags';
import withInitialLogic from './withInitialLogic';
import withTheme from './withTheme';

const PortalUserData = ({ children }: { children: React.ReactNode }) => {
  const {
    dispatch,
    state: { isLoading, error },
  } = useGlobalState();

  useEffect(() => {
    dispatch({ type: 'SET_IS_LOADING', payload: false });
  }, []);

  if (isLoading) return null;
  if (error) return null;

  return children;
};

export default flowRight([
  withLoader,
  withAuthenticationRequired,
  withInitialLogic,
  withFeatureFlagsProvider,
  // TODO: check if we need it
  // withJwtPayload,
  withTheme,
])(PortalUserData);
