import { useEffect, useState } from 'react';
import { xor } from 'lodash';
import { Moment } from 'moment';
import { useGlobalState } from 'context/GlobalState';
import { CardAccountFilter } from 'domains/card/components';
import {
  CategoryFilter,
  FlagReasonFilter,
  ProjectFilter,
  ReceiptFilter,
  ReviewFilter,
  StatusFilter,
  TeamFilter,
  TransactionSimpleTypeFilter,
} from 'domains/transaction/components';
import { QueryParams as ChTeamsTransactionsPageQueryParams } from 'domains/transaction/pages/CardholderTeamTransactionsPage';
import {
  QueryParams as TransactionsPageQueryParams,
  visibleTransactionTypes,
} from 'domains/transaction/pages/TransactionsPage';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { DateFilter, FilterDrawer, FilterDrawerProps } from 'layout';
import {
  MerchantCategory,
  NONE_VALUE,
  PrivateExpenseStatus,
  privateExpenseStatuses,
  Project,
  ReviewFlagReason,
  Team,
  TransactionReceiptStatus,
  TransactionReviewStatus,
  TransactionSimpleType,
  TransactionStatus,
  transactionStatuses,
} from 'services/constants';

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  teams: Team[];
  projects: Project[];
  params: TransactionsPageQueryParams | ChTeamsTransactionsPageQueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
}

interface State {
  status: TransactionStatus[];
  type: TransactionSimpleType[];
  category: MerchantCategory[];
  teamId: string[];
  projectIds: string[];
  receipt: TransactionReceiptStatus | '';
  fromDate: Moment | null;
  toDate: Moment | null;
  reviewStatus: TransactionReviewStatus | typeof NONE_VALUE | '';
  flagReason: ReviewFlagReason | '';
  privateExpenseStatus: PrivateExpenseStatus[];
  cardAccountId: string;
}

const FiltersDrawer = ({
  teams,
  projects,
  params,
  setParam,
  ...props
}: Props) => {
  const {
    state: { cardAccounts, featureModules },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    status: [],
    type: [],
    category: [],
    teamId: [],
    projectIds: [],
    receipt: '',
    fromDate: null,
    toDate: null,
    reviewStatus: '',
    flagReason: '',
    privateExpenseStatus: [],
    cardAccountId: '',
  });

  const areFiltersSelected = !!(
    state.status.length ||
    state.type.length ||
    state.category.length ||
    state.teamId.length ||
    state.receipt ||
    state.projectIds.length ||
    state.fromDate ||
    state.toDate ||
    state.reviewStatus ||
    state.flagReason ||
    state.privateExpenseStatus.length ||
    state.cardAccountId
  );

  useEffect(() => {
    if (props.open)
      setState({
        status: params.status,
        type: params.type,
        category: params.category,
        teamId: params.teamId,
        projectIds: params.projectIds,
        receipt: params.receipt,
        fromDate: params.fromDate,
        toDate: params.toDate,
        reviewStatus: params.reviewStatus,
        flagReason: params.flagReason,
        privateExpenseStatus: params.privateExpenseStatus,
        cardAccountId: params.cardAccountId,
      });
  }, [props.open]);

  const onFiltersApply = () => {
    const filtersToApply = {
      ...state,
      fromDate: state.fromDate?.startOf('day')?.format(),
      toDate: state.toDate?.endOf('day')?.format(),
    };

    setParam(
      Object.keys(filtersToApply).map((key) => [
        key,
        filtersToApply[key as keyof State],
      ])
    );
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.keys(state).map((key) => [key, '']));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      <StatusFilter
        value={state.status}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            status: xor(prevState.status, [value]),
          }))
        }
        options={transactionStatuses}
      />

      <TransactionSimpleTypeFilter
        value={state.type}
        onChange={(value) =>
          setState((prevState) => ({
            ...prevState,
            type: xor(prevState.type, [value]),
          }))
        }
        options={visibleTransactionTypes}
      />

      <DateFilter
        fromDate={params.fromDate}
        toDate={params.toDate}
        onChange={(fromDate, toDate) =>
          setState((prevState) => ({
            ...prevState,
            fromDate: fromDate?.startOf('day') || null,
            toDate: toDate?.endOf('day') || null,
          }))
        }
      />

      {cardAccounts.length > 1 && (
        <CardAccountFilter
          value={state.cardAccountId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, cardAccountId: value }))
          }
        />
      )}

      {featureModules.RECEIPT_MANAGEMENT && (
        <ReceiptFilter
          value={state.receipt}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, receipt: value }))
          }
        />
      )}

      <CategoryFilter
        value={state.category}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, category: value }))
        }
      />

      {!!teams.length && (
        <TeamFilter
          teams={teams}
          value={state.teamId}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, teamId: value }))
          }
        />
      )}

      {!!projects.length && (
        <ProjectFilter
          projects={projects}
          value={state.projectIds}
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, projectIds: value }))
          }
        />
      )}

      <ReviewFilter
        value={state.reviewStatus}
        onChange={(value) =>
          setState((prevState) => ({ ...prevState, reviewStatus: value }))
        }
      />

      <FlagReasonFilter
        value={state.flagReason}
        onChange={(flagReason) =>
          setState((prevState) => ({
            ...prevState,
            flagReason,
            privateExpenseStatus:
              flagReason === ReviewFlagReason.privateExpense
                ? [...privateExpenseStatuses]
                : [],
          }))
        }
        privateExpenseStatusesValue={state.privateExpenseStatus}
        onPrivateExpenseStatusChange={(values) =>
          setState((prevState) => ({
            ...prevState,
            privateExpenseStatus: xor(prevState.privateExpenseStatus, values),
          }))
        }
      />
    </FilterDrawer>
  );
};

export default FiltersDrawer;
