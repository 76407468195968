import { Money } from './types';

export enum SettlementTransactionType {
  interest = 'INTEREST',
  remitCredit = 'REMIT_CREDIT',
  remitDebit = 'REMIT_DEBIT',
  sctCredit = 'SCT_CREDIT',
  sctDebit = 'SCT_DEBIT',
  sddAuth = 'SDD_AUTH',
  sddBulk = 'SDD_BULK',
  sddReturn = 'SDD_RETURN',
  sddSingle = 'SDD_SINGLE',
  unknown = 'UNKNOWN',
}

export enum SettlementTransactionPaymentType {
  payout = 'PAYOUT',
  billPmt = 'BILL_PMT',
  billPmtReversal = 'BILL_PMT_REVERSAL',
  orgSetlPmt = 'ORG_SETL_PMT',
  programSetlPmt = 'PROGRAM_SETL_PMT',
  programSetlFeePmt = 'PROGRAM_SETL_FEE_PMT',
  rcnPmt = 'RCN_PMT',
  cashbackRedemption = 'CASHBACK_REDEMPTION',
  prefundingPmt = 'PREFUNDING_PMT',
  other = 'OTHER',
}

export interface SettlementTransaction {
  transactionId: string;
  organizationId: string | null;
  postingDate: string;
  valueDate: string;
  purpose: string;
  endToEndId: string;
  errorMessage: string | null;
  transactionType: SettlementTransactionType;
  counterpartyName: string | null;
  counterpartyAccountNumber: string | null;
  counterpartyBic: string | null;
  amount: Money;
  balance: Money;
  paymentType: SettlementTransactionPaymentType;
  paymentId: string;
  originatorId: string | null;
  statementId: string;
  publicId: string;
}

export interface SettlementTransactionPageableList {
  hasNextPage: boolean;
  transactions: SettlementTransaction[];
  totalCount: number;
}
