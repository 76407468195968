import { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useCurrencyDisplayMode } from 'domains/organization/hooks';
import { Dialog, DialogProps, DialogTitle, withDialogWrapper } from 'elements';
import useSnackbar from 'hooks/useSnackbar';
import {
  InitiateReimbursementResponse,
  Money,
  STRIPE_PUBLIC_KEY,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { formatMoney, getGenericErrorMsg } from 'services/utils';
import ConfirmationStep from './ConfirmationStep';
import ImmediatePaymentStep from './ImmediatePaymentStep';
import PaymentDetailsStep from './PaymentDetailsStep';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export enum Steps {
  CONFIRMATION = 'confirmation',
  PAYMENT_DETAILS = 'payment_details',
  IMMEDIATE_PAYMENT = 'immediate_payment',
}

export const STRIPE_SUCCESS_STATUSES = ['succeeded', 'requires_capture'];

interface Props extends DialogProps {
  onClose: () => void;
  onReimbursementSuccess: () => void;
  transactionId: string;
  transactionAmount: Money;
}

interface State {
  step: Steps;
  clientSecret: string | null;
  reimbursementId: string | null;
  paymentMethodId: string | null;
}

const InitiatingReimbursementDialog = (props: Props) => {
  const { t, i18n } = useTranslation();
  const currencyDisplay = useCurrencyDisplayMode();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<State>({
    step: Steps.CONFIRMATION,
    clientSecret: null,
    reimbursementId: null,
    paymentMethodId: null,
  });

  const goToNextStep = async (
    step: Steps,
    response: InitiateReimbursementResponse
  ) => {
    setState((prevState) => ({
      ...prevState,
      step,
      clientSecret: response.clientSecret,
      reimbursementId: response.id,
      paymentMethodId: response.paymentMethodId,
    }));
  };

  const cancelPaymentIntent = async () => {
    if (state.reimbursementId && state.step === Steps.PAYMENT_DETAILS) {
      try {
        await api.cancelPaymentIntent(state.reimbursementId);
        props.onClose();
      } catch (error) {
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    } else {
      props.onClose();
    }
  };

  const onSuccess = () => {
    props.onClose();
    props.onReimbursementSuccess();
  };

  return (
    <Dialog {...props} onClose={cancelPaymentIntent}>
      <DialogTitle>
        {t('transactionDetails.reimbursementDialog.title', {
          amount: formatMoney(props.transactionAmount, i18n.language, {
            currencyDisplay,
            fractionalPart: true,
            absoluteValue: true,
          }),
        })}
      </DialogTitle>

      {state.step === Steps.CONFIRMATION && (
        <ConfirmationStep
          onClose={props.onClose}
          goToNextStep={goToNextStep}
          transactionId={props.transactionId}
        />
      )}

      {[Steps.PAYMENT_DETAILS, Steps.IMMEDIATE_PAYMENT].includes(
        state.step
      ) && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: state.clientSecret!,
            appearance: {
              variables: {
                fontFamily: 'MaisonNeue, sans-serif',
              },
            },
          }}
        >
          {state.step === Steps.PAYMENT_DETAILS && (
            <PaymentDetailsStep
              onCancel={cancelPaymentIntent}
              onSuccess={onSuccess}
            />
          )}
          {state.step === Steps.IMMEDIATE_PAYMENT && (
            <ImmediatePaymentStep
              clientSecret={state.clientSecret!}
              paymentMethodId={state.paymentMethodId!}
              onReimbursementSuccess={props.onReimbursementSuccess}
            />
          )}
        </Elements>
      )}
    </Dialog>
  );
};

export default withDialogWrapper(InitiatingReimbursementDialog);
