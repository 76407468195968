import { useEffect, useMemo, useRef, useState } from 'react';
import { sortBy } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import {
  useActiveTeams,
  useGlobalState,
  useManagedTeams,
} from 'context/GlobalState';
import { TransactionDetailsPage } from 'domains/transaction/pages/index';
import {
  DataGrid,
  FileXIcon,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useCurrentApp from 'hooks/useCurrentApp';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  DEFAULT_PAGE_LIMIT,
  FeatureModuleValueByKeyMap,
  GetTransactionsParams,
  merchantCategories,
  privateExpenseStatuses,
  reviewFlagReasons,
  Team,
  Transaction,
  transactionReceiptStatuses,
  TransactionReviewStatus,
  TransactionStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getReceiptFilterApiParams,
  getValidQueryParamValue,
  getValidQueryParamValues,
} from 'services/utils';
import Filters from './Filters';
import FilterChips from './Filters/FilterChips';
import useColumns from './useColumns';

export const visibleTransactionStatuses = [
  TransactionStatus.pending,
  TransactionStatus.confirmed,
];

const getQueryParams = (
  qs: string,
  managedTeamIds: string[],
  sortedProjectIds: string[],
  cardAccountIds: string[],
  featureModules: FeatureModuleValueByKeyMap
) => {
  const {
    q,
    teamId,
    status,
    category,
    projectIds,
    fromDate,
    toDate,
    receipt,
    flagReason,
    privateExpenseStatus,
    cardAccountId,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  return {
    q: q?.trim() || '',
    teamId: getValidQueryParamValues(teamId, managedTeamIds),
    status: getValidQueryParamValues(status, visibleTransactionStatuses),
    category: getValidQueryParamValues(category, merchantCategories),
    projectIds: getValidQueryParamValues(projectIds, sortedProjectIds),
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
    receipt: getValidQueryParamValue(receipt, transactionReceiptStatuses),
    flagReason: featureModules.PRIVATE_EXPENSE
      ? getValidQueryParamValue(flagReason, reviewFlagReasons)
      : '',
    privateExpenseStatus: featureModules.PRIVATE_EXPENSE
      ? getValidQueryParamValues(privateExpenseStatus, privateExpenseStatuses)
      : [],
    cardAccountId: getValidQueryParamValue(cardAccountId, cardAccountIds),
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({
  teamId,
  status,
  category,
  projectIds,
  fromDate,
  receipt,
  flagReason,
  privateExpenseStatus,
  cardAccountId,
}: QueryParams) =>
  +!!teamId.length +
  +!!status.length +
  +!!category.length +
  +!!projectIds.length +
  +!!fromDate +
  +!!receipt.length +
  +!!flagReason +
  +!!privateExpenseStatus.length +
  +!!cardAccountId;

interface State {
  isLoading: boolean;
  transactions: Transaction[];
  hasNextPage: boolean;
  totalCount: number;
}

const TransactionsFlaggedPage = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { isAdminApp } = useCurrentApp();
  const showPageError = useShowPageError();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const mounted = useMounted();
  const setQueryParam = useSetQueryParam();
  const {
    state: {
      organization,
      accountingSettings,
      featureModules,
      projects,
      cardAccounts,
    },
  } = useGlobalState();
  const managedTeams = useManagedTeams();
  const activeTeams = useActiveTeams();
  const visibleTeams = useMemo<Team[]>(() => {
    if (isAdminApp) return activeTeams;
    return canUser('team:manage') ? managedTeams : [];
  }, [isAdminApp, activeTeams, managedTeams]);
  const visibleTeamsIds = useMemo(() => visibleTeams.map((item) => item.id), [
    visibleTeams,
  ]);
  const sortedProjects = useMemo(
    () =>
      projects?.length &&
      // projects hidden from partner
      featureModules.ACCOUNTING_FEATURES &&
      accountingSettings!.projectEnabled
        ? sortBy(projects, (v) => v.name.toLowerCase())
        : [],
    [projects]
  );
  const sortedProjectIds = useMemo(
    () => sortedProjects.map((project) => project.id),
    [sortedProjects]
  );
  const cardAccountIds = useMemo(
    () => cardAccounts.map((account) => account.id),
    [cardAccounts]
  );

  const paramsRef = useRef(
    getQueryParams(
      location.search,
      visibleTeamsIds,
      sortedProjectIds,
      cardAccountIds,
      featureModules
    )
  );
  const pageRef = useRef(0);
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:transactionId',
    true
  );
  const columns = useColumns();
  const dataGridRef = useGridApiRef();
  const [state, setState] = useState<State>({
    isLoading: true,
    transactions: [],
    hasNextPage: false,
    totalCount: 0,
  });

  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState = !state.transactions.length && !areFiltersApplied;

  const getRequestParams = (): GetTransactionsParams => {
    const {
      q,
      status,
      category,
      projectIds,
      fromDate,
      toDate,
      receipt,
      flagReason,
      privateExpenseStatus,
      cardAccountId,
      teamId,
    } = paramsRef.current;

    return {
      q: q.length ? q : undefined,
      status: status.length ? status.join() : undefined,
      category: category.length ? category.join() : undefined,
      projectIds: projectIds.length ? projectIds.join() : undefined,
      fromDate: fromDate?.format(),
      toDate: toDate?.format(),
      organizationId: organization!.id,
      teamId: teamId.length ? teamId.join() : undefined,
      ...getReceiptFilterApiParams(receipt),
      flagReason: flagReason || undefined,
      privateExpenseStatus: privateExpenseStatus.length
        ? privateExpenseStatus.join()
        : undefined,
      cardAccountId: cardAccountId || undefined,
      reviewStatus: TransactionReviewStatus.flagged,
    };
  };

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }

      const params = getRequestParams();
      const {
        transactions,
        hasNextPage,
        totalCount,
      } = await api.getTransactions({ ...params, page, limit });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        transactions: isLoadMore
          ? [...prevState.transactions, ...transactions]
          : transactions,
        hasNextPage,
        totalCount,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const onTransactionDetailsUpdated = (updatedTransaction: Transaction) => {
    setState((prevState) => ({
      ...prevState,
      transactions: prevState.transactions.map((transaction) =>
        transaction.transactionId === updatedTransaction.transactionId
          ? updatedTransaction
          : transaction
      ),
    }));
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(
      location.search,
      visibleTeamsIds,
      sortedProjectIds,
      cardAccountIds,
      featureModules
    );
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  return (
    <>
      <PageHeader>
        <PageTitle
          title={t('transactionsFlaggedPage.titleNested')}
          suptitle={t('transactionsFlaggedPage.title')}
        />

        <Filters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          disabled={isEmptyState}
          teams={visibleTeams}
          projects={sortedProjects}
          transactionsCount={state.totalCount}
        />
        <FilterChips
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          teams={visibleTeams}
          projects={sortedProjects}
        />
      </PageHeader>
      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<Transaction>
          apiRef={dataGridRef}
          rowHeight={72}
          rowCount={state.totalCount}
          getRowId={(row) => row.transactionId}
          disableMultipleRowSelection
          keepNonExistentRowsSelected
          loading={state.isLoading}
          rows={state.transactions}
          columns={columns}
          columnVisibilityModel={{
            merchantName: true,
            receiptNeeded:
              featureModules.RECEIPT_MANAGEMENT &&
              !!organization?.missingReceiptNotificationEnabled,
            createdAt: true,
            cardType: !isDetailsPageOpen,
            memberFirstName: !isDetailsPageOpen,
            cardAccountName: !isDetailsPageOpen && cardAccounts.length > 1,
            transactionAmount: true,
            review: !isDetailsPageOpen,
            _integration_: featureModules.ACCOUNTING_FEATURES,
            drawerPlaceholder: isDetailsPageOpen,
          }}
          rowSelectionModel={
            detailsParams?.transactionId ? [detailsParams.transactionId] : []
          }
          onRowClick={({ id, row }) => {
            if (dataGridRef.current?.getSelectedRows().has(id))
              history.push(`${url}${location.search}`);
            else history.push(`${url}/${row.transactionId}${location.search}`);
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.transactions.length && areFiltersApplied)
                return <NothingFound />;

              return (
                <NoData
                  isNewDesign
                  Icon={FileXIcon}
                  label={t('transactionsFlaggedPage.noTransactions')}
                  $top={90}
                />
              );
            },
            loadingOverlay: () => null,
          }}
        />

        <Route
          path={`${path}/:transactionId`}
          children={({ match }) => (
            <TransactionDetailsPage
              open={!!match}
              isAdminApp={isAdminApp}
              onUpdate={onTransactionDetailsUpdated}
              onReviewUpdated={(reviewStatus) => {
                if (!match) return;
                pageRef.current = 0;
                getData(pageRef.current);
                if (reviewStatus === TransactionReviewStatus.flagged) return;
                history.replace({
                  pathname: match.url.substring(0, match.url.lastIndexOf('/')),
                  search: location.search,
                });
              }}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export default withPageConfig(TransactionsFlaggedPage, {
  permission: 'transaction-review:view',
  featureModule: (featureModules) =>
    featureModules.MANAGER_TX_REVIEWS || featureModules.PRIVATE_EXPENSE,
});
