import React, { useContext, useReducer } from 'react';
import { sortBy } from 'lodash';
import {
  AccountingSettings,
  CardAccount,
  CardAccountCurrency,
  CardConfigSetting,
  CardDesign,
  CustomField,
  DocumentsByGenericTypeMap,
  FeatureModuleByKeyMap,
  FeatureModuleValueByKeyMap,
  GeneralInfo,
  MemberDetails,
  Membership,
  Organization,
  OrganizationIntegrations,
  OrganizationsStaticData,
  Partner,
  PartnerConfig,
  PartnerIds,
  PartnerMember,
  PartnerMemberMappedDetails,
  PartnerOrgAuthDetails,
  PlatformLegalForm,
  Project,
  ReceiptInboxLayout,
  ReceiptInboxState,
  ReceiptsSettings,
  Subcategory,
  SubscriptionPlan,
  SubscriptionPlanType,
  SUPPORT_EMAIL,
  SUPPORT_NUMBER,
  Team,
  VatRate,
} from 'services/constants';
import { JwtPayload } from 'services/rbac';
import { Attributes } from 'services/rbac/attributes';
import getPermissions, { Permissions } from 'services/rbac/permissions';
import { getMoneyObject } from 'services/utils';

type Action =
  | { type: 'SET_IS_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: any }
  | { type: 'TOGGLE_SIDEBAR'; payload: boolean }
  | { type: 'SET_THEME_ID'; payload: string }
  | { type: 'SET_JWT_PAYLOAD'; payload: { jwtPayload: JwtPayload } }
  | {
      type: 'SET_FEATURE_MODULES';
      payload: {
        featureModules: Partial<FeatureModuleValueByKeyMap>;
        featureModulesWithData: Partial<FeatureModuleByKeyMap>;
      };
    }
  | { type: 'RESET_FEATURE_MODULES' }
  | {
      type: 'SET_PARTNER_DATA';
      payload: {
        partners?: Partner[] | null;
        partnerOrgAuthDetails?: PartnerOrgAuthDetails | null;
        partnerConfig?: PartnerConfig;
      };
    }
  | {
      type: 'SET_CUSTOM_FIELDS';
      payload: {
        transactionCustomFields: CustomField[];
      };
    }
  | {
      type: 'SET_USER_DATA';
      payload: {
        platformLegalForms?: PlatformLegalForm[];
        member?: MemberDetails | PartnerMember;
        memberships?: Membership[];
        teams?: Team[];
        teamCount?: number;
        accountingSettings?: AccountingSettings;
        receiptsSettings?: ReceiptsSettings | null;
        subcategories?: Subcategory[] | null;
        vatRates?: VatRate[] | null;
        projects?: Project[] | null;
        transactionCustomFields?: CustomField[];
        organization?: Organization;
        organizationIntegrations?: OrganizationIntegrations | null;
      };
    }
  | {
      type: 'SET_ORGANIZATION_DATA';
      payload: {
        organization?: null | Organization;
        teams?: Team[];
        teamCount?: number;
        accountingSettings?: AccountingSettings;
        subcategories?: Subcategory[] | null;
        vatRates?: VatRate[] | null;
        projects?: Project[] | null;
        transactionCustomFields?: CustomField[];
        partners?: Partner[];
        partnerOrgAuthDetails?: PartnerOrgAuthDetails;
        memberships?: Membership[];
        organizationIntegrations?: OrganizationIntegrations | null;
        defaultCardAccount?: CardAccount;
        cardAccounts?: CardAccount[];
      };
    }
  | {
      type: 'SET_APP_TYPE';
      payload: { isComplianceRiskWhiteLabelApp: boolean };
    }
  | {
      type: 'ADD_TEAM';
      payload: Team;
    }
  | {
      type: 'UPDATE_TEAM';
      payload: Team;
    }
  | {
      type: 'DELETE_TEAM';
      payload: Team;
    }
  | {
      type: 'ADD_PROJECTS';
      payload: Project[];
    }
  | {
      type: 'UPDATE_PROJECT';
      payload: Project;
    }
  | {
      type: 'DELETE_PROJECT';
      payload: Project;
    }
  | {
      type: 'ADD_SUBCATEGORIES';
      payload: Subcategory[];
    }
  | {
      type: 'UPDATE_SUBCATEGORY';
      payload: Subcategory;
    }
  | {
      type: 'DELETE_SUBCATEGORY';
      payload: Subcategory;
    }
  | {
      type: 'ADD_VAT_RATE';
      payload: VatRate;
    }
  | {
      type: 'UPDATE_VAT_RATE';
      payload: VatRate;
    }
  | {
      type: 'DELETE_VAT_RATE';
      payload: VatRate;
    }
  | {
      type: 'SET_GENERAL_INFO';
      payload: { generalInfo: GeneralInfo };
    }
  | {
      type: 'SET_CARD_CONFIG_SETTINGS';
      payload: { cardConfigSettings: CardConfigSetting[] };
    }
  | {
      type: 'SET_CARD_DESIGNS';
      payload: { cardDesigns: CardDesign[] };
    }
  | {
      type: 'SET_TRANSACTIONS_COUNT';
      payload: {
        transactionsNeedsReviewCount?: number;
        transactionsFlaggedCount?: number;
      };
    }
  | {
      type: 'SET_SUBSCRIPTION_PLAN_DATA';
      payload: { subscriptionPlan: SubscriptionPlan | null };
    }
  | {
      type: 'SET_DOCUMENTS';
      payload: { documents: DocumentsByGenericTypeMap | null };
    }
  | {
      type: 'SET_ORGANIZATIONS_STATIC_DATA';
      payload: {
        organizationsStaticData: OrganizationsStaticData;
      };
    }
  | {
      type: 'SET_CARD_ACCOUNT_CURRENCIES';
      payload: {
        cardAccountCurrencies: CardAccountCurrency[];
      };
    }
  | {
      type: 'SET_UNMATCHED_RECEIPTS_COUNT';
      payload: { unmatchedTaskCount: number; unmatchedTaskCountSelf: number };
    }
  | {
      type: 'SET_RECEIPT_INBOX_DATA';
      payload: Partial<ReceiptInboxState>;
    }
  | { type: 'RESET_RECEIPT_INBOX_DATA' };

export interface GlobalState {
  isLoading: boolean;
  error: any;
  isSidebarOpen: boolean;
  themeId: string;
  jwtPayload: JwtPayload;
  permissions: Permissions;
  featureModules: FeatureModuleValueByKeyMap;
  featureModulesWithData: FeatureModuleByKeyMap;
  member: MemberDetails | PartnerMemberMappedDetails;
  memberships: Membership[];
  // all organization teams for admins, managed teams for team managers, empty array for cardholders
  teams: Team[];
  // number of all organization teams for all roles
  teamCount: number;
  organization: null | Organization;
  organizationIntegrations: null | OrganizationIntegrations;
  defaultCardAccount: CardAccount | null;
  cardAccounts: CardAccount[];
  cardConfigSettings: CardConfigSetting[];
  cardDesigns: CardDesign[];
  subscriptionPlan: SubscriptionPlan | null;
  documents: DocumentsByGenericTypeMap | null;
  subcategories: Subcategory[] | null;
  vatRates: VatRate[] | null;
  projects: Project[] | null;
  partners: Partner[] | null;
  // if partnerOrgAuthDetails is undefined -> something went wrong with the request
  partnerOrgAuthDetails: PartnerOrgAuthDetails | null | undefined;
  partnerConfig: PartnerConfig | null;
  accountingSettings: AccountingSettings | null;
  receiptsSettings: ReceiptsSettings | null;
  generalInfo: GeneralInfo;
  platformLegalForms: PlatformLegalForm[];
  // One of the versions of the white-labeled-app when only only some routes are allowed.
  isComplianceRiskWhiteLabelApp: boolean;
  organizationsStaticData: OrganizationsStaticData | null;
  cardAccountCurrencies: CardAccountCurrency[];
  transactionsNeedsReviewCount: number;
  transactionsFlaggedCount: number;
  transactionCustomFields: CustomField[];
  unmatchedReceiptsCounts: {
    unmatchedTaskCount: number;
    unmatchedTaskCountSelf: number;
  };
  receiptInbox: ReceiptInboxState;
}

interface DispatchFunc {
  (action: Action): any;
}

interface GlobalStateContextType {
  state: GlobalState;
  dispatch: DispatchFunc;
}

interface GlobalStateProviderOptions {
  children: React.ReactNode;
  initialState?: GlobalState;
}

export const INITIAL_STATE = {
  isLoading: false,
  error: null,
  isSidebarOpen: true,
  themeId: PartnerIds.pliant,
  jwtPayload: {
    attr: {} as Attributes,
    roles: [],
    permissions: [],
  },
  permissions: {} as Permissions,
  featureModules: {} as FeatureModuleValueByKeyMap,
  featureModulesWithData: {} as FeatureModuleByKeyMap,
  member: {} as MemberDetails | PartnerMemberMappedDetails,
  memberships: [],
  teams: [],
  teamCount: 0,
  organization: null,
  organizationIntegrations: null,
  defaultCardAccount: null,
  cardAccounts: [],
  cardConfigSettings: [],
  cardDesigns: [],
  subscriptionPlan: null,
  documents: null,
  subcategories: null,
  vatRates: null,
  projects: null,
  partners: null,
  partnerOrgAuthDetails: undefined, // organization partner (source)
  partnerConfig: null,
  accountingSettings: null,
  receiptsSettings: null,
  generalInfo: {
    supportEmail: SUPPORT_EMAIL,
    supportPhone: SUPPORT_NUMBER,
    supportPhoneFormatted: SUPPORT_NUMBER,
  },
  platformLegalForms: [],
  isComplianceRiskWhiteLabelApp: false,
  organizationsStaticData: null,
  cardAccountCurrencies: [],
  transactionsNeedsReviewCount: 0,
  transactionsFlaggedCount: 0,
  transactionCustomFields: [],
  unmatchedReceiptsCounts: { unmatchedTaskCount: 0, unmatchedTaskCountSelf: 0 },
  receiptInbox: {
    layout: ReceiptInboxLayout.GRID,
    isDialogOpen: false,
    flow: null,
    selectedTransationHasReceipt: false,
    receipt: null,
    thumbnail: '',
  },
};

export const GlobalStateContext = React.createContext<GlobalStateContextType | null>(
  null
);

export const useGlobalState = () => useContext(GlobalStateContext)!;

export const GlobalStateProvider = ({
  children,
  initialState = INITIAL_STATE,
}: GlobalStateProviderOptions) => {
  const reducer = (state: GlobalState, action: Action): GlobalState => {
    switch (action.type) {
      case 'SET_IS_LOADING':
        return {
          ...state,
          isLoading: action.payload,
        };
      case 'SET_ERROR':
        return {
          ...state,
          error: action.payload,
          isLoading: false,
        };
      case 'TOGGLE_SIDEBAR':
        return {
          ...state,
          isSidebarOpen: action.payload,
        };
      case 'SET_THEME_ID':
        return { ...state, themeId: action.payload };
      case 'SET_JWT_PAYLOAD': {
        const { jwtPayload } = action.payload;
        const {
          member,
          organization,
          defaultCardAccount,
          subscriptionPlan,
          partnerConfig,
        } = state;

        return {
          ...state,
          jwtPayload,
          permissions: getPermissions(
            jwtPayload,
            member,
            organization,
            defaultCardAccount,
            subscriptionPlan,
            partnerConfig
          ),
        };
      }
      case 'SET_FEATURE_MODULES': {
        return {
          ...state,
          featureModules: {
            ...state.featureModules,
            ...action.payload.featureModules,
          },
          featureModulesWithData: {
            ...state.featureModulesWithData,
            ...action.payload.featureModulesWithData,
          },
        };
      }
      case 'RESET_FEATURE_MODULES': {
        return {
          ...state,
          featureModules: INITIAL_STATE.featureModules,
          featureModulesWithData: INITIAL_STATE.featureModulesWithData,
        };
      }
      case 'SET_PARTNER_DATA': {
        return {
          ...state,
          ...action.payload,
        };
      }
      case 'SET_CUSTOM_FIELDS': {
        return {
          ...state,
          ...action.payload,
        };
      }
      case 'SET_USER_DATA': {
        const {
          member = state.member,
          organization = state.organization,
        } = action.payload;
        const {
          jwtPayload,
          subscriptionPlan,
          defaultCardAccount,
          partnerConfig,
        } = state;

        // todo: improve after all necessary portal pages are implemented
        const memberToStore =
          'partnerCompanyId' in member
            ? {
                legalRepresentative: null,
                securityKeyPaired: false,
                securityKeyName: null,
                insured: false,
                phoneNumber: '',
                ...member,
              }
            : member;

        return {
          ...state,
          ...action.payload,
          member: memberToStore,
          isLoading: false,
          permissions: getPermissions(
            jwtPayload,
            memberToStore,
            organization,
            defaultCardAccount,
            subscriptionPlan,
            partnerConfig
          ),
        };
      }
      case 'SET_ORGANIZATION_DATA': {
        const {
          organization = state.organization,
          defaultCardAccount = state.defaultCardAccount,
        } = action.payload;
        const { member, jwtPayload, subscriptionPlan, partnerConfig } = state;

        return {
          ...state,
          ...action.payload,
          permissions: getPermissions(
            jwtPayload,
            member,
            organization,
            defaultCardAccount,
            subscriptionPlan,
            partnerConfig
          ),
        };
      }
      case 'SET_APP_TYPE': {
        const { isComplianceRiskWhiteLabelApp } = action.payload;
        return { ...state, isComplianceRiskWhiteLabelApp };
      }
      case 'ADD_TEAM': {
        const teams = sortBy<Team>([...state.teams, action.payload], (item) =>
          item.name.toLowerCase()
        );
        return {
          ...state,
          teams,
          teamCount: teams.length,
        };
      }
      case 'UPDATE_TEAM': {
        const teams = state.teams.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
        return {
          ...state,
          teams: sortBy<Team>(teams, (item) => item.name.toLowerCase()),
        };
      }
      case 'DELETE_TEAM': {
        const teams = state.teams.filter(
          (item) => item.id !== action.payload.id
        );
        return {
          ...state,
          teams,
          teamCount: teams.length,
        };
      }
      case 'ADD_PROJECTS': {
        const projects = sortBy<Project>(
          [...(state.projects || []), ...action.payload],
          (item) => item.name.toLowerCase()
        );
        return {
          ...state,
          projects,
        };
      }
      case 'UPDATE_PROJECT': {
        const projects =
          state.projects?.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ) || [];
        return {
          ...state,
          projects: sortBy<Project>(projects, (item) =>
            item.name.toLowerCase()
          ),
        };
      }
      case 'DELETE_PROJECT': {
        const projects =
          state.projects?.filter((item) => item.id !== action.payload.id) || [];
        return {
          ...state,
          projects,
        };
      }
      case 'ADD_SUBCATEGORIES': {
        const subcategories = sortBy<Subcategory>(
          [...(state.subcategories || []), ...action.payload],
          (item) => item.category.toLowerCase()
        );
        return {
          ...state,
          subcategories,
        };
      }
      case 'UPDATE_SUBCATEGORY': {
        const subcategories =
          state.subcategories?.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ) || [];
        return {
          ...state,
          subcategories: sortBy<Subcategory>(subcategories, (item) =>
            item.category.toLowerCase()
          ),
        };
      }
      case 'DELETE_SUBCATEGORY': {
        const subcategories =
          state.subcategories?.filter(
            (item) => item.id !== action.payload.id
          ) || [];
        return {
          ...state,
          subcategories,
        };
      }
      case 'ADD_VAT_RATE': {
        const vatRates = sortBy<VatRate>(
          [...(state.vatRates || []), action.payload],
          (item) => item.name.toLowerCase()
        );
        return {
          ...state,
          vatRates,
        };
      }
      case 'UPDATE_VAT_RATE': {
        const vatRates =
          state.vatRates?.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ) || [];
        return {
          ...state,
          vatRates: sortBy<VatRate>(vatRates, (item) =>
            item.name.toLowerCase()
          ),
        };
      }
      case 'DELETE_VAT_RATE': {
        const vatRates =
          state.vatRates?.filter((item) => item.id !== action.payload.id) || [];
        return {
          ...state,
          vatRates,
        };
      }
      case 'SET_GENERAL_INFO': {
        const { generalInfo } = action.payload;
        return { ...state, generalInfo };
      }
      case 'SET_CARD_CONFIG_SETTINGS': {
        const { cardConfigSettings } = action.payload;
        return { ...state, cardConfigSettings };
      }
      case 'SET_CARD_DESIGNS': {
        const { cardDesigns } = action.payload;
        return { ...state, cardDesigns };
      }
      case 'SET_TRANSACTIONS_COUNT': {
        const {
          transactionsNeedsReviewCount = state.transactionsNeedsReviewCount,
          transactionsFlaggedCount = state.transactionsFlaggedCount,
        } = action.payload;
        return {
          ...state,
          transactionsNeedsReviewCount,
          transactionsFlaggedCount,
        };
      }
      case 'SET_SUBSCRIPTION_PLAN_DATA': {
        const { subscriptionPlan } = action.payload;
        const {
          member,
          jwtPayload,
          organization,
          defaultCardAccount,
          partnerConfig,
        } = state;

        /** For the "Enterprise" plan, baseFee and additionalUserFee will be set to a fixed maximum value if null */
        if (subscriptionPlan?.type === SubscriptionPlanType.enterprise) {
          subscriptionPlan.baseFee =
            subscriptionPlan.baseFee || getMoneyObject(0);
          subscriptionPlan.additionalUserFee =
            subscriptionPlan.additionalUserFee || getMoneyObject(0);
        }
        return {
          ...state,
          subscriptionPlan,
          permissions: getPermissions(
            jwtPayload,
            member,
            organization,
            defaultCardAccount,
            subscriptionPlan,
            partnerConfig
          ),
        };
      }
      case 'SET_DOCUMENTS': {
        const { documents } = action.payload;
        return { ...state, documents };
      }
      case 'SET_ORGANIZATIONS_STATIC_DATA': {
        const { organizationsStaticData } = action.payload;
        return { ...state, organizationsStaticData };
      }
      case 'SET_CARD_ACCOUNT_CURRENCIES': {
        const { cardAccountCurrencies } = action.payload;
        return { ...state, cardAccountCurrencies };
      }
      case 'SET_UNMATCHED_RECEIPTS_COUNT': {
        const unmatchedReceiptsCounts = action.payload;
        return { ...state, unmatchedReceiptsCounts };
      }
      case 'SET_RECEIPT_INBOX_DATA': {
        return {
          ...state,
          receiptInbox: { ...state.receiptInbox, ...action.payload },
        };
      }
      case 'RESET_RECEIPT_INBOX_DATA': {
        return {
          ...state,
          receiptInbox: {
            ...INITIAL_STATE.receiptInbox,
            layout: state.receiptInbox.layout,
          },
        };
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export { default as useActiveTeams } from './useActiveTeams';

export { default as useManagedTeams } from './useManagedTeams';

export { default as useCardConfigSetting } from './useCardConfigSetting';

export { default as useCardDesign } from './useCardDesign';

export { default as useCardholderAppLink } from './useCardholderAppLink';
