import { useMemo } from 'react';
import { useGlobalState } from 'context/GlobalState';
import { AccountingSystem } from 'services/constants';
import { useFlags } from 'services/featureflags';

const useAccountingSystemExport = () => {
  const { datevExportEnabled } = useFlags();
  const {
    state: { accountingSettings },
  } = useGlobalState();

  const isAccSystemExportEnabled = () => {
    if (
      accountingSettings!.accountingSystem === AccountingSystem.customDatevBauen
    )
      return true;

    if (!accountingSettings!.useAccountingExport) return false;
    switch (accountingSettings!.accountingSystem) {
      case AccountingSystem.bmd:
      case AccountingSystem.netvisor:
      case AccountingSystem.procountor:
      case AccountingSystem.camt:
      case AccountingSystem.lexware:
      case AccountingSystem.customDemy:
      case AccountingSystem.customManningTravel:
      case AccountingSystem.customKts:
        return true;
      case AccountingSystem.datev:
        return datevExportEnabled;
      default:
        return false;
    }
  };

  return useMemo(() => isAccSystemExportEnabled(), [
    accountingSettings!.accountingSystem,
    accountingSettings!.useAccountingExport,
    datevExportEnabled,
  ]);
};

export default useAccountingSystemExport;
