import { useEffect, useState } from 'react';
import round from 'lodash/round';
import { Trans, useTranslation } from 'react-i18next';
import { NumberFormatValues } from 'react-number-format';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LoaderWithOverlay,
  MenuItem,
  MoneyField,
  Select,
  Slider,
  Typography,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  CashbackRedemptionBankAccount,
  CurrencyCashback,
  Money,
  OrganizationEmission,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg, getMoneyObject } from 'services/utils';
import SuccessStep from './SuccessStep';

const getAccountName = (account: CashbackRedemptionBankAccount) =>
  account.currency ? `${account.iban} (${account.currency})` : account.iban;

interface State {
  bankAccounts: CashbackRedemptionBankAccount[];
  bankAccountId: string;
  isLoading: boolean;
  offsetPayoutValue: number;
  orgEmission: OrganizationEmission | null;
  pricePerTonne: Money | null;
  step: 'redeem' | 'success';
}

interface Props extends DialogProps {
  currencyCashback: CurrencyCashback;
  onSuccess: () => void;
  onClose: () => void;
}

// It's fine to hardcode EUR for pliant earth as it's the only currency supported for now
const RedeemCashbackDialog = ({
  currencyCashback,
  onSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const api = useImperativeApi();
  const {
    state: { featureModules, organization },
  } = useGlobalState();
  const isPliantEarthActivated = featureModules.PLIANT_EARTH;

  const [state, setState] = useState<State>({
    bankAccounts: [],
    bankAccountId: '',
    isLoading: true,
    offsetPayoutValue: 0,
    orgEmission: null,
    pricePerTonne: null,
    step: 'redeem',
  });

  const getData = async () => {
    try {
      const [
        orgEmission,
        { pricePerTonne },
        { bankAccounts },
      ] = await Promise.all([
        isPliantEarthActivated
          ? api.getTotalOrgEmissionsByYear(organization!.id)
          : null,
        isPliantEarthActivated
          ? api.getCarbonPrice()
          : { pricePerTonne: getMoneyObject(0) },
        api.getCashbackRedemptionBankAccounts(
          organization!.id,
          currencyCashback.totalRedeemable.currency
        ),
      ]);

      if (!mounted.current) return;

      if (!bankAccounts.length) {
        enqueueSnackbar(t('redeemCashBackDialog.errors.noAccountsFound'), {
          variant: 'error',
        });
        props.onClose();
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        bankAccountId: bankAccounts[0].bankAccountId,
        bankAccounts,
        orgEmission,
        pricePerTonne,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      props.onClose();
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const { totalRedeemable } = currencyCashback;
  const maxTotalValue = round(totalRedeemable.value / 100, 2);
  const cashPayoutValue = round(
    maxTotalValue - (state.offsetPayoutValue ?? 0),
    2
  );
  let potentialOffsetWeight = 0;
  if (state.offsetPayoutValue && state.pricePerTonne?.value) {
    potentialOffsetWeight = round(
      (state.offsetPayoutValue / (state.pricePerTonne.value / 100)) * 1000
    );
  }
  const totalEmissionBalance =
    round(
      ((state.orgEmission?.lifetimeCarbonEmission ?? 0) -
        (state.orgEmission?.lifetimeCarbonOffset ?? 0)) /
        1000
    ) - potentialOffsetWeight;

  const redeemCashback = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      if (isPliantEarthActivated) {
        await api.redeemCashback(organization!.id, {
          bankAccountId: state.bankAccountId,
          payoutAmount: getMoneyObject(round(cashPayoutValue * 100)),
          carbonOffsetAmount: getMoneyObject(
            round(state.offsetPayoutValue * 100)
          ),
        });
      } else {
        await api.redeemCashback(organization!.id, {
          bankAccountId: state.bankAccountId,
          payoutAmount: currencyCashback.totalRedeemable,
        });
      }

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        step: 'success',
      }));
      onSuccess();
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const handleSliderChange = (e: Event, newValue: number | number[]) => {
    setState((prevState) => ({
      ...prevState,
      offsetPayoutValue: newValue as number,
    }));
  };

  const isAllowedValue = ({ floatValue }: NumberFormatValues) =>
    !floatValue || floatValue <= maxTotalValue;

  return (
    <Dialog {...props}>
      {state.step === 'redeem' ? (
        <>
          <DialogTitle>{t('redeemCashBackDialog.title')}</DialogTitle>
          <DialogContent>
            <Typography
              variant="caption"
              component="div"
              mb={0.5}
              color="text.secondary"
            >
              {t('redeemCashBackDialog.label')}
            </Typography>
            <Typography variant="h6" mb={3}>
              <FormatMoney value={totalRedeemable} fractionalPart />
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth disabled={state.isLoading}>
                  <InputLabel id="cashback-payout-bank-account-label">
                    {t('redeemCashBackDialog.cashbackPayoutAccount')}
                  </InputLabel>
                  <Select<string>
                    value={state.bankAccountId}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        bankAccountId: e.target.value,
                      }))
                    }
                    renderValue={(selected) => {
                      const account = state.bankAccounts.find(
                        (item) => item.bankAccountId === selected
                      );

                      return account ? getAccountName(account) : '';
                    }}
                    labelId="cashback-payout-bank-account-label"
                  >
                    {state.bankAccounts.map((item) => (
                      <MenuItem
                        key={item.bankAccountId}
                        value={item.bankAccountId}
                      >
                        {getAccountName(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {isPliantEarthActivated && (
              <>
                <Typography variant="subtitle1" mt={4} mb={3}>
                  {t('redeemCashBackDialog.redeemLabel')}
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <MoneyField
                      label={t('redeemCashBackDialog.toCashbackPayoutAccount')}
                      decimalScale={2}
                      isAllowed={isAllowedValue}
                      name="cash"
                      value={cashPayoutValue}
                      onValueChange={({ floatValue }) => {
                        setState((prevState) => ({
                          ...prevState,
                          offsetPayoutValue: maxTotalValue - (floatValue ?? 0),
                        }));
                      }}
                      disabled={state.isLoading}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <MoneyField
                      label={t('redeemCashBackDialog.toCompensate')}
                      helperText={
                        <>
                          <Trans
                            i18nKey="redeemCashBackDialog.compensateKgValue"
                            components={{
                              b: <b />,
                              span: <span />,
                            }}
                            values={{
                              offset: potentialOffsetWeight,
                            }}
                          />
                          <br />
                          <Trans
                            i18nKey="redeemCashBackDialog.balanceKgValue"
                            components={{
                              b: <b />,
                              span: <span />,
                            }}
                            values={{
                              balance: totalEmissionBalance,
                            }}
                          />
                        </>
                      }
                      decimalScale={2}
                      isAllowed={isAllowedValue}
                      name="offset"
                      value={state.offsetPayoutValue}
                      onValueChange={({ floatValue }) => {
                        setState((prevState) => ({
                          ...prevState,
                          offsetPayoutValue: floatValue ?? 0,
                        }));
                      }}
                      disabled={state.isLoading}
                    />
                  </Grid>
                </Grid>

                <Box width="80%" mx="auto" textAlign="center">
                  <Slider
                    max={maxTotalValue}
                    value={state.offsetPayoutValue}
                    onChange={handleSliderChange}
                    disabled={state.isLoading}
                    sx={{ mt: 3, mb: 1 }}
                  />
                  <Typography variant="caption" component="div">
                    {t('redeemCashBackDialog.sliderHint')}
                  </Typography>
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={props.onClose}>
              {t('common.button.cancel')}
            </Button>
            <Button disabled={state.isLoading} onClick={redeemCashback}>
              {t('redeemCashBackDialog.submitButton')}
            </Button>
          </DialogActions>
          <LoaderWithOverlay loading={state.isLoading} />
        </>
      ) : (
        <SuccessStep
          hasCashPayout={isPliantEarthActivated ? !!cashPayoutValue : true}
          hasOffsetPayout={!!state.offsetPayoutValue}
          onClose={props.onClose}
        />
      )}
    </Dialog>
  );
};

export default withDialogWrapper<Props>(RedeemCashbackDialog);
