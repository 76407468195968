import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { getStripeCardStatus } from 'domains/member/utils';
import {
  Box,
  Button,
  CreditCardIcon,
  DialogActions,
  DialogContent,
  LoaderWithOverlay,
  Typography,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  InitiateReimbursementResponse,
  PaymentMethod,
  StripeCardFunding,
  StripeCardStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';
import { Steps } from '..';

interface Props {
  onClose: () => void;
  goToNextStep: (step: Steps, response: InitiateReimbursementResponse) => void;
  transactionId: string;
  paymentMethod: PaymentMethod;
}

interface State {
  isConfirming: boolean;
}

const WithPaymentMethod = (props: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const {
    state: { member },
  } = useGlobalState();
  const mounted = useMounted();
  const [state, setState] = useState<State>({
    isConfirming: false,
  });

  const isStripeCardExpired =
    !!props.paymentMethod &&
    getStripeCardStatus(props.paymentMethod) === StripeCardStatus.expired;

  const initiateReimbursement = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isConfirming: true,
      }));
      const response = await api.initiateReimbursement(
        member.id,
        props.transactionId
      );
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConfirming: false,
      }));

      props.goToNextStep(
        isStripeCardExpired ? Steps.PAYMENT_DETAILS : Steps.IMMEDIATE_PAYMENT,
        response
      );
    } catch (error) {
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isConfirming: false,
      }));
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  return (
    <>
      <DialogContent>
        <LoaderWithOverlay loading={state.isConfirming} />
        <>
          <Typography variant="body1">
            {t(
              `transactionDetails.reimbursementDialog.descriptions.${
                isStripeCardExpired
                  ? 'expiredPaymentMethod'
                  : 'validPaymentMethod'
              }`
            )}
          </Typography>
          <Box display="flex" alignItems="center" mt={3} px={2} maxWidth="50%">
            <CreditCardIcon />
            <Box display="flex" flexDirection="column" ml={4} mr="auto">
              <Typography variant="body2" color="text.secondary">
                {t(
                  `privatePaymentCard.fundings.${
                    props.paymentMethod.funding || StripeCardFunding.credit
                  }`
                )}
              </Typography>
              <Box display="flex">
                <Typography
                  variant="body1"
                  marginRight={0.5}
                  color={isStripeCardExpired ? 'error' : 'text.primary'}
                >
                  {t(`privatePaymentCard.brands.${props.paymentMethod.brand}`)}{' '}
                  **** {props.paymentMethod.lastFourDigits}
                </Typography>
                <Typography
                  variant="body1"
                  color={isStripeCardExpired ? 'error' : 'text.secondary'}
                >
                  (
                  {isStripeCardExpired
                    ? t('privatePaymentCard.statuses.EXPIRED')
                    : moment
                        .utc(props.paymentMethod.expiryDate)
                        .format('MM/YY')}
                  )
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={props.onClose}
          children={t('common.button.cancel')}
        />
        <Button
          onClick={initiateReimbursement}
          children={
            isStripeCardExpired
              ? t('transactionDetails.reimbursementDialog.reimburseWithNewCard')
              : t('common.button.confirm')
          }
        />
      </DialogActions>
    </>
  );
};

export default WithPaymentMethod;
