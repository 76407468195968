import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { RolePicker } from 'domains/member/components';
import { useAvailableRoles } from 'domains/member/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  TextField,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  NetworkErrorCode,
  PartnerMemberMappedDetails,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { Role } from 'services/rbac';
import {
  getGenericErrorMsg,
  getNetworkErrorCode,
  trimObjValues,
  validateEmail,
} from 'services/utils';

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  roles: Role;
}

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
}

const InvitePartnerMemberDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { member },
  } = useGlobalState();
  const currentMember = member as PartnerMemberMappedDetails;
  const { allowedRoles } = useAvailableRoles({ isPartnerMember: true });

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      roles: allowedRoles[0].role,
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        await api.invitePartnerMember({
          ...trimObjValues(values),
          roles: [values.roles],
          partnerCompanyId: currentMember.partnerCompanyId,
        });
        if (!mounted.current) return;
        enqueueSnackbar(t('common.invitationSent'));
        onSuccess();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        if (
          getNetworkErrorCode(error) === NetworkErrorCode.memberLimitExceeded
        ) {
          enqueueSnackbar(t('inviteMemberDialog.memberLimitExceeded'), {
            variant: 'error',
          });
          return;
        }
        if (getNetworkErrorCode(error) === NetworkErrorCode.userAlreadyExists) {
          setErrors({
            email: t('addTeamMembersDialog.inviteMember.userExistsError'),
          });
          return;
        }
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  const { invalidEmailError, emailTooLongError } = validateEmail(
    formik.values.email
  );

  const isSubmitDisabled =
    !formik.values.firstName.trim() ||
    !formik.values.lastName.trim() ||
    !formik.values.email.trim() ||
    !!invalidEmailError ||
    !!emailTooLongError ||
    formik.isSubmitting;

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('invitePartnerMemberDialog.inviteMember')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="invite-partner-member-form"
          noValidate
        >
          <Grid container columnSpacing={3} rowSpacing={2}>
            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('firstName')}
                label={t('invitePartnerMemberDialog.firstName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('lastName')}
                label={t('invitePartnerMemberDialog.lastName')}
                inputProps={{ maxLength: 50 }}
                disabled={formik.isSubmitting}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                {...formik.getFieldProps('email')}
                label={t('invitePartnerMemberDialog.email')}
                disabled={formik.isSubmitting}
                error={
                  !!invalidEmailError ||
                  !!emailTooLongError ||
                  !!formik.errors.email
                }
                helperText={
                  invalidEmailError || emailTooLongError || formik.errors.email
                }
              />
            </Grid>

            <Grid item xs={6}>
              <RolePicker
                value={formik.values.roles}
                onChange={(role) => formik.setFieldValue('roles', role)}
                allowedRoles={allowedRoles}
                isDisabled={formik.isSubmitting}
                showHelperText={false}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={isSubmitDisabled}
          form="invite-partner-member-form"
          type="submit"
        >
          {t('invitePartnerMemberDialog.sendInvite')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(InvitePartnerMemberDialog);
