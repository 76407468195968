import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowLineRightIcon,
  BankIcon,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  withDialogWrapper,
} from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { BillPaymentBillPaymentType } from 'services/constants';

interface ListButtonProps {
  onClick: () => void;
  primary?: ReactNode;
  secondary?: ReactNode;
  icon?: ReactNode;
}

const ListButton = ({ onClick, primary, secondary, icon }: ListButtonProps) => (
  <ListItemButton
    onClick={onClick}
    sx={{
      mb: 2,
      px: 3,
      py: 2.5,
      borderRadius: 1,
      border: (theme) => `1px solid ${theme.palette.divider}`,
    }}
  >
    <ListItemIcon sx={{ minWidth: 48 }}>{icon}</ListItemIcon>
    <ListItemText
      primary={primary}
      secondary={secondary}
      secondaryTypographyProps={{ variant: 'caption' }}
      sx={{ my: 0 }}
    />
  </ListItemButton>
);

type Type =
  | BillPaymentBillPaymentType.internalTransfer
  | BillPaymentBillPaymentType.prefundPayment;

interface Props extends DialogProps {
  onClose: () => void;
  onToggle: (type: Type) => void;
}

const TopUpSelectionDialog = ({ onToggle, ...props }: Props) => {
  const { t } = useTranslation();
  const partnerName = usePartnerName();

  return (
    <Dialog {...props} maxWidth="sm">
      <DialogTitle>{t('topUpSelectionDialog.title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" mb={3}>
          {t('topUpSelectionDialog.description')}
        </Typography>

        <List>
          <ListButton
            onClick={() => onToggle(BillPaymentBillPaymentType.prefundPayment)}
            primary={t('topUpSelectionDialog.prefundPaymentBtnLabel')}
            secondary={t('topUpSelectionDialog.prefundPaymentBtnDescription')}
            icon={<BankIcon />}
          />
          <ListButton
            onClick={() =>
              onToggle(BillPaymentBillPaymentType.internalTransfer)
            }
            primary={t('topUpSelectionDialog.internalTransferBtnLabel', {
              partnerName,
            })}
            secondary={t(
              'topUpSelectionDialog.internalTransferBtnDescription',
              { partnerName }
            )}
            icon={<ArrowLineRightIcon />}
          />
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default withDialogWrapper(TopUpSelectionDialog);
