import { LicenseInfo } from '@mui/x-license-pro';
import ReactDOM from 'react-dom';
import { ExternalApp, InternalApp, PortalApp } from 'components/App';
import { env } from 'env';
import { MUI_LICENSE_KEY } from 'services/constants';
import initializeI18n from 'services/i18n';
import { initializeMonitoring } from 'services/monitoring';
// init axios defaults
import 'services/network';

initializeMonitoring();

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const App = (() => {
  if (env.REACT_APP_PARTNER_SCOPE === 'portal') return PortalApp;
  return env.REACT_APP_BUILD_TARGET === 'external' ? ExternalApp : InternalApp;
})();

(async () => {
  await initializeI18n('en');
  ReactDOM.render(<App />, document.getElementById('app-root'));
})();
