import { Trans } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import LexOfficeLogo from 'assets/svg/integrations/lexOfficeLogo.svg?react';
import AppLogo from 'components/AppLogo';
import { ApiIntegrationStatusBadge } from 'components/Badge';
import { useGlobalState } from 'context/GlobalState';
import {
  IntegrationConnectionBlock,
  IntegrationConnectionLogoWrapper,
} from 'domains/settings/layout';
import { Box, Button, Typography } from 'elements';
import usePartnerName from 'hooks/usePartnerName';
import { AccountingSystem, ApiIntegrationStatus } from 'services/constants';
import { useCanUser } from 'services/rbac';
import { getPath } from 'services/utils';

const getTextKey = (
  accountingSystem: AccountingSystem,
  status: ApiIntegrationStatus
) => {
  let mainTextKey, actionButtonTextKey;

  if (accountingSystem === AccountingSystem.lexOffice) {
    if (status === ApiIntegrationStatus.connected) {
      mainTextKey = 'locoiaIntegrationSubPage.lexOffice.activeDescription';
      actionButtonTextKey = 'locoiaIntegrationSubPage.disconnectButton';
    } else {
      mainTextKey = 'locoiaIntegrationSubPage.lexOffice.inactiveDescription';
      actionButtonTextKey = 'locoiaIntegrationSubPage.connectButton';
    }
  } else {
    if (status === ApiIntegrationStatus.connected) {
      mainTextKey = 'locoiaIntegrationSubPage.customSystem.activeDescription';
      actionButtonTextKey = 'common.button.deactivate';
    } else {
      mainTextKey = 'locoiaIntegrationSubPage.customSystem.inactiveDescription';
      actionButtonTextKey = 'common.button.activate';
    }
  }
  return { mainTextKey, actionButtonTextKey };
};

interface Props {
  onConnectionChange: () => void;
}

const LocoiaConnectionBlock = ({ onConnectionChange }: Props) => {
  const canUser = useCanUser();
  const partnerName = usePartnerName();
  const {
    state: { organization, accountingSettings },
  } = useGlobalState();

  const { mainTextKey, actionButtonTextKey } = getTextKey(
    accountingSettings!.accountingSystem!,
    accountingSettings!.apiIntegrationStatus
  );

  return (
    <IntegrationConnectionBlock>
      <IntegrationConnectionLogoWrapper
        opacity={
          accountingSettings!.apiIntegrationStatus !==
          ApiIntegrationStatus.connected
            ? 0.5
            : undefined
        }
      >
        {accountingSettings!.accountingSystem === AccountingSystem.lexOffice ? (
          <LexOfficeLogo />
        ) : (
          <AppLogo />
        )}
      </IntegrationConnectionLogoWrapper>

      <Box display="flex" justifyContent="center" mb={3}>
        <ApiIntegrationStatusBadge
          status={accountingSettings!.apiIntegrationStatus}
        />
      </Box>

      <Typography mb={3}>
        <Trans
          i18nKey={mainTextKey}
          components={{
            linkTo: (
              <Link
                to={generatePath(getPath('export'), {
                  orgId: organization!.id,
                })}
              />
            ),
          }}
          values={{
            partnerName,
          }}
        />
      </Typography>

      <Button
        variant={
          accountingSettings!.apiIntegrationStatus !==
          ApiIntegrationStatus.connected
            ? 'contained'
            : 'outlined'
        }
        onClick={onConnectionChange}
        disabled={!canUser('locoia-settings:change')}
      >
        <Trans
          i18nKey={actionButtonTextKey}
          values={{
            name: accountingSettings!.accountingSystemName,
          }}
        />
      </Button>
    </IntegrationConnectionBlock>
  );
};

export default LocoiaConnectionBlock;
