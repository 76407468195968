import { useFormik } from 'formik';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import {
  OnboardingTaskActions,
  TaskTitle,
} from 'domains/onboarding/components';
import { Box, DatePicker, Grid, LoaderWithOverlay } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { PageTitle } from 'layout';
import {
  OnboardingItemStatus,
  OnboardingTaskDateOfBirth,
  TaskNavigationPropsBase,
} from 'services/constants';
import { logError } from 'services/monitoring';
import { useTanstackQuery } from 'services/network/useTanstackQuery';
import { getGenericErrorMsg } from 'services/utils';

interface FormValues {
  dateOfBirth: Moment | null;
}

export interface Props extends TaskNavigationPropsBase {
  task: OnboardingTaskDateOfBirth;
  onUpdate: (newTask: OnboardingTaskDateOfBirth) => void;
}

const DateOfBirthTask = ({
  isReadOnly,
  task,
  taskNavigationItems,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { organization },
  } = useGlobalState();
  const { useUpdateDateOfBirth } = useTanstackQuery();
  const { mutate: dateOfBirthMutate } = useUpdateDateOfBirth({
    onSuccess: (response) => {
      if (!mounted.current) return;
      onUpdate(response);
    },
    onError: (error) => {
      if (!mounted.current) return;
      formik.setSubmitting(false);
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    },
  });

  const {
    data: { dateOfBirth },
  } = task;

  const formik = useFormik<FormValues>({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      dateOfBirth: dateOfBirth.value ? moment(dateOfBirth.value) : null,
    },
    onSubmit: (values) => {
      dateOfBirthMutate({
        organizationId: organization!.id,
        taskId: task.id,
        data: {
          dateOfBirth: values.dateOfBirth
            ? moment(values.dateOfBirth).format('YYYY-MM-DD')
            : '',
        },
      });
    },
  });

  return (
    <>
      <PageTitle pt={0} title={<TaskTitle task={task} />} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DatePicker
            label={t('orgOnboardingDateOfBirthTask.dateOfBirthLabel')}
            disabled={
              isReadOnly || formik.isSubmitting || !dateOfBirth.editable
            }
            {...formik.getFieldProps('dateOfBirth')}
            onChange={(newDate) => formik.setFieldValue('dateOfBirth', newDate)}
            maxDate={moment().subtract(18, 'years')}
            onError={(error) => {
              if (!error) {
                formik.setFieldError('dateOfBirth', '');
                return;
              }
              formik.setFieldError(
                'dateOfBirth',
                error === 'maxDate'
                  ? t('orgOnboardingDateOfBirthTask.dateOfBirthPastError')
                  : t('orgOnboardingDateOfBirthTask.dateOfBirthInvalidError')
              );
            }}
            onAccept={() => formik.setFieldError('dateOfBirth', '')}
            slotProps={{
              field: {
                format: 'DD.MM.YYYY',
              },
              textField: {
                InputProps: { notched: false },
                size: 'medium',
                error: !!formik.errors.dateOfBirth,
                helperText: formik.errors.dateOfBirth,
              },
            }}
          />
        </Grid>
      </Grid>

      <Box mt={4}>
        <OnboardingTaskActions
          isReadOnly={isReadOnly || !dateOfBirth.editable}
          taskNavigationItems={taskNavigationItems}
          disabled={
            formik.isSubmitting ||
            (!formik.values.dateOfBirth && dateOfBirth.required) ||
            (task.status !== OnboardingItemStatus.requiresAction &&
              !formik.dirty) ||
            !!formik.errors.dateOfBirth
          }
          onSuccess={formik.handleSubmit}
        />
      </Box>

      <LoaderWithOverlay loading={formik.isSubmitting} />
    </>
  );
};

export default DateOfBirthTask;
