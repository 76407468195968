import { useEffect, useRef, useState } from 'react';
import lodashDebounce from 'lodash/debounce';
import { useGlobalState } from 'context/GlobalState';
import { env } from 'env';
import { ExportSseData } from 'services/constants';

// 60 minutes
const RECONNECTION_FREQUENCY = 60 * 60 * 1000;

const baseURL = env.MODE === 'development' ? '/api' : env.REACT_APP_API_URL;

const useEventSource = (serverEventsAllowed: boolean) => {
  const sseRef = useRef<EventSource | null>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const reconnectFrequencyRef = useRef(1);
  const {
    state: { organization },
  } = useGlobalState();
  const [eventSourceData, setEventSourceData] = useState<ExportSseData | null>(
    null
  );
  const [isEventSourceError, setIsEventSourceError] = useState(false);

  const getRealData = (e: any) => {
    const parsedData: ExportSseData = JSON.parse(e.data);
    setEventSourceData(parsedData);
    setIsEventSourceError(false);
  };

  const connectToServer = () => {
    const sse = new EventSource(
      `${baseURL}/integrations/sse/organizations/${organization!.id}/status`
    );
    sseRef.current = sse;
    sseRef.current.addEventListener('EXPORT_STATUS', getRealData);

    // reconnect connection every N seconds to prevent timeout
    sseRef.current.onopen = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        if (sseRef.current) {
          sseRef.current.removeEventListener('EXPORT_STATUS', getRealData);
          sseRef.current.close();
          sseRef.current = null;
          connectToServer();
        }
      }, RECONNECTION_FREQUENCY);
    };

    sseRef.current.onerror = () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (sseRef.current) {
        sseRef.current.removeEventListener('EXPORT_STATUS', getRealData);
        sseRef.current.close();
        sseRef.current = null;
      }
      setIsEventSourceError(true);
      setEventSourceData(null);
      // slowly raise the delay up to ~ 1min
      reconnectFrequencyRef.current =
        reconnectFrequencyRef.current < 64
          ? reconnectFrequencyRef.current * 2
          : 64;

      lodashDebounce(connectToServer, reconnectFrequencyRef.current * 1000)();
    };
  };

  useEffect(() => {
    if (serverEventsAllowed) {
      connectToServer();
    }
    return () => {
      // clear timeout
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      // disconnect
      if (sseRef.current) {
        sseRef.current.removeEventListener('EXPORT_STATUS', getRealData);
        sseRef.current.close();
      }
    };
  }, [serverEventsAllowed]);

  return { eventSourceData, isEventSourceError };
};

export default useEventSource;
