import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { orgOnboardingPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import { DocumentLink } from 'domains/document/components';
import { ArchivedDocumentsDialog } from 'domains/document/dialogs';
import { getDocumentName, getDocumentUrl } from 'domains/document/utils';
import LanguageSelect from 'domains/member/components/LanguageSelect';
import { NotificationSettingsDialog } from 'domains/member/dialogs';
import { Box, Button, CaretLeftIcon, Typography } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useCurrentApp from 'hooks/useCurrentApp';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import {
  Document,
  DocumentGenericType,
  NetworkErrorCode,
} from 'services/constants';
import { useFlags } from 'services/featureflags';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg, getNetworkErrorCode } from 'services/utils';
import InsuranceSection from './InsuranceSection';
import IssuanceLimitSection from './IssuanceLimitSection';
import PairedDevice from './PairedDevice';
import PasswordSection from './PasswordSection';
import PersonalDataSection from './PersonalDataSection';
import PrivatePaymentCard from './PrivatePaymentCard';

const ProfilePage = ({ isOnboardingFlow }: { isOnboardingFlow?: boolean }) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const { isPortalApp } = useCurrentApp();
  const {
    state: {
      isComplianceRiskWhiteLabelApp,
      organization,
      featureModules,
      documents,
      defaultCardAccount,
      member,
    },
  } = useGlobalState();
  const {
    companyReimbursementEnabled,
    emailNotificationSettingsEnabled,
  } = useFlags();
  const canUser = useCanUser();
  const [
    isArchivedDocumentsDialogOpen,
    setIsArchivedDocumentsDialogOpen,
  ] = useState(false);
  const [
    isNotificationSettingsDialogOpen,
    setIsNotificationSettingsDialogOpen,
  ] = useState(false);
  const [
    cardholderReimbursementTermsAndConditionsDocument,
    setCardholderReimbursementTermsAndConditionsDocument,
  ] = useState<Document | null>(null);

  useEffect(() => {
    getCardholderReimbursementTermsAndConditionsDocument();
  }, []);

  const getCardholderReimbursementTermsAndConditionsDocument = async () => {
    try {
      const document = await api.getCardholderReimbursementTermsAndConditionsDocument(
        member.id
      );
      if (!mounted.current) return;
      setCardholderReimbursementTermsAndConditionsDocument(document);
    } catch (error) {
      if (!mounted.current) return;
      if (
        getNetworkErrorCode(error) ===
        NetworkErrorCode.noAcceptedTermsAndConditions
      )
        return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  const docsToShow = {
    ...documents,
  };
  if (cardholderReimbursementTermsAndConditionsDocument) {
    docsToShow[
      DocumentGenericType.cardholderReimbursementTermsAndConditions
    ] = [cardholderReimbursementTermsAndConditionsDocument];
  }

  return (
    <>
      <PageHeader>
        <ContentContainer
          size={isOnboardingFlow ? 'sm' : 'md'}
          mx={isOnboardingFlow ? 'auto' : 0}
        >
          {isOnboardingFlow && (
            <Button
              size="large"
              variant="outlined"
              startIcon={<CaretLeftIcon />}
              component={RouterLink}
              to={generatePath(orgOnboardingPaths.orgOnboarding)}
              sx={{ mb: 2 }}
            >
              {t('profilePage.backToOnboarding')}
            </Button>
          )}

          <PageTitle title={t('profilePage.profileDetails')} />
        </ContentContainer>
      </PageHeader>

      <PageContent>
        <ContentContainer
          size={isOnboardingFlow ? 'sm' : 'md'}
          mx={isOnboardingFlow ? 'auto' : 0}
        >
          <PersonalDataSection />

          {!isPortalApp && <PasswordSection />}

          {!isPortalApp &&
            companyReimbursementEnabled &&
            featureModules.COMPANY_REIMBURSEMENT && <PrivatePaymentCard />}

          {!isPortalApp && !isComplianceRiskWhiteLabelApp && <PairedDevice />}

          {!isPortalApp && <IssuanceLimitSection />}

          {!isPortalApp &&
            !isComplianceRiskWhiteLabelApp &&
            emailNotificationSettingsEnabled && (
              <Box mb={8}>
                <Typography variant="h6" mb={1}>
                  {t('profilePage.notificationSettings')}
                </Typography>
                <Button
                  disabled={
                    !canUser('profile-page-notification-settings:manage')
                  }
                  onClick={() => setIsNotificationSettingsDialogOpen(true)}
                  size="large"
                  variant="outlined"
                >
                  {t('profilePage.notificationSettingsButton')}
                </Button>
              </Box>
            )}

          <Box mb={8}>
            <Typography variant="h6" mb={1}>
              {t('profilePage.language')}
            </Typography>
            <LanguageSelect />
          </Box>

          {!isPortalApp && !isComplianceRiskWhiteLabelApp && (
            <InsuranceSection />
          )}

          {!isPortalApp && (
            <>
              <Box mb={3}>
                <Typography variant="h6" mb={1}>
                  {t('profilePage.legalDocuments')}
                </Typography>
                <div>
                  {docsToShow ? (
                    [
                      DocumentGenericType.platformTermsAndConditions,
                      DocumentGenericType.platformDataPrivacyPolicyWeb,
                      DocumentGenericType.platformDataPrivacyPolicyMobile,
                      DocumentGenericType.bankTermsAndConditions,
                      DocumentGenericType.bankDataPrivacyPolicy,
                      DocumentGenericType.issuerTermsAndConditions,
                      DocumentGenericType.issuerDataPrivacyPolicy,
                      DocumentGenericType.insuranceTerms,
                      DocumentGenericType.cardholderReimbursementTermsAndConditions,
                    ].map((genericType) => {
                      if (!docsToShow[genericType]) return null;
                      const doc = docsToShow[genericType]![0];
                      return (
                        <DocumentLink
                          key={doc.documentId}
                          name={getDocumentName(
                            doc,
                            genericType,
                            organization!.partnerId,
                            defaultCardAccount!.accountGroup.value
                          )}
                          url={getDocumentUrl(doc.documentName)}
                        />
                      );
                    })
                  ) : (
                    <Typography color="error">
                      {t('errors.loadData')}
                    </Typography>
                  )}
                </div>
              </Box>
              <Button
                onClick={() => setIsArchivedDocumentsDialogOpen(true)}
                size="large"
                variant="outlined"
              >
                {t('profilePage.archivedDocuments')}
              </Button>
            </>
          )}
        </ContentContainer>
      </PageContent>

      <NotificationSettingsDialog
        open={isNotificationSettingsDialogOpen}
        onClose={() => setIsNotificationSettingsDialogOpen(false)}
      />

      <ArchivedDocumentsDialog
        open={isArchivedDocumentsDialogOpen}
        onClose={() => setIsArchivedDocumentsDialogOpen(false)}
      />
    </>
  );
};

export const CardholderProfilePage = withPageConfig(ProfilePage, {
  permission: 'ch-profile-page:visit',
});

export const AdminProfilePage = withPageConfig(ProfilePage, {
  permission: 'profile-page:visit',
});
