import { useEffect, useMemo, useRef, useState } from 'react';
import xor from 'lodash/xor';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import BulkUploadItemsDialog from 'components/BulkUploadItemsDialog';
import ConfirmDialog from 'components/ConfirmDialogV2';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import { useActiveTeams, useGlobalState } from 'context/GlobalState';
import {
  CreateEditTeamBudgetDialog,
  CreateEditTeamDialog,
  CreateTeamMultistepDialog,
  EditTeamPermissionsDialog,
  UpdateTeamMembersDialog,
} from 'domains/member/dialogs';
import { AddNewItemMenu } from 'domains/settings/components/AddNewItemMenu';
import {
  Box,
  Button,
  FormControlLabel,
  GearSixIcon,
  Grid,
  HandCoinsIcon,
  IconButton,
  ListItemIcon,
  LoaderWithOverlay,
  MenuContainer,
  MenuItem,
  Paper,
  PencilSimpleIcon,
  Switch,
  ThumbsUpIcon,
  Tooltip,
  Typography,
  UserGearIcon,
  UsersIcon,
  UsersThreeIcon,
  XCircleIcon,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import useSnackbar from 'hooks/useSnackbar';
import {
  PageContent,
  PageFilters,
  PageHeader,
  PageSearchInput,
  PageTitle,
  PageTitleActions,
} from 'layout';
import {
  FeatureModuleKey,
  Team,
  TeamStatus,
  teamStatuses,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';
import TeamBudget from './TeamBudget';
import TeamMemberList from './TeamMemberList';

const getQueryParams = (qs: string) => {
  const { q, status } = Object.fromEntries(new URLSearchParams(qs).entries());

  return {
    q: q ? q.trim() : '',
    status:
      status && xor(status.split(','), teamStatuses).length === 0
        ? teamStatuses
        : [TeamStatus.active],
  };
};

const getTeamMemberList = (
  managersLength: number,
  isReviewToggleVisible: boolean
) => {
  // need to reduce number of shown members for better UI
  if (isReviewToggleVisible) return managersLength ? 3 : 6;
  return managersLength ? 5 : 8;
};

interface State {
  teamToEdit: Team | null;
  teamToUpdateMembers: Team | null;
  teamToEditPermissions: Team | null;
  teamToDelete: Team | null;
  isTeamDeleting: boolean;
  teamToDeactivate: Team | null;
  teamToUpdateBudget: Team | null;
  isTeamDeactivating: boolean;
  isLoading: boolean;
  isCreateTeamMultistepDialogOpen: boolean;
  isUploadTeamsDialogOpen: boolean;
}

const TeamsPage = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const api = useImperativeApi();
  const location = useLocation();
  const setQueryParam = useSetQueryParam();
  const canUser = useCanUser();
  const { dispatch, state: globalState } = useGlobalState();
  const activeTeams = useActiveTeams();
  const paramsRef = useRef(getQueryParams(location.search));
  const [state, setState] = useState<State>({
    teamToEdit: null,
    teamToUpdateMembers: null,
    teamToEditPermissions: null,
    teamToDelete: null,
    teamToUpdateBudget: null,
    isTeamDeleting: false,
    teamToDeactivate: null,
    isTeamDeactivating: false,
    isLoading: true,
    isCreateTeamMultistepDialogOpen: false,
    isUploadTeamsDialogOpen: false,
  });

  const teams = useMemo(() => {
    paramsRef.current = getQueryParams(location.search);
    const { q, status } = paramsRef.current;
    if (!!q || !!status.length) {
      return globalState.teams
        .filter((item) =>
          item.name.toLowerCase().includes(paramsRef.current.q.toLowerCase())
        )
        .filter((item) => status.includes(item.teamStatus));
    } else {
      return globalState.teams;
    }
  }, [globalState.teams, location.search]);

  const areFiltersApplied = !!paramsRef.current.q.length;
  const isEmptyState = !state.isLoading && !teams.length && !areFiltersApplied;

  const updateTeams = async () => {
    try {
      const { teams } = await api.getTeams({
        organizationId: globalState.organization!.id,
      });
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: { teams, teamCount: teams.length },
      });
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isLoading: false }));
      logError(error);
    }
  };

  useEffect(() => {
    updateTeams();
  }, []);

  const deactivateTeam = async () => {
    try {
      setState((prevState) => ({ ...prevState, isTeamDeactivating: true }));
      const data = await api.deactivateTeam(state.teamToDeactivate!.id);
      dispatch({ type: 'UPDATE_TEAM', payload: data });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        teamToDeactivate: null,
        isTeamDeactivating: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isTeamDeactivating: false }));
      logError(error);
    }
  };

  const requestTeamDeletion = async (team: Team) => {
    try {
      if (state.isTeamDeleting) return;
      setState((prevState) => ({ ...prevState, isTeamDeleting: true }));
      const { assignments } = await api.getTeamTransactionAssignments(
        globalState.organization!.id,
        team.id
      );
      if (!mounted.current) return;
      if (assignments[0]?.hasAssignedTransactions) {
        setState((prevState) => ({ ...prevState, isTeamDeleting: false }));
        return enqueueSnackbar(t('teamsPage.teamCannotBeDeletedError'), {
          variant: 'error',
        });
      }
      setState((prevState) => ({
        ...prevState,
        teamToDelete: team,
        isTeamDeleting: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isTeamDeleting: false }));
      logError(error);
    }
  };

  const deleteTeam = async () => {
    try {
      setState((prevState) => ({ ...prevState, isTeamDeleting: true }));
      await api.deleteTeam(state.teamToDelete!.id);
      dispatch({ type: 'DELETE_TEAM', payload: state.teamToDelete! });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        teamToDelete: null,
        isTeamDeleting: false,
      }));
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      setState((prevState) => ({ ...prevState, isTeamDeleting: false }));
      logError(error);
    }
  };

  const renderMenu = (team: Team) => {
    const isEditPermissionsVisible = canUser('team-permissions:change', team);
    const isEditBudgetVisible =
      globalState.featureModules.TEAM_BUDGETS && canUser('team-budget:change');
    const isMenuVisible =
      canUser('team-details:change', team) ||
      canUser('team-members:change', team) ||
      isEditPermissionsVisible ||
      isEditBudgetVisible ||
      canUser('team:deactivate', team) ||
      canUser('team:delete', team);

    if (!isMenuVisible) return null;

    return (
      <MenuContainer
        button={
          <IconButton sx={{ ml: 1, my: 0.5 }}>
            <GearSixIcon />
          </IconButton>
        }
      >
        {canUser('team-details:change', team) && (
          <MenuItem
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                teamToEdit: team,
              }));
            }}
          >
            <ListItemIcon>
              <PencilSimpleIcon />
            </ListItemIcon>
            {t('teamsPage.editTeamDetails')}
          </MenuItem>
        )}
        {canUser('team-members:change', team) && (
          <MenuItem
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                teamToUpdateMembers: team,
              }));
            }}
          >
            <ListItemIcon>
              <UsersThreeIcon />
            </ListItemIcon>
            {t('teamsPage.editTeamMembers')}
          </MenuItem>
        )}
        {isEditPermissionsVisible && (
          <MenuItem
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                teamToEditPermissions: team,
              }));
            }}
          >
            <ListItemIcon>
              <UserGearIcon />
            </ListItemIcon>
            {t('teamsPage.editPermissions')}
          </MenuItem>
        )}
        {isEditBudgetVisible && (
          <MenuItem
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                teamToUpdateBudget: team,
              }));
            }}
          >
            <ListItemIcon>
              <HandCoinsIcon />
            </ListItemIcon>
            {t('teamsPage.editTeamBudget')}
          </MenuItem>
        )}
        {canUser('team:deactivate', team) && (
          <MenuItem
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                teamToDeactivate: team,
              }))
            }
          >
            <ListItemIcon>
              <XCircleIcon />
            </ListItemIcon>
            {t('teamsPage.deactivateTeam')}
          </MenuItem>
        )}
        {canUser('team:delete', team) && (
          <MenuItem onClick={() => requestTeamDeletion(team)}>
            <ListItemIcon>
              <XCircleIcon />
            </ListItemIcon>
            {t('teamsPage.deleteTeam')}
          </MenuItem>
        )}
      </MenuContainer>
    );
  };

  return (
    <>
      <PageHeader>
        <PageTitle title={t('teamsPage.title')}>
          {canUser('team:bulk-upload') ? (
            <AddNewItemMenu
              format="XLSX"
              title={t('int.teamsPage.addTeam')}
              onAdd={() =>
                setState((prevState) => ({
                  ...prevState,
                  isCreateTeamMultistepDialogOpen: true,
                }))
              }
              onUpload={() =>
                setState((prevState) => ({
                  ...prevState,
                  isUploadTeamsDialogOpen: true,
                }))
              }
            />
          ) : canUser('team:create') ? (
            <PageTitleActions>
              <Button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    isCreateTeamMultistepDialogOpen: true,
                  }))
                }
                data-intercom-target="create-team-button"
                startIcon={<UsersIcon />}
              >
                {t('teamsPage.createTeam')}
              </Button>
            </PageTitleActions>
          ) : null}
        </PageTitle>

        <PageFilters
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pb: 2,
            mb: -2,
            borderBottom: `1px solid ${theme.palette.divider}`,
          })}
        >
          <PageSearchInput
            initialValue={paramsRef.current.q}
            onChange={(value) => setQueryParam('q', value)}
            disabled={isEmptyState}
          />

          {activeTeams.length !== globalState.teams.length && (
            <FormControlLabel
              checked={paramsRef.current.status.length !== 1}
              onChange={(e, checked) =>
                setQueryParam('status', checked ? teamStatuses : [])
              }
              control={<Switch />}
              label={t('teamsPage.deactivatedTeams')}
            />
          )}
        </PageFilters>
      </PageHeader>

      <PageContent pt={2}>
        <LoaderWithOverlay loading={state.isLoading} />

        {isEmptyState && (
          <NoData isNewDesign Icon={UsersIcon} label={t('teamsPage.noTeams')} />
        )}

        {!state.isLoading && !teams.length && areFiltersApplied && (
          <NothingFound />
        )}

        <Grid container spacing={4}>
          {!state.isLoading &&
            teams.map((team) => {
              const isTeamDeactivated =
                team.teamStatus === TeamStatus.deactivated;

              return (
                <Grid item key={team.id}>
                  <Paper
                    variant="outlined"
                    sx={{
                      width: 350,
                      height: 240,
                      opacity: isTeamDeactivated ? 0.5 : 1,
                    }}
                  >
                    <Box
                      p={2}
                      borderBottom={(theme) =>
                        `1px solid ${theme.palette.divider}`
                      }
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box minWidth={0}>
                          {team.costCenter && (
                            <Typography
                              variant="caption"
                              component="div"
                              color="text.secondary"
                              noWrap
                              my="-2px"
                            >
                              {team.costCenter}
                            </Typography>
                          )}
                          <Typography variant="h6" noWrap>
                            {team.name}
                          </Typography>
                        </Box>
                        {renderMenu(team)}
                      </Box>

                      {globalState.featureModules.TEAM_BUDGETS &&
                        (team.budget?.value ? (
                          <TeamBudget
                            balance={team.balance!}
                            budget={team.budget}
                          />
                        ) : (
                          <Box height={72} />
                        ))}
                    </Box>

                    <Box display="flex" alignItems="center" p={2}>
                      {!!team.managers.length && (
                        <Box width="40%">
                          <Typography variant="subtitle2">
                            <Trans
                              i18nKey="teamsPage.managers"
                              values={{ count: team.managers.length }}
                              components={{
                                highlight: (
                                  <Typography
                                    variant="body2"
                                    component="span"
                                    color="text.secondary"
                                  />
                                ),
                              }}
                            />
                          </Typography>
                          <TeamMemberList
                            areManagers
                            members={team.managers}
                            maxItems={2}
                          />
                        </Box>
                      )}
                      <Box flexGrow={1}>
                        <Typography variant="subtitle2">
                          <Trans
                            i18nKey="teamsPage.members"
                            values={{ count: team.members.length }}
                            components={{
                              highlight: (
                                <Typography
                                  variant="body2"
                                  component="span"
                                  color="text.secondary"
                                />
                              ),
                            }}
                          />
                        </Typography>
                        <TeamMemberList
                          members={team.members}
                          maxItems={getTeamMemberList(
                            team.managers.length,
                            globalState.featureModules.MANAGER_TX_REVIEWS
                          )}
                        />
                      </Box>

                      {globalState.featureModules.MANAGER_TX_REVIEWS && (
                        <Tooltip
                          disableHoverListener={isTeamDeactivated}
                          disableTouchListener={isTeamDeactivated}
                          title={
                            team.managerTxReviewRequired
                              ? t('teamsPage.txReviewActivatedTooltip')
                              : t('teamsPage.txReviewDeactivatedTooltip')
                          }
                        >
                          <ThumbsUpIcon
                            fontSize="small"
                            sx={{
                              mr: 1.5,
                              color: team.managerTxReviewRequired
                                ? 'action.active'
                                : 'action.disabled',
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
      </PageContent>

      <CreateTeamMultistepDialog
        open={state.isCreateTeamMultistepDialogOpen}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            isCreateTeamMultistepDialogOpen: false,
          }))
        }
      />
      <CreateEditTeamDialog
        team={state.teamToEdit}
        open={!!state.teamToEdit}
        onClose={() =>
          setState((prevState) => ({ ...prevState, teamToEdit: null }))
        }
      />
      <UpdateTeamMembersDialog
        team={state.teamToUpdateMembers}
        isEditing={true}
        open={!!state.teamToUpdateMembers}
        onClose={() =>
          setState((prevState) => ({ ...prevState, teamToUpdateMembers: null }))
        }
      />
      <EditTeamPermissionsDialog
        team={state.teamToEditPermissions}
        isEditing={true}
        open={!!state.teamToEditPermissions}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            teamToEditPermissions: null,
          }))
        }
      />
      <CreateEditTeamBudgetDialog
        open={!!state.teamToUpdateBudget}
        team={state.teamToUpdateBudget}
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            teamToUpdateBudget: null,
          }))
        }
        isEditing={true}
      />
      <ConfirmDialog
        open={!!state.teamToDeactivate}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            teamToDeactivate: null,
          }));
        }}
        onSuccess={deactivateTeam}
        loading={state.isTeamDeactivating}
        title={t('teamsPage.deactivateTeamConfirmationTitle')}
        description={t('teamsPage.deactivateTeamConfirmationDescription')}
      />
      <ConfirmDialog
        open={!!state.teamToDelete}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            teamToDelete: null,
          }));
        }}
        onSuccess={deleteTeam}
        loading={state.isTeamDeleting}
        title={t('teamsPage.deleteTeamConfirmationTitle')}
        description={
          <Trans
            i18nKey="teamsPage.deleteTeamConfirmationDescription"
            values={state.teamToDelete || { name: '' }}
          />
        }
      />
      {canUser('team:bulk-upload') && (
        <BulkUploadItemsDialog
          open={state.isUploadTeamsDialogOpen}
          onClose={() =>
            setState((prevState) => ({
              ...prevState,
              isUploadTeamsDialogOpen: false,
            }))
          }
          context="teams"
        />
      )}
    </>
  );
};

export default withPageConfig(TeamsPage, {
  permission: 'teams-page:visit',
  featureModule: FeatureModuleKey.teams,
});
