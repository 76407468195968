import { useState } from 'react';
import { useFormik } from 'formik';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import PartnerSelect from 'components/PartnerSelect';
import { useGlobalState } from 'context/GlobalState';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  PartnerAudit,
  PartnerIds,
  PartnerName,
  PartnerStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { getGenericErrorMsg } from 'services/utils';

const defaultPartner = {
  partnerId: PartnerIds.pliant,
  status: PartnerStatus.active,
  name: PartnerName.pliant,
};

interface Props extends DialogProps {
  onSuccess: (partnerAudit: PartnerAudit) => void;
  onClose: () => void;
}

const ChangePartnerDialog = ({ onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const {
    dispatch,
    state: { organization },
  } = useGlobalState();
  const [isConfirmationStep, setIsConfirmationStep] = useState(true);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      partner: organization!.partnerId
        ? {
            partnerId: organization!.partnerId,
            name: organization!.partnerName,
            status: PartnerStatus.active,
          }
        : defaultPartner,
      partnerRevenueShare: organization!.partnerRevenueShareId
        ? {
            partnerId: organization!.partnerRevenueShareId,
            name: organization!.partnerRevenueShareName,
            status: PartnerStatus.active,
          }
        : defaultPartner,
    },
    onSubmit: async ({ partner, partnerRevenueShare }, { setSubmitting }) => {
      const { partnerId } = partner;
      const partnerRevenueShareId = partnerRevenueShare.partnerId;
      try {
        const updatedPartnerAudit = await api.changePartner(organization!.id, {
          partnerId,
          partnerRevenueShareId,
        });

        const updatedPartner = await api.getPartnerOrgAuthDetails(
          partnerId,
          organization!.id
        );
        dispatch({
          type: 'SET_ORGANIZATION_DATA',
          payload: {
            organization: {
              ...organization!,
              partnerName: updatedPartnerAudit.partnerName,
              partnerRevenueShareName:
                updatedPartnerAudit.partnerRevenueShareName,
              partnerId,
              partnerRevenueShareId,
              partnersOrganizationId:
                updatedPartnerAudit.partnersOrganizationsAudit
                  ?.partnersOrganizationId || '',
            },
            partnerOrgAuthDetails: updatedPartner,
          },
        });
        // we need to fire this even on unmount
        // otherwise partnerAudit won't be updated in case when
        // we hadn't had source by default but added it to store
        onSuccess(updatedPartnerAudit);
        if (!mounted.current) return;
        props.onClose();
      } catch (error) {
        if (!mounted.current) return;
        setSubmitting(false);
        enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
        logError(error);
      }
    },
  });

  if (isConfirmationStep)
    return (
      <ConfirmDialog
        {...props}
        title={t('int.changePartnerDialog.title')}
        description={t('int.changePartnerDialog.confirmationDescription')}
        onSuccess={() => setIsConfirmationStep(false)}
      />
    );

  return (
    <Dialog {...props} maxWidth="xs">
      <DialogTitle>{t('int.changePartnerDialog.title')}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={formik.handleSubmit}
          id="change-partner-form"
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PartnerSelect
                label={t('int.partnerSelect.revenueLabel')}
                tooltipLabel={t('int.partnerSelect.revenueTooltip')}
                {...omit(
                  formik.getFieldProps('partnerRevenueShare'),
                  'onChange'
                )}
                onChange={(value) => {
                  formik.setFieldValue('partnerRevenueShare', value, false);
                  formik.setFieldValue('partner', value, false);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PartnerSelect
                label={t('int.partnerSelect.configLabel')}
                tooltipLabel={t('int.partnerSelect.configTooltip')}
                isPartnerConfig
                {...omit(formik.getFieldProps('partner'), 'onChange')}
                onChange={(value) => {
                  formik.setFieldValue('partner', value, false);
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.onClose}>
          {t('common.button.cancel')}
        </Button>
        <Button
          disabled={
            !formik.values.partner.partnerId ||
            (organization!.partnerId === formik.values.partner.partnerId &&
              organization!.partnerRevenueShareId ===
                formik.values.partnerRevenueShare.partnerId) ||
            formik.isSubmitting
          }
          form="change-partner-form"
          type="submit"
        >
          {t('common.button.confirm')}
        </Button>
      </DialogActions>
      <LoaderWithOverlay loading={formik.isSubmitting} />
    </Dialog>
  );
};

export default withDialogWrapper<Props>(ChangePartnerDialog);
