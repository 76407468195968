import { useMemo, useRef } from 'react';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmDialog from 'components/ConfirmDialogV2';
import FormatMoney from 'components/FormatMoney';
import { useGlobalState } from 'context/GlobalState';
import { DIALOG_STEPPER_WIDTH } from 'domains/card/components';
import { CardDetailsDialog } from 'domains/card/dialogs';
import {
  useCardCreationCustomFields,
  useMaxAllowedCardLimit,
} from 'domains/card/hooks';
import {
  Dialog,
  DialogProps,
  LoaderWithOverlay,
  withDialogWrapper,
} from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import {
  Card,
  CardCategoryControl,
  CardCategoryControlType,
  CardConfigGroup,
  CardConfigSetting,
  CardControlRestriction,
  CardDateControl,
  CardFundingType,
  CardLimitRenewFrequency,
  CardLoadFrequency,
  CardLocationControl,
  CardMerchantControl,
  CardPremiumProductType,
  CardTimeControl,
  CustomField,
  CustomFieldOption,
  DEFAULT_TIMEZONE,
  Member,
  MemberDetails,
  MerchantCategory,
  NetworkErrorCode,
  PremiumCardFeeTier,
  Team,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import {
  convertDineroToMoney,
  dineroFromFloat,
  getCurrencyByCode,
  getGenericErrorMsg,
  getNetworkErrorCode,
} from 'services/utils';
import AttributesStep from './AttributesStep';
import ControlsStep from './ControlsStep';
import LimitsAndValidityStep from './LimitsAndValidityStep';
import MemberAndTypeStep from './MemberAndTypeStep';
import SummaryStep from './SummaryStep';
import useCardIssuedMessage from './useCardIssuedSnackbar';

export type Step =
  | 'memberAndType'
  | 'limitsAndValidity'
  | 'controls'
  | 'attributes'
  | 'summary'
  | 'confirmation'
  | 'details';

export interface FormValues {
  step: Step;
  member: Member | MemberDetails | null;
  cardConfigSetting: CardConfigSetting | null;
  expiryPeriodMonths: number;
  validFrom: string;
  validTo: string;
  limit: string;
  transactionLimit: string;
  limitRenewFrequency: CardLimitRenewFrequency;
  loadAmount: string;
  loadFrequency: CardLoadFrequency;
  cardName: string;
  purpose: string;
  cardAccountId: string;
  cardDesignId: string;
  teamId: string;
  projectId: string;
  circulaSynced: boolean;
  categoryControl: CardCategoryControl | null;
  merchantControl: CardMerchantControl | null;
  dateControl: CardDateControl | null;
  timeControl: CardTimeControl | null;
  locationControl: CardLocationControl | null;
  customFirstName: string;
  customLastName: string;
  customFields: (CustomFieldOption | string | null)[];
  customFieldsResponse: CustomField[];
}

export const getInitialValues = ({
  member,
  cardConfigSetting,
  managerTeam,
  customFields,
  customFieldsResponse,
}: {
  member?: Member | MemberDetails | null;
  cardConfigSetting?: CardConfigSetting | null;
  managerTeam?: Team | null;
  customFields: (CustomFieldOption | string | null)[];
  customFieldsResponse: CustomField[];
}): FormValues => {
  const expiryPeriodMonths = cardConfigSetting
    ? cardConfigSetting.expiryPeriodsInMonths[
        cardConfigSetting.expiryPeriodsInMonths.length - 1
      ]
    : 0;

  return {
    step: 'memberAndType',
    member: member || null,
    cardConfigSetting: cardConfigSetting || null,
    expiryPeriodMonths,
    validFrom: moment().format('YYYY-MM-DD'),
    validTo:
      typeof cardConfigSetting?.validityPeriodDefaultInDays === 'number'
        ? moment()
            .add(cardConfigSetting?.validityPeriodDefaultInDays, 'days')
            .format('YYYY-MM-DD')
        : moment().add(expiryPeriodMonths, 'months').format('YYYY-MM-DD'),
    limit:
      cardConfigSetting?.fundingType === CardFundingType.loadBased ? '0' : '',
    transactionLimit:
      cardConfigSetting?.fundingType === CardFundingType.loadBased ? '0' : '',
    limitRenewFrequency:
      cardConfigSetting?.maxUsage === 1 ||
      cardConfigSetting?.fundingType === CardFundingType.loadBased ||
      cardConfigSetting?.cardConfigGroup === CardConfigGroup.pliantVirtualTravel
        ? CardLimitRenewFrequency.total
        : CardLimitRenewFrequency.monthly,
    // Hardcoded values for Bonago cards - should be updated
    // when we have these values in the card config
    loadAmount: '50',
    loadFrequency: CardLoadFrequency.monthly,
    cardName: '',
    purpose: '',
    cardAccountId: cardConfigSetting?.cardAccounts[0] || '',
    cardDesignId: cardConfigSetting?.cardDesignIds[0] || '',
    teamId: managerTeam?.id || '',
    projectId: '',
    circulaSynced: false,
    categoryControl:
      cardConfigSetting?.cardConfigGroup === CardConfigGroup.pliantVirtualTravel
        ? {
            restriction: CardControlRestriction.allowed,
            type: CardCategoryControlType.category,
            values: [MerchantCategory.travelAndAccommodation],
            displayValues: null,
          }
        : null,
    merchantControl: null,
    dateControl: null,
    timeControl: null,
    locationControl: null,
    customFirstName: '',
    customLastName: '',
    customFields, // custom fields for user manipulations
    customFieldsResponse, // actual custom fields from BE
  };
};

interface Props extends DialogProps {
  onClose: () => void;
  onSuccess: () => void;
  member?: Member | MemberDetails | null;
  managerTeam?: Team;
}

export const IssueCardDialog = ({
  onSuccess,
  member,
  managerTeam,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const { enqueueSnackbar } = useSnackbar();
  const showCardIssuedMessage = useCardIssuedMessage();
  const {
    state: {
      organization,
      member: currentMember,
      cardAccounts,
      featureModules,
    },
  } = useGlobalState();
  const premiumCardFeeTier = useRef<PremiumCardFeeTier | null>(null);
  const didUserConfirmPremiumCardFee = useRef(false);
  const issuedCard = useRef<Card | null>(null);
  const { getMaxAllowedCardLimitExceededErrorMessage } = useMaxAllowedCardLimit(
    managerTeam
  );
  const {
    customFields,
    customFieldsResponse,
    isLoading,
  } = useCardCreationCustomFields(props.onClose);
  const initialValues = useMemo(() => {
    return getInitialValues({
      member,
      managerTeam,
      customFields,
      customFieldsResponse,
    });
  }, [member, managerTeam, customFields, customFieldsResponse]);

  const onSubmit = async (
    values: FormValues,
    { setSubmitting, setErrors, setFieldValue }: FormikHelpers<FormValues>
  ) => {
    try {
      if (
        values.cardConfigSetting!.premiumProductType ===
          CardPremiumProductType.visaInfinite &&
        !didUserConfirmPremiumCardFee.current
      ) {
        const data = await api.getCurrentPremiumCardFeeTier(organization!.id);
        if (data.fee.value > 0) {
          premiumCardFeeTier.current = data;
          setFieldValue('step', 'confirmation');
          return;
        }
      }

      const account = cardAccounts.find(
        (item) => item.id === values.cardAccountId
      )!;
      const currency = getCurrencyByCode(account.currency.value);
      const shouldShowCardDetails =
        values.cardConfigSetting!.showDetailsModal &&
        currentMember.id === values.member!.id;

      const card = await api[
        shouldShowCardDetails ? 'issueCardSync' : 'issueCard'
      ]({
        organizationId: organization!.id,
        memberId: values.member!.id,
        teamId: values.teamId || undefined,
        cardConfig: values.cardConfigSetting!.cardConfig,
        cardAccountId: values.cardAccountId,
        cardDesignId: values.cardDesignId,
        transactionLimit: convertDineroToMoney(
          dineroFromFloat(values.transactionLimit, currency)
        ),
        limit: convertDineroToMoney(dineroFromFloat(values.limit, currency)),
        limitRenewFrequency: values.limitRenewFrequency,
        loadAmount:
          values.cardConfigSetting!.fundingType === CardFundingType.loadBased
            ? convertDineroToMoney(dineroFromFloat(values.loadAmount, currency))
            : undefined,
        loadFrequency:
          values.cardConfigSetting!.fundingType === CardFundingType.loadBased
            ? values.loadFrequency
            : undefined,
        expiryPeriodMonths: values.expiryPeriodMonths,
        ...(values.cardConfigSetting!.validityPeriodEnabled && {
          validFrom: values.validFrom,
          validTo: values.validTo,
          validTimezone: DEFAULT_TIMEZONE,
        }),
        cardName: values.cardName.trim() || undefined,
        purpose: values.purpose.trim() || undefined,
        projectId: values.projectId || undefined,
        cardControls: featureModules.CARD_CONTROLS
          ? {
              ...(!!values.categoryControl?.values.length && {
                categories: values.categoryControl,
              }),
              ...(!!values.merchantControl?.values.length && {
                merchants: values.merchantControl,
              }),
              ...(!!values.dateControl?.values.length && {
                dates: values.dateControl,
              }),
              ...(!!values.timeControl?.values.length && {
                times: values.timeControl,
              }),
              ...(!!values.locationControl?.values.length && {
                locations: values.locationControl,
              }),
            }
          : {},
        customFirstName: values.customFirstName || undefined,
        customLastName: values.customLastName || undefined,
      });

      if (values.customFields.length > 0) {
        await api.updateCardsCardTxCustomFields({
          cardTransactionCustomFieldRequests: values.customFields.map(
            (customField, index) => ({
              cardId: card.cardId,
              organizationId: values.customFieldsResponse[index].organizationId,
              transactionCustomFieldId: values.customFieldsResponse[index].id,
              defaultValueForCard:
                typeof customField === 'string' ? customField : '',
              customFieldOptionId:
                typeof customField !== 'string' && customField
                  ? customField.id
                  : null,
            })
          ),
        });
      }

      if (values.circulaSynced) {
        await api.addCardToCircula(card.organizationId, card.cardId);
      }

      if (!mounted.current) return;
      setSubmitting(false);
      showCardIssuedMessage(card, values.member!, managerTeam?.id);
      if (shouldShowCardDetails) {
        issuedCard.current = card;
        setFieldValue('step', 'details');
      } else {
        onSuccess();
      }
    } catch (error) {
      if (!mounted.current) return;
      setSubmitting(false);
      switch (getNetworkErrorCode(error)) {
        case NetworkErrorCode.maxCardLimitExceeded:
          setErrors({
            [values.cardConfigSetting!.maxUsage === 1
              ? 'transactionLimit'
              : 'limit']: getMaxAllowedCardLimitExceededErrorMessage(error),
          });
          setFieldValue('step', 'limitsAndValidity');
          break;
        case NetworkErrorCode.orgLimitExceeded:
          setErrors({
            [values.cardConfigSetting!.maxUsage === 1
              ? 'transactionLimit'
              : 'limit']: t('errors.cardTotalLimitsHigherThanOrgLimit'),
          });
          setFieldValue('step', 'limitsAndValidity');
          break;
        case NetworkErrorCode.issuanceLimitExceeded:
        case NetworkErrorCode.cardIssuanceLimitExceeded:
          enqueueSnackbar(t('issueCardDialog_v2.issuanceLimitError'), {
            variant: 'error',
          });
          setFieldValue('step', 'summary');
          break;
        default:
          enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
          setFieldValue('step', 'summary');
          logError(error);
      }
    }
  };

  const renderStep = (formikProps: FormikProps<FormValues>) => {
    if (formikProps.values.step === 'confirmation')
      return (
        <ConfirmDialog
          {...props}
          title={t('issueCardDialog_v2.blackCardConfirmationHeader', {
            cardName: t('cardNames.PLIANT_BLACK'),
          })}
          description={
            <Trans
              i18nKey="issueCardDialog_v2.blackCardConfirmationDescription"
              values={{ cardName: t('cardNames.PLIANT_BLACK') }}
              components={{
                fee: <FormatMoney value={premiumCardFeeTier.current!.fee} />,
              }}
            />
          }
          onClose={() => formikProps.setFieldValue('step', 'summary')}
          onSuccess={() => {
            didUserConfirmPremiumCardFee.current = true;
            formikProps.handleSubmit();
          }}
          loading={formikProps.isSubmitting}
        />
      );

    if (formikProps.values.step === 'details')
      return (
        <CardDetailsDialog
          {...props}
          card={issuedCard.current!}
          onClose={onSuccess}
        />
      );

    return (
      <Dialog
        {...props}
        PaperProps={{
          sx: (theme) => ({
            minHeight: '600px',
            maxWidth: theme.breakpoints.values.sm + DIALOG_STEPPER_WIDTH + 'px',
            paddingLeft: DIALOG_STEPPER_WIDTH + 'px',
          }),
        }}
      >
        {formikProps.values.step === 'memberAndType' && (
          <MemberAndTypeStep
            managerTeam={managerTeam}
            isMemberAutocompleteDisabled={!!member}
            onClose={props.onClose}
          />
        )}
        {formikProps.values.step === 'limitsAndValidity' && (
          <LimitsAndValidityStep
            managerTeam={managerTeam}
            onClose={props.onClose}
          />
        )}
        {formikProps.values.step === 'controls' && (
          <ControlsStep onClose={props.onClose} />
        )}
        {formikProps.values.step === 'attributes' && (
          <AttributesStep onClose={props.onClose} />
        )}
        {formikProps.values.step === 'summary' && (
          <SummaryStep onClose={props.onClose} />
        )}

        <LoaderWithOverlay loading={isLoading} />
      </Dialog>
    );
  };

  return (
    <Formik<FormValues>
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {renderStep}
    </Formik>
  );
};

export default withDialogWrapper(IssueCardDialog);
