import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridRowSpacing, GridRowSpacingParams } from '@mui/x-data-grid-pro';
import { intersection, sortBy } from 'lodash';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Route, useLocation, useRouteMatch } from 'react-router-dom';
import NoData from 'components/NoData';
import NothingFound from 'components/NothingFound';
import {
  useActiveTeams,
  useGlobalState,
  useManagedTeams,
} from 'context/GlobalState';
import { ChTransactionPagesTabs } from 'domains/transaction/components';
import { useUpdateTransactionsCounters } from 'domains/transaction/hooks';
import { TransactionDetailsPage } from 'domains/transaction/pages';
import { isTxFlaggingFrozenByExport } from 'domains/transaction/utils';
import {
  DataGrid,
  FileXIcon,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useCurrentApp from 'hooks/useCurrentApp';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import { PageHeader, PageTableContent, PageTitle } from 'layout';
import {
  DEFAULT_PAGE_LIMIT,
  FeatureModuleKey,
  GetTransactionsParams,
  merchantCategories,
  Team,
  Transaction,
  transactionReceiptStatuses,
  TransactionReviewStatus,
  TransactionStatus,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import {
  getReceiptFilterApiParams,
  getValidQueryParamValue,
  getValidQueryParamValues,
} from 'services/utils';
import Filters from './Filters';
import FilterChips from './Filters/FilterChips';
import TransactionsReviewToast from './TransactionsReviewToast';
import useColumns from './useColumns';

export const visibleTransactionStatuses = [
  TransactionStatus.pending,
  TransactionStatus.confirmed,
];

const getQueryParams = (
  qs: string,
  managedTeamIds: string[],
  sortedProjectIds: string[],
  cardAccountIds: string[]
) => {
  const {
    q,
    teamId,
    status,
    category,
    projectIds,
    fromDate,
    toDate,
    receipt,
    cardAccountId,
  } = Object.fromEntries(new URLSearchParams(qs).entries());

  const fromDateMoment = moment(fromDate, moment.ISO_8601);
  const toDateMoment = moment(toDate, moment.ISO_8601);

  return {
    q: q ? q.trim() : '',
    teamId: getValidQueryParamValues(teamId, managedTeamIds),
    status: getValidQueryParamValues(status, visibleTransactionStatuses),
    category: getValidQueryParamValues(category, merchantCategories),
    projectIds: getValidQueryParamValues(projectIds, sortedProjectIds),
    fromDate: fromDateMoment.isValid() ? fromDateMoment : null,
    toDate:
      fromDateMoment.isValid() && toDateMoment.isValid() ? toDateMoment : null,
    receipt: getValidQueryParamValue(receipt, transactionReceiptStatuses),
    cardAccountId: getValidQueryParamValue(cardAccountId, cardAccountIds),
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

const getSelectedFiltersCount = ({
  teamId,
  status,
  category,
  projectIds,
  fromDate,
  receipt,
  cardAccountId,
}: QueryParams) =>
  +!!teamId.length +
  +!!status.length +
  +!!category.length +
  +!!projectIds.length +
  +!!fromDate +
  +!!receipt.length +
  +!!cardAccountId;

interface State {
  isLoading: boolean;
  transactions: Transaction[];
  hasNextPage: boolean;
  totalCount: number;
  allIds: string[];
  selectedIds: string[];
  missingReceiptsCount: number;
}

const NeedsReviewPage = () => {
  const dataGridRef = useGridApiRef();
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const { isAdminApp } = useCurrentApp();
  const history = useHistory();
  const location = useLocation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const canUser = useCanUser();
  const {
    state: {
      organization,
      accountingSettings,
      member,
      featureModules,
      projects,
      cardAccounts,
    },
  } = useGlobalState();
  const updateTransactionsCounters = useUpdateTransactionsCounters();
  const managedTeams = useManagedTeams();
  const activeTeams = useActiveTeams();
  const setQueryParam = useSetQueryParam();
  const { isDetailsPageOpen, detailsParams } = useIsDetailsPageOpen(
    '/:transactionId',
    true
  );
  const managedTeamIds = useMemo(() => managedTeams.map((team) => team.id), [
    managedTeams,
  ]);
  const visibleTeams = useMemo<Team[]>(() => {
    if (isAdminApp) return activeTeams;
    return canUser('team:manage') ? managedTeams : [];
  }, [isAdminApp, activeTeams, managedTeams]);
  const visibleTeamsIds = useMemo(() => visibleTeams.map((item) => item.id), [
    visibleTeams,
  ]);
  const sortedProjects = useMemo(
    () =>
      projects?.length &&
      // projects hidden from partner
      featureModules.ACCOUNTING_FEATURES &&
      accountingSettings!.projectEnabled
        ? sortBy(projects, (v) => v.name.toLowerCase())
        : [],
    [projects]
  );
  const sortedProjectIds = useMemo(
    () => sortedProjects.map((project) => project.id),
    [sortedProjects]
  );
  const cardAccountIds = useMemo(
    () => cardAccounts.map((account) => account.id),
    [cardAccounts]
  );
  const paramsRef = useRef(
    getQueryParams(
      location.search,
      visibleTeamsIds,
      sortedProjectIds,
      cardAccountIds
    )
  );
  const columns = useColumns();
  const pageRef = useRef(0);
  const [state, setState] = useState<State>({
    isLoading: true,
    transactions: [],
    hasNextPage: false,
    totalCount: 0,
    allIds: [],
    selectedIds: [],
    missingReceiptsCount: 0,
  });
  const selectedFiltersCount = getSelectedFiltersCount(paramsRef.current);
  const areFiltersApplied =
    !!paramsRef.current.q.length || !!selectedFiltersCount;
  const isEmptyState = !state.transactions.length && !areFiltersApplied;

  const hasTransactionsFrozenByExport = state.selectedIds.some((id) => {
    const transaction = state.transactions.find(
      (transaction) => transaction.transactionId === id
    );
    return !!transaction && isTxFlaggingFrozenByExport(transaction);
  });
  const hasSelectedMultipleAccountingTransactions = state.selectedIds.some(
    (id) => {
      const transaction = state.transactions.find(
        (transaction) => transaction.transactionId === id
      );
      return transaction?.hasMultipleAccountingTransactions || false;
    }
  );

  const getTeamIdRequestParam = () => {
    const { teamId } = paramsRef.current;
    if (teamId.length) {
      return teamId.join();
    }
    if (isAdminApp) {
      return undefined;
    }
    return managedTeamIds.join();
  };

  const getRequestParams = (): GetTransactionsParams => {
    const {
      q,
      status,
      category,
      projectIds,
      fromDate,
      toDate,
      receipt,
      cardAccountId,
    } = paramsRef.current;

    return {
      q: q.length ? q : undefined,
      status: status.length ? status.join() : undefined,
      category: category.length ? category.join() : undefined,
      projectIds: projectIds.length ? projectIds.join() : undefined,
      fromDate: fromDate?.format(),
      toDate: toDate?.format(),
      organizationId: organization!.id,
      teamId: getTeamIdRequestParam(),
      ...getReceiptFilterApiParams(receipt),
      reviewStatus: TransactionReviewStatus.needsReview,
      cardAccountId: cardAccountId || undefined,
    };
  };

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));

      const params = getRequestParams();
      const {
        transactions: transactionsResponse,
        hasNextPage,
        totalCount,
      } = await api.getTransactions({ ...params, page, limit });

      if (!mounted.current) return;
      setState((prevState) => {
        const transactions = isLoadMore
          ? [...prevState.transactions, ...transactionsResponse]
          : transactionsResponse;
        const allIds = transactions.map((item) => item.transactionId);
        const selectedIds = intersection(prevState.selectedIds, allIds);

        return {
          ...prevState,
          isLoading: false,
          transactions,
          hasNextPage,
          totalCount,
          allIds,
          selectedIds,
        };
      });
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const getMissingReceiptsFiltersCounts = async () => {
    try {
      const {
        count: missingReceiptsCount,
      } = await api.getMissingTransactionReceiptCount({
        organizationId: organization!.id,
        memberId: isAdminApp ? undefined : member.id,
      });

      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        missingReceiptsCount,
      }));
    } catch (error) {
      logError(error);
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading)
      dataGridRef.current.scroll({ left: 0, top: 0 });

    paramsRef.current = getQueryParams(
      location.search,
      visibleTeamsIds,
      sortedProjectIds,
      cardAccountIds
    );
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  useEffect(() => {
    getMissingReceiptsFiltersCounts();
  }, []);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const getRowSpacing = useCallback(
    (params: GridRowSpacingParams): GridRowSpacing => {
      if (!state.selectedIds.length) return {};
      const marginBottom = isDetailsPageOpen ? 192 : 152;
      return {
        bottom: params.isLastVisible ? marginBottom : 0,
      };
    },
    [isDetailsPageOpen, state.selectedIds.length]
  );

  const onTransactionDetailsUpdated = (updatedTransaction: Transaction) => {
    setState((prevState) => ({
      ...prevState,
      transactions: prevState.transactions.map((transaction) =>
        transaction.transactionId === updatedTransaction.transactionId
          ? updatedTransaction
          : transaction
      ),
    }));
  };

  return (
    <>
      <PageHeader>
        <PageTitle
          title={
            isAdminApp
              ? t('transactionsNeedsReviewPage.titleNested')
              : t('transactionsNeedsReviewPage.title')
          }
          suptitle={
            isAdminApp ? t('transactionsNeedsReviewPage.title') : undefined
          }
        />

        <ChTransactionPagesTabs />

        <Filters
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          disabled={isEmptyState}
          teams={visibleTeams}
          projects={sortedProjects}
          missingReceiptsCount={state.missingReceiptsCount}
          transactionsCount={state.totalCount}
        />

        <FilterChips
          params={paramsRef.current}
          selectedFiltersCount={selectedFiltersCount}
          setParam={setQueryParam}
          teams={visibleTeams}
          projects={sortedProjects}
        />
      </PageHeader>

      <PageTableContent>
        <LoaderWithOverlay loading={state.isLoading} />

        <DataGrid<Transaction>
          apiRef={dataGridRef}
          rowHeight={72}
          rowCount={state.totalCount}
          getRowId={(row) => row.transactionId}
          getRowSpacing={getRowSpacing}
          checkboxSelection={canUser('transaction-review:bulk-change')}
          getRowClassName={(params) =>
            params.id === detailsParams?.transactionId
              ? 'row-details-visible'
              : ''
          }
          onRowSelectionModelChange={(newRowSelectionModel) =>
            setState((prevState) => ({
              ...prevState,
              selectedIds: newRowSelectionModel as string[],
            }))
          }
          keepNonExistentRowsSelected
          loading={state.isLoading}
          rows={state.transactions}
          columns={columns}
          columnVisibilityModel={{
            cardType: !isDetailsPageOpen,
            memberFirstName: !isDetailsPageOpen,
            teamName:
              !isDetailsPageOpen && !isAdminApp && managedTeams.length > 1,
            cardAccountName: !isDetailsPageOpen,
            review: !isDetailsPageOpen,
            drawerPlaceholder: isDetailsPageOpen,
            _integration_: featureModules.ACCOUNTING_FEATURES,
          }}
          disableRowSelectionOnClick
          rowSelectionModel={state.selectedIds}
          onRowClick={({ id, row }) => {
            if (id === detailsParams?.transactionId) {
              history.push(`${url}${location.search}`);
            } else
              history.push(`${url}/${row.transactionId}${location.search}`);
          }}
          onRowsScrollEnd={() => {
            if (!state.isLoading && state.hasNextPage) loadMoreItems();
          }}
          slots={{
            noRowsOverlay: () => {
              if (!state.transactions.length && areFiltersApplied)
                return <NothingFound />;

              return (
                <NoData
                  isNewDesign
                  Icon={FileXIcon}
                  label={t('transactionsNeedsReviewPage.noTransactions')}
                  $top={90}
                />
              );
            },
            loadingOverlay: () => null,
          }}
        />

        <TransactionsReviewToast
          ids={state.selectedIds}
          isDetailsPageOpen={isDetailsPageOpen}
          hasTransactionsFrozenByExport={hasTransactionsFrozenByExport}
          hasMultipleAccountingTransactions={
            hasSelectedMultipleAccountingTransactions
          }
          onUpdate={() => {
            setState((prevState) => ({ ...prevState, selectedIds: [] }));
            pageRef.current = 0;
            getData(pageRef.current);
            updateTransactionsCounters();
          }}
        />

        <Route
          path={`${path}/:transactionId`}
          children={({ history, location, match }) => (
            <TransactionDetailsPage
              open={!!match}
              isAdminApp={false}
              onUpdate={onTransactionDetailsUpdated}
              refetchMissingReceiptCount={getMissingReceiptsFiltersCounts}
              onReviewUpdated={() => {
                if (!match) return;
                pageRef.current = 0;
                getData(pageRef.current);
                history.replace({
                  pathname: match.url.substring(0, match.url.lastIndexOf('/')),
                  search: location.search,
                });
              }}
            />
          )}
        />
      </PageTableContent>
    </>
  );
};

export const TransactionsNeedsReviewPage = withPageConfig(NeedsReviewPage, {
  permission: 'transaction-review:view',
  featureModule: FeatureModuleKey.managerTxReviews,
});

export const ChTransactionsNeedsReviewPage = withPageConfig(NeedsReviewPage, {
  permission: 'team-transaction-review:view',
  featureModule: FeatureModuleKey.managerTxReviews,
});
