import { Box } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import FormatMoney from 'components/FormatMoney';
import {
  BillPaymentTypeBadge,
  PartialPaymentStatusBadge,
} from 'domains/billing/components';
import {
  BillPaymentBillPaymentType,
  BillPaymentType,
  PartialPaymentStatus,
  Payment,
} from 'services/constants';
import { useCanUser } from 'services/rbac';
import {
  AdditionalInfoPrimaryText,
  AdditionalInfoSecondaryText,
  CutOffDate,
  DirectDebitReversalBadge,
  PaymentDefermentInDays,
  PaymentItemWrapper,
} from './style';

interface Props {
  payment: Payment;
}

const PaymentItem = ({ payment }: Props) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const canUser = useCanUser();

  if (
    payment.billPaymentType ===
      BillPaymentBillPaymentType.billPaymentReversal ||
    payment.billPaymentType ===
      BillPaymentBillPaymentType.directDebitReversalFee
  ) {
    return (
      <PaymentItemWrapper>
        <Box width="100px" flex="100 0 auto">
          {moment(payment.paymentDate).format('D MMM YYYY')}
        </Box>
        <Box width="154px" flex="154 0 auto" />
        <Box width="75px" flex="75 0 auto">
          <DirectDebitReversalBadge>
            {t('statementItem.ddReversal')}
          </DirectDebitReversalBadge>
        </Box>
        <Box width="170px" flex="160 0 auto">
          <PartialPaymentStatusBadge status={payment.status} />
        </Box>
        <Box width="170px" flex="170 0 auto" textAlign="left">
          <AdditionalInfoPrimaryText>
            {t('statementItem.billPayment')} {payment.originalPaymentPublicId}{' '}
            <Box component="span" whiteSpace="nowrap">
              ({moment(payment.originalPaymentPaymentDate).format('D MMM YYYY')}
              )
            </Box>
          </AdditionalInfoPrimaryText>
          {payment.billPaymentType ===
            BillPaymentBillPaymentType.billPaymentReversal &&
            canUser('delayed-payment:retry') &&
            payment.returnResponseCode && (
              <AdditionalInfoSecondaryText>
                {t('int.billPaymentItem.failReason', {
                  message: t(
                    `int.returnReasonCodes.${payment.returnResponseCode}`
                  ),
                  code: payment.returnResponseCode,
                })}
              </AdditionalInfoSecondaryText>
            )}
          {payment.billPaymentType ===
            BillPaymentBillPaymentType.directDebitReversalFee && (
            <AdditionalInfoSecondaryText>
              {t('statementItem.ddReversalFee')}
            </AdditionalInfoSecondaryText>
          )}
        </Box>
        <Box width="120px" flex="120 0 auto" textAlign="right">
          <FormatMoney value={payment.amount} fractionalPart />
        </Box>
      </PaymentItemWrapper>
    );
  }

  const renderAdditionalInfo = () => {
    if (payment.billPaymentType === BillPaymentBillPaymentType.payout) {
      return (
        <>
          <AdditionalInfoPrimaryText>
            {payment.payoutAccountBankName}
          </AdditionalInfoPrimaryText>
          <AdditionalInfoSecondaryText>
            {payment.payoutAccountIban}
          </AdditionalInfoSecondaryText>
        </>
      );
    }
    if (payment.billPaymentType === BillPaymentBillPaymentType.prefundPayment) {
      return (
        <>
          <AdditionalInfoPrimaryText>
            {payment.counterpartyBankName}
          </AdditionalInfoPrimaryText>
          <AdditionalInfoSecondaryText>
            {payment.counterpartyIban}
          </AdditionalInfoSecondaryText>
        </>
      );
    }
    if (
      payment.type === BillPaymentType.wireTransfer ||
      (payment.status !== PartialPaymentStatus.planned &&
        payment.status !== PartialPaymentStatus.upcoming)
    ) {
      return payment.publicId;
    }
    return null;
  };

  const getMainDate = () => {
    if (payment.billPaymentType === BillPaymentBillPaymentType.payout) {
      return payment.paymentDate
        ? moment(payment.paymentDate).format('D MMM YYYY')
        : '-';
    }
    return payment.status === PartialPaymentStatus.paid
      ? moment(payment.paymentDate).format('D MMM YYYY')
      : moment(payment.collectionDate).format('D MMM YYYY');
  };

  return (
    <PaymentItemWrapper>
      <Box width="100px" flex="100 0 auto">
        {getMainDate()}
        {payment.cutoffDate && (
          <CutOffDate>
            {moment(payment.cutoffDate).format('D MMM YYYY')}
          </CutOffDate>
        )}
      </Box>
      <Box width="154px" flex="154 0 auto">
        {!!payment.paymentDefermentInDays &&
          payment.paymentDefermentInDays > 0 && (
            <PaymentDefermentInDays>
              {t('statementItem.nDaysDeferred', {
                number: payment.paymentDefermentInDays,
              })}
            </PaymentDefermentInDays>
          )}
      </Box>
      <Box width="75px" flex="75 0 auto">
        <BillPaymentTypeBadge type={payment.type} />
      </Box>
      <Box width="170px" flex="160 0 auto" display="flex" alignItems="center">
        {payment.billPaymentType !==
          BillPaymentBillPaymentType.prefundPayment && (
          <PartialPaymentStatusBadge status={payment.status} />
        )}
      </Box>
      <Box width="170px" flex="170 0 auto" textAlign="left">
        {renderAdditionalInfo()}
      </Box>
      <Box width="120px" flex="120 0 auto" textAlign="right">
        {payment.status === PartialPaymentStatus.planned && '-'}
        {(payment.status === PartialPaymentStatus.upcoming ||
          payment.status === PartialPaymentStatus.readyForPmt ||
          payment.status === PartialPaymentStatus.delayed) && (
          <Box color={alpha(colors.grey10, 0.5)}>
            <FormatMoney value={payment.amount} fractionalPart />
          </Box>
        )}
        {(payment.status === PartialPaymentStatus.pending ||
          payment.status === PartialPaymentStatus.paid ||
          payment.status === PartialPaymentStatus.deferred) && (
          <FormatMoney value={payment.amount} fractionalPart />
        )}
        {payment.status === PartialPaymentStatus.failed && (
          <Box style={{ textDecoration: 'line-through' }}>
            <FormatMoney value={payment.amount} fractionalPart />
          </Box>
        )}
      </Box>
    </PaymentItemWrapper>
  );
};

export default PaymentItem;
